export default function DelhiAboutUs() {
    return (
      <div
        className="privacy-policy-container"
        style={{ overflow: "scroll" }}
      >
        <div className="title-style-about" style={{ marginTop:40 }}>
          About Us
        </div>
        <div className="subtitle" style={{ marginTop: 40 }}>
          What we do
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          We are a motivated team of young dynamic individuals with a vision to be
          a reliable end-to-end city logistics partner for MSMEs in Chennai and
          beyond, deliver goods on-demand and allow MSMEs to focus on their core
          business. For the past few years we have been conducting surveys and
          studies to understand the characteristics of the freight industry,
          analysing issues and challenges faced by both shippers and truckers from
          a variety of perspectives.
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          GoodMove app is an initiative to consolidate freight in-order to
          decrease the number of less than truckload trips and reduce road
          congestion and emission of GHGs.
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          For more details, please visit our website
          <a href="https://goodmove.in/" style={{ marginLeft: 10 }}>
            www.goodmove.in
          </a>
        </div>
      </div>
    );
  }
  