export default function TermandConditions() {
  return (
    <div
      className="privacy-policy-container"
      style={{ overflow: "scroll" }}
    >
      <div className="title-style" style={{ marginTop: 40 }}>
        Terms and Conditions
      </div>
      <div className="subtitle" style={{ marginTop: 40 }}>
        Privacy Policy
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        This privacy policy sets out how GoodMove uses and protects any
        information that you share when you use this mobile app/ platform. The
        following Terms &amp; Conditions shall apply to customers utilising the
        Services offered by the GoodMove
      </div>
      <div className="subtitle"> Mobile app to Transport:</div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        The customer shall pay the fare (as agreed), toll (where applicable) and
        any fee or levy presently payable or hereinafter imposed by the law or
        required to be paid for availing of the transport Services. The customer
        agrees and accepts that the use of our Services is at the sole risk of
        the Customer, and further acknowledges that the GoodMove disclaims all
        representations and warranties of any kind, whether express or implied.
      </div>
      <div className="subtitle" style={{ margin: 30 }}>
        The customer shall ensure that he/she will not indulge in any of the
        following activities while availing the service :
      </div>

      <div className="subtitle-style" style={{ margin: 30 }}>
        Asking the driver to break any Traffic/RTO/City Police and/or government
        rules for any purpose. The driver has the right to refuse such a request
        by the customer. The driver also has the right to refuse such a pick-up.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        Pressurizing the driver to overload the truck with the consignment than
        the allowed limit. The customer shall indemnify company from and against
        and in respect of any or all liabilities, losses, charges and expenses
        (including legal fees and costs on a full indemnity basis) claims,
        demands, actions and proceedings which GoodMove may incur or sustain
        directly or indirectly from or by any reason of or in relation to the
        use or proposed use of the services by the customer and shall pay such
        sums on demand on the GoodMove.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        GoodMove shall be entitled to disclose to all companies within its
        group, or any government body as so required by the law or by directive
        or request from any government body, the particulars of the customer in
        the possession of company in any way as company, in its absolute
        discretion, deems fit or if it considers it in its interests to do so.
        GoodMove shall be entitled at any time without giving any reason to
        terminate the booking to transport the consignment done by the customer.
        User(s) shall indemnify GoodMove with respect to any expenses incurred
        with respect to such booking.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        In the case of lost items inside the consolidation centre or during the
        journey, GoodMove will try to locate the items on a best-effort basis
        but is not responsible for the same in case of loss or damage to the
        same. GoodMove aggregates its vehicles for the purpose of providing
        services. In the event of loss of any item, user(s) shall not have any
        right to withhold the payment to be made towards the third party or
        agent operating this mobile app. Any complaint in respect of the
        services or the use of the freight vehicle, the customer has to inform
        GoodMove of the same in writing within 24 hours of using the freight
        vehicle or the services of GoodMove.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        GoodMove shall not be liable for any conduct of the drivers of freight
        vehicles. However, GoodMove encourages you to notify it of any
        complaints that you may have against the driver that you may have hired
        using our Services.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        GoodMove shall be entitled to add to, vary or amend any or all these
        terms and conditions at any time and the customer shall be bound by such
        addition, variation or amendment once such addition, variation or
        amendment are incorporated into these terms and conditions.
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        {" "}
        All the calls made to the customer care are recorded for quality and
        training purposes. In the event you place a query on our app including
        query with respect to our services, applicable fees or terms of service,
        you hereby expressly agree to consent to receive our responses, whether
        by way of telephonic calls or electronic mail, to such query and all
        related information with respect to our services. For removal of doubts,
        related information shall include without limitation any marketing
        and/or commercial information, you understand and agree that such
        information shall in no event qualify as unsolicited commercial
        communication under the Telecom Unsolicited Commercial Communications
        Regulations, 2007 and/or due to disclosure of such information, our
        telephone numbers shall not qualify to be registered under the “National
        Do Not Call Register” or the “Private Do Not Call Register” in
        accordance with the Telecom Unsolicited Commercial Communications
        Regulations, 2007 or any other applicable law.
      </div>
      <div className="subtitle">Toll Charges :</div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        {" "}
        In case of a toll on your trip, return toll fare will be charged.
      </div>
    </div>
  );
}
