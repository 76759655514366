import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { motion as m } from "framer-motion";
import {
  addBooking,
  getFareBytype,
  getShopDetails,
  getCCSessionData,
  getBookingDistance,
} from "./api/shop";
import TwoWheelerIcon from "./images/2wheelerfinal.png";
import ThreeWheelerIcon from "./images/3wheelerFinal.png";
import FourWheelerIcon from "./images/4wheelerfinal.png";
import TickIcon from "./images/tickicon.png";
import Comingsoon from "./images/coming_soon.png";
import IndianCurrencyIcon from "./images/indian_rupee.png";
import LocationIcon from "./images/location-icon-zeufz.png";
import EditIcon from "./images/edit.png";
import OfficeIcon from "./images/office-icon.jpg";
import RecentSearchIcon from "./images/recent.png";
import SearchIcon from "./images/icons8-search-52.png";
import { getAddressListPerUser } from "./api/shop";
import ReactLoading from "react-loading";
import Geocode from "react-geocode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";
import moment from "moment";
import { add } from "date-fns";
import axios from "axios";
Geocode.setApiKey("AIzaSyBeoSc41NabP50zIM7bi2gMYTrolDSRmBA");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
const GOOGLE_API_KEY = "AIzaSyBeoSc41NabP50zIM7bi2gMYTrolDSRmBA";
// const baseUrl = "https://test-api.goodmove.in/api";
const baseUrl = "https://api.goodmove.in/api";
// const baseUrl = "http://52.66.195.223:3000/api";
const libraries = ['places'];


function Booking(params) {
  const navigate = useNavigate();
  const { shop_id } = useParams();

  console.log("function props", shop_id);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [payType, setPaytype] = useState("");
  const [file, setFile] = useState(null);

  // const [sameDay, setSameday] = useState(true);
  const [nextDay, setNextday] = useState(false);
  const [twoWheeler, setTwoWheeler] = useState(false);
  const [threeWheeler, setThreeWheeler] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [vegam, setVegam] = useState(false);
  const [vivegam, setVivegam] = useState(false);
  const [isBookingTypeSelected, setIsBookingTypeSelected] = useState(true);

  const [showRatePopup, setShowRatePopup] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [shopDetails, setShopDetails] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [showUnavailableMessage, setShowUnavailableMessage] = useState(false);
  const [coords, setCoords] = useState({ lat: 13.0418, lng: 80.2341 });
  const [map, setMap] = useState(/** @type goodle.maps.map */ null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAddress, setIsAddress] = useState(false);
  const [isAgreedTermsandCond, setIsAgreedTermsandCond] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    customer_mobile: "",
    dropFromTime: "",
    dropToTime: "",
    file: "",
    vehicle_type: "",
    fare: "",
    bagId: 0,
    save_address: false,
  });
  const [fareDetails, setFareDetails] = useState({});
  const [confirmedFare, setConfirmedFare] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({
    address: "",
    address_type: "Pickup",
    city: "",
    country: "",
    // customer_id: selectedCustomerId,
    latitude: 0,
    longitude: 0,
    // mobile: selectedCustomerPhone.slice(-10),
    pincode: "",
    receiver_contact: "",
    receiver_name: "",
    state: "",
    landmark: "",
  });
  const [addedBookingResult, setAddedBookingResult] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [showAddressListPage, setShowAddressListPage] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [query, setQuery] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isValidBagId, setisValidBagId] = useState(false);
  const [isPayTypeChoosed, setIsPayTypeChoosed] = useState(false);
  const [isFareFetched, setIsFareFetched] = useState(false);
  const [isAddressNotSelected, setIsAddressNotSelected] = useState(false);
  const [isEnableButton, setIsEnableButton] = useState(true);
  // const notify = () => { isAddressNotSelected ? toast("Wow so easy!") : null};} ;
  const [isValidTime, setIsValidTime] = useState();
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [storeBookingResponse, setStoreBookingResponse] = useState({});
  const [showAppLoader, setShowAppLoader] = useState(false);
  const [isClickedSubmit, setIsClickedSubmit] = useState(false);
  const [isValidForFareCal, setIsValidForFareCal] = useState(false);
  const [isVehicleChoosed, setIsVehicleChoosed] = useState(false);
  const ccForm = useRef();
  const [showTermsandConditionsPopup, setShowTermsandConditionsPopup] =
    useState(false);
  const notify = () => toast("Please choose delivery location!");
  const navigateMap = () => {
    setIsActive(true);
    setShowMap(true);
  };

  const google = window.google;
  const search = (options) => {
    return options.filter((item) =>
      item.address.toString().toLowerCase().includes(query)
    );
  };

  const handleBeforeUnload = (event) => {
    // Check if the event is due to the back button being clicked
    if (event.clientX < 0 && event.clientY < 0) {
      // Show the confirmation popup
      event.returnValue = "Are you sure you want to leave?";
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchShopDetails = async () => {
      const result = await getShopDetails(shop_id);
      console.log(result, "----result");
      setShopDetails(result.data);
      if (shopDetails.id) {
        setShowAppLoader(false);
      }
      setShowAppLoader(true);
      localStorage.setItem("shopDetails", JSON.stringify(result.data));
    };
    fetchShopDetails();
  }, []);

  const navigateToMap = async () => {
    console.log(bookingDetails.customer_mobile, "-----customer_mobile");
    let addressList;
    if (bookingDetails.customer_mobile) {
      addressList = await getAddressListPerUser(bookingDetails.customer_mobile);
      console.log(addressList, "-----addressList");

      setAddressList(addressList.data);
      if (addressList.data.length === 0) {
        console.log("---com insidex");
        setShowMap(true);
      } else {
        setShowAddressListPage(true);
      }
    } else {
      setShowMap(true);
    }
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });
  if (!isLoaded) {
    return <div style={{ fontSize: 12, color: "#000000" }}>map is loading</div>;
  }

  let address;

  let findAddress = function (lat, lng) {
    console.log(lat, lng, "-------e.latLng?.lng(),");
    return Geocode.fromLatLng(lat, lng).then(
      (response) => {
        address = response.results[0].formatted_address;
        setSelectedAddress({ ...selectedAddress, address: address });
        let city, state, country, pincode;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                pincode = response.results[0].address_components[i].long_name;
                break;
              default:
                pincode = "";
            }
          }
        }
        console.log(city, state, country);

        setSelectedAddress({
          ...selectedAddress,
          address,
          city,
          state,
          country,
          pincode,
          latitude: lat,
          longitude: lng,
        });
        console.log(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onMapLoad = (map) => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        console.log(pos, "-----pos");
        setCoords({ ...coords, lat: pos.lat, lng: pos.lng });
      }
    );
  };

  const updatePlace = async (plc) => {
    console.log("plc");
    console.log(plc);
    console.log(autocomplete.getPlace());
    const addressObj = autocomplete.getPlace();
    let city = "",
      state = "",
      country = "",
      pincode = "";
    addressObj.address_components.map((comps) => {
      if (comps.types.indexOf("locality") > -1) {
        city = comps.long_name;
      }
      if (comps.types.indexOf("administrative_area_level_1") > -1) {
        state = comps.long_name;
      }
      if (comps.types.indexOf("country") > -1) {
        country = comps.long_name;
      }
      if (comps.types.indexOf("postal_code") > -1) {
        pincode = comps.long_name;
      }
    });
    const address = addressObj.formatted_address
      ? addressObj.formatted_address
      : "";
    let reqObj = {
      shop_id: shop_id,
      destination_address: {
        lat: addressObj.geometry.location.lat(),
        long: addressObj.geometry.location.lng(),
      },
    };
    // let checkDistance = await getBookingDistance(reqObj);
    // if (checkDistance.success) {
    setSelectedAddress({
      ...selectedAddress,
      address,
      city,
      state,
      country,
      pincode,
      latitude: addressObj.geometry.location.lat(),
      longitude: addressObj.geometry.location.lng(),
    });
    setCoords({
      ...coords,
      lat: addressObj.geometry.location.lat(),
      lng: addressObj.geometry.location.lng(),
    });
  };

  const updateOnload = (plc) => {
    console.log(plc);
    setAutocomplete(plc);
  };
  var cityBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(12.288681, 79.997589),
    new google.maps.LatLng(9.939093, 78.121719)
  );

  const returnHome = () => {
    setShowSuccessPopup(false);
    window.location.reload();
  };

  const showBookedPopup = async () => {
    const validMobile = bookingDetails.customer_mobile?.replace(/[^\d]/g, "");
    console.log("coming inside 1-------");

    console.log(file, payType, "-----file----");
    if (file === null && payType !== "" && selectedAddress.address !== "") {
      setIsFileUploaded(true);
      setIsPayTypeChoosed(false);
      setIsAddress(false);
    } else if (
      payType === "" &&
      file !== null &&
      selectedAddress.address !== ""
    ) {
      setIsPayTypeChoosed(true);
      setIsFileUploaded(false);
      setIsAddress(false);
    } else if (
      selectedAddress.address === "" &&
      file !== null &&
      payType !== ""
    ) {
      setIsAddress(true);
      setIsPayTypeChoosed(false);
      setIsFileUploaded(false);
    } else if (
      selectedAddress.address === "" &&
      file === null &&
      payType === ""
    ) {
      setIsFileUploaded(true);
      setIsPayTypeChoosed(true);
      setIsAddress(true);
    } else if (
      payType === "" &&
      file === null &&
      selectedAddress.address !== ""
    ) {
      setIsFileUploaded(true);
      setIsPayTypeChoosed(true);
      setIsAddress(false);
    } else if (
      payType === "" &&
      file !== null &&
      selectedAddress.address === ""
    ) {
      setIsPayTypeChoosed(true);
      setIsAddress(true);
      setIsFileUploaded(false);
    } else if (
      payType !== "" &&
      file === null &&
      selectedAddress.address === ""
    ) {
      setIsAddress(true);
      setIsFileUploaded(true);
      setIsPayTypeChoosed(false);
    } else if (
      payType !== "" &&
      file !== null &&
      selectedAddress.address !== ""
    ) {
      setIsFileUploaded(false);
      setIsPayTypeChoosed(false);
      setIsAddress(false);
    }

    // if (bookingDetails.bagId === 0) {
    //   setisValidBagId(true);
    // }

    // else if (validMobile.length === 10) {
    //   setIsValidMobile(true);
    // }

    if (!vegam && !vivegam) {
      setIsBookingTypeSelected(false);
    }

    if (!pickup && !threeWheeler && !twoWheeler) {
      setIsVehicleChoosed(true);
    }

    console.log(
      validMobile,
      bookingDetails.customer_mobile?.replace(/[^\d]/g, "").length,
      "---validMobile"
    );
    console.log("coming inside 2-------");

    console.log(payType, shopDetails, "-----shopDetails");
    console.log("-----booking details", bookingDetails, saveAddress);
    if (bookingDetails.customer_mobile?.replace(/[^\d]/g, "").length === 10) {
      console.log("coming inside 3-------");

      let fromTime;
      let toFime;
      if (vegam) {
        console.log("coming inside 4-------");

        // const today = new Date();

        // fromTime = moment({
        //   year: today.getFullYear(),
        //   month: today.getMonth(),
        //   date: today.getDate(),
        //   hours: bookingDetails.dropFromTime.slice(0, 2),
        //   minute: bookingDetails.dropFromTime.slice(3, 5),
        //   second: 0,
        // }).format("YYYY-MM-DD HH:mm:ss");
        fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
        console.log(fromTime, "----finalDate");
        console.log(
          bookingDetails.dropToTime.slice(0, 2),
          bookingDetails.dropToTime.slice(3, 5),
          "-----bookingDetails.dropToTime.slice(3, 5)"
        );
        // toFime = moment({
        //   year: today.getFullYear(),
        //   month: today.getMonth(),
        //   date: today.getDate(),
        //   hours: bookingDetails.dropToTime.slice(0, 2),
        //   minute: bookingDetails.dropToTime.slice(3, 5),
        //   second: 0,
        // }).format("YYYY-MM-DD HH:mm:ss");

        toFime = moment(fromTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");

        // const date1 = new Date(fromTime);
        // const date2 = new Date(toFime);
        // console.log(date1, date2, "-----date12");
        // if (date1 > date2) {
        //   console.log("coming inside 5-------");
        //   console.log("time1 is later than time2");
        //   setIsValidTime(false);
        // } else if (date1 < date2) {
        //   console.log("time1 is earlier than time2");
        //   setIsValidTime(true);
        // } else {
        //   console.log("time1 and time2 are the same");
        //   setIsValidTime(false);
        // }
        console.log(toFime, "----toFime");
      } else if (vivegam) {
        fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
        toFime = moment(fromTime).add(6, "hour").format("YYYY-MM-DD HH:mm:ss");
      }
      // else if (nextDay && vegam) {
      //   // const tomorrowFns = add(new Date(), {
      //   //   days: 1,
      //   // });
      //   // console.log("tomorrow", tomorrowFns);
      //   // fromTime = moment({
      //   //   year: tomorrowFns.getFullYear(),
      //   //   month: tomorrowFns.getMonth(),
      //   //   date: tomorrowFns.getDate(),
      //   //   hours: +bookingDetails.dropFromTime.slice(0, 2),
      //   //   minute: +bookingDetails.dropFromTime.slice(3, 5),
      //   //   second: 0,
      //   // }).format("YYYY-MM-DD hh:mm:ss");
      //   fromTime = moment()
      //     .add(1, "day") // Add 1 day to get to the next day
      //     .format("YYYY-MM-DD HH:mm:ss");
      //   console.log(fromTime, "----finalDate");
      //   toFime = moment(fromTime)
      //     .add(1, "hour") // Add 6 hours to the next day
      //     .format("YYYY-MM-DD HH:mm:ss");
      //   console.log(fromTime, "----finalDate");
      //   // toFime = moment({
      //   //   year: tomorrowFns.getFullYear(),
      //   //   month: tomorrowFns.getMonth(),
      //   //   date: tomorrowFns.getDate(),
      //   //   hours: +bookingDetails.dropToTime.slice(0, 2),
      //   //   minute: +bookingDetails.dropToTime.slice(3, 5),
      //   //   second: 0,
      //   // }).format("YYYY-MM-DD hh:mm:ss");
      //   // const date1 = new Date(fromTime);
      //   // const date2 = new Date(toFime);

      //   // if (date1 > date2) {
      //   //   console.log("time1 is later than time2");
      //   //   setIsValidTime(false);
      //   // } else if (date1 < date2) {
      //   //   console.log("time1 is earlier than time2");
      //   //   setIsValidTime(true);
      //   // } else {
      //   //   console.log("time1 and time2 are the same");
      //   //   setIsValidTime(false);
      //   // }
      //   console.log(toFime, "----toFime");
      // } else if (nextDay && vivegam) {
      //   fromTime = moment()
      //     .add(1, "day") // Add 1 day to get to the next day
      //     .format("YYYY-MM-DD HH:mm:ss");
      //   console.log(fromTime, "----finalDate");
      //   toFime = moment(fromTime)
      //     .add(6, "hour") // Add 6 hours to the next day
      //     .format("YYYY-MM-DD HH:mm:ss");
      //   console.log(fromTime, "----finalDate");
      // }
      console.log("coming inside 6-------");

      const bookingPayload = {
        customer_id: 0,
        pickup_location: shopDetails.address,
        pickup_time: "",
        pickuptime_flexiblity_status: "Fixed",
        pickup_flexible_time: "",
        dropoff_location:
          selectedAddress.address + " " + selectedAddress.landmark,
        dropoff_time: fromTime,
        dropofftime_flexiblity_status: "Fixed",
        dropoff_flexible_time: toFime,
        commodity_type: 1,
        commodity_weight: 0,
        weight_unit: 12,
        commodity_whole_volume: 1,
        volume_unit: 35,
        commodity_length: "",
        length_unit: 37,
        commodity_breadth: "",
        breadth_unit: 37,
        commodity_height: "",
        height_unit: 37,
        packaging_type: 1,
        receiver_name: "Customer",
        receiver_contact: bookingDetails.customer_mobile,
        instruction: "Test",
        pickup_lat: shopDetails.latitude,
        pickup_long: shopDetails.longitude,
        pickup_city: "Chennai",
        pickup_state: "Tamil Nadu",
        pickup_country: "India",
        pickup_pincode: "",
        dropoff_city: selectedAddress.city,
        dropoff_state: selectedAddress.state,
        dropoff_country: selectedAddress.country,
        dropoff_pincode: selectedAddress.pincode,
        dropoff_lat: selectedAddress.latitude,
        dropoff_long: selectedAddress.longitude,
        assign_tour_status: 0,
        other_commodity_name: "",
        is_having_loading_charges: false,
        customer_mobile: bookingDetails.customer_mobile,
        vehicle_type: twoWheeler
          ? "2wheeler"
          : threeWheeler
          ? "3wheeler"
          : "4wheeler",
        payment_type: payType,
        fare: fareDetails.total_fare,
        shop_id: shop_id,
        save_address: saveAddress,
        consignment_image: file.data,
        delivery_on: "sameday",
        bag_id: parseInt(bookingDetails.bagId),
        is_vivegam: vivegam,
      };

      console.log(bookingPayload, "----bookingpayloadddd");
      if (
        (payType === "COD" || payType === "PS") &&
        payType !== "" &&
        fromTime < toFime &&
        isBookingTypeSelected
      ) {
        console.log("coming inside 7-------");
        if (isEnableButton) {
          setFareDetails({});
          setTwoWheeler(false);

          setThreeWheeler(false);
          setPickup(false);
          alert("Please accept the rate before you confirm the booking!");

          return;
        }
        setShowLoader(true);
        const result = await addBooking(bookingPayload);
        console.log(result, "----result for booking");
        if (result.success === true) {
          setShowLoader(false);
          setStoreBookingResponse(result.data.bookingDetails);
          setShowSuccessPopup(true);
          setAddedBookingResult(result.data.bookingDetails);
          localStorage.setItem("added booking", JSON.stringify(result.data));
        } else {
          setShowLoader(false);
          setShowSuccessPopup(true);
        }
      } else if (
        payType === "Online payment" &&
        fromTime < toFime &&
        isBookingTypeSelected
      ) {
        if (isEnableButton) {
          setFareDetails({});
          setTwoWheeler(false);

          setThreeWheeler(false);
          setPickup(false);
          alert("Please accept the rate before you confirm the booking!");

          return;
        }
        setShowLoader(true);
        const result = await addBooking(bookingPayload);
        setStoreBookingResponse(result.data.bookingDetails);

        console.log("booking result");
        console.log(result);
        setEncRequest(result.data.encoded_value);
        setAccessCode(result.data.accessCode);
        setTimeout(() => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          ccForm.current.submit();
          setShowLoader(false);
        }, 2 * 1000);
      }
    }
  };
  const navigateToHome = () => {
    setShowMap(false);
    setShowAddressListPage(false);
  };
  const handleFileChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileName", e.target.files[0].name);
    console.log(formData, "-----formData");
    try {
      const res = await axios.post(
        baseUrl + "/booking/uploadreceipt",
        formData
      );
      console.log(res);
      setFile(res.data);
    } catch (ex) {
      console.log(ex);
    }
    console.log(e.target.files[0].name, "----e.target.files");
  };

  const handleChange = (event) => {
    setPaytype(event.target.value);
    console.log(payType, "---payType");
  };

  const handleCheckChange = async (e, type) => {
    if (!selectedAddress.address) {
      notify();
      console.log("coming inside address------");
      setIsAddressNotSelected(true);
      setTwoWheeler(false);
      setThreeWheeler(false);
      setPickup(false);
    }

    console.log(e, "----e");
    // if (type === "sameDay") {
    //   setSameday(true);
    //   setNextday(false);
    // } else if (type === "nextDay") {
    //   setNextday(true);
    //   setSameday(false);
    // }
    if (type === "vivegam") {
      setIsBookingTypeSelected(true);
      setVegam(false);
      setVivegam(true);
    } else if (type === "vegam") {
      setIsBookingTypeSelected(true);
      setVegam(true);
      setVivegam(false);
    } else if (type === "twoWheeler") {
      setFareDetails({});
      setTwoWheeler(true);
      setShowRatePopup(true);
      setThreeWheeler(false);
      setPickup(false);
      const farePayload = {
        customer_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        shop_id: parseInt(shop_id),
        vehicle_type: "2wheeler",
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      console.log(farePayload, "-----farePayload");
      const result = await getFareBytype(farePayload);
      // console.log(result, "----result");
      // setFareDetails(result.data);
      if (result.success === true) {
        setIsFareFetched(true);
        setIsValidForFareCal(true);
        setFareDetails(result.data);
      } else {
        // setShowRatePopup(false);
        setShowUnavailableMessage(true);
        setIsValidForFareCal(false);
      }
    } else if (type === "threeWheeler") {
      setFareDetails({});
      setTwoWheeler(false);
      setThreeWheeler(true);
      setPickup(false);
      setShowRatePopup(true);
      const farePayload = {
        customer_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        shop_id: parseInt(shop_id),
        vehicle_type: "3wheeler",
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      console.log(farePayload, "-----farePayload");
      const result = await getFareBytype(farePayload);
      // console.log(result, "----result");
      // setFareDetails(result.data);
      if (result.success === true) {
        setIsFareFetched(true);
        setIsValidForFareCal(true);

        setFareDetails(result.data);
      } else {
        setShowUnavailableMessage(true);
        setIsValidForFareCal(false);
      }
    } else if (type === "pickup") {
      setFareDetails({});
      setTwoWheeler(false);
      setThreeWheeler(false);
      setPickup(true);
      setShowRatePopup(true);
      const farePayload = {
        customer_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        shop_id: parseInt(shop_id),
        vehicle_type: "4wheeler",
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      console.log(farePayload, "-----farePayload");
      setShowUnavailableMessage(false);

      const result = await getFareBytype(farePayload);
      if (result.success === true) {
        setIsFareFetched(true);
        setIsValidForFareCal(true);

        setFareDetails(result.data);
      } else {
        // setIsValidForFareCal(false);
        alert("something went wrong, please try again");
      }
      // console.log(isFareFetched, result, "----result");
      // setFareDetails(result.data);
    }
  };
  console.log(selectedAddress, "---samenext");
  const getCCSession = async () => {
    let sessionData = await getCCSessionData();
    console.log("sessionData");
    console.log(sessionData);
    if (sessionData.success) {
      setEncRequest(sessionData.data.encoded_value);
      setAccessCode(sessionData.data.accessCode);
      setTimeout(() => {
        ccForm.current.submit();
      }, 10 * 1000);
    }
  };
  const selectedFromList = async (data) => {
    let reqObj = {
      shop_id: shop_id,
      destination_address: {
        lat: data.latitude,
        long: data.longitude,
      },
    };
    let checkDistance = await getBookingDistance(reqObj);
    if (checkDistance.success) {
      setSelectedAddress({
        ...selectedAddress,
        address: data.address,
        address_type: data.address_type,
        city: data.city,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
        pincode: data.pincode,
        state: data.state,
        receiver_contact: data.receiver_contact,
        receiver_name: data.receiver_name,
      });
      setShowAddressListPage(false);
    } else {
      alert(checkDistance.message);
    }
  };
  return (
    <>
      {!shopDetails.id && showAppLoader ? (
        <div
          className="pricing-policy-container"
          style={{ height: 100, marginLeft: 50 }}
        >
          <div className="title-style" style={{ marginTop: 25, color: "red" }}>
            Shop not found
          </div>
          <div className="subtitle-style" style={{ marginTop: 10 }}>
            Please use valid shop url!
          </div>
        </div>
      ) : (
        <m.div style={{ height: 880 }}>
          {!showMap && (
            <>
              {" "}
              <div className="App-semi-container">
                <div className="form-container">
                  {/* <div>
            <img
              style={{
                width: 110,
                height: 32,
                marginTop: 25,
                marginBottom: -5,
              }}
              src={MinlLogo}
              alt="zeuez logo"
            />
          </div> */}
                  <div className="my-order-button-shop">
                    <a
                      href="/otpVerification"
                      style={{ color: "#ffffff", textDecoration: "none" }}
                    >
                      My Orders
                    </a>
                  </div>
                  <div className="title-style" style={{ marginTop: 20 }}>
                    Book Here
                  </div>
                  <div className="form-contn">
                    <form
                      // onSubmit={handleSubmit((data) => console.log(data, "-----dataaaa"))}
                      className="form-display"
                    >
                      <div className="web-view-style-one">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: -55,
                          }}
                        >
                          {/* <div
                className="subtitle-style"
                style={{
                  position: "relative",
                  marginTop: -10,
                }}
              >
                Enter Mobile
              </div> */}
                          <input
                            className="form-field-style"
                            {...register("mobile", { required: true })}
                            placeholder="Enter your mobile number"
                            type="text"
                            maxLength={10}
                            onChange={(e) => {
                              setBookingDetails({
                                ...bookingDetails,
                                customer_mobile: e.target.value,

                                vehicle_type: "",
                                fare: "",
                                bagId: 0,
                              });

                              setVegam(false);
                              setVivegam(false);
                              setFareDetails({});
                              setTwoWheeler(false);
                              setThreeWheeler(false);
                              setPickup(false);
                              console.log(
                                "badid===========",
                                bookingDetails.bagId,
                                vegam,
                                vivegam,
                                file
                              );
                            }}
                          />
                          {errors.mobile && (
                            <p className="error-font-style">
                              mobile number is required.
                            </p>
                          )}
                          {bookingDetails.customer_mobile !== "" &&
                            bookingDetails.customer_mobile?.replace(/[^\d]/g, "")
                              .length !== 10 && (
                              <p className="error-font-style">
                                Please enter valid mobile number
                              </p>
                            )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: -55,
                          }}
                        >
                          <div
                            style={{
                              color: "#30A46C",
                              fontSize: 10,
                              fontWeight: "normal",
                              marginLeft: 56,
                            }}
                          >
                            Pickup location
                          </div>
                          {/* <div
                            className="form-field-style"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: 8,
                              padding: 5,
                            }}
                          >
                            <div style={{ marginTop: 5 }}>
                              {shopDetails.address !== "" &&
                              shopDetails.address !== undefined
                                ? shopDetails.address
                                    .split(" ")
                                    .slice(0, 4)
                                    .join(" ") + "..."
                                : ""}
                            </div>
                          </div> */}
                          <input
                            className="form-field-style"
                            // {...register("pickupaddress", { required: true })}
                            placeholder="Pick the pickup location"
                            type="text"
                            // onClick={() => {
                            //   navigateToMap("pickup");
                            // }}
                            value={shopDetails.address}
                            onChange={() =>
                              console.log(selectedAddress, "----seleadd")
                            }
                            style={{ marginTop: 4 }}
                            readOnly
                          />

                          {isAddress && (
                            <p className="error-font-style">
                              pickup address is required.
                            </p>
                          )}
                        </div>
                        <div
                          className="edit-icon-style"
                          onClick={() => {
                            navigate("/booking");
                          }}
                        >
                          <div className="edit-lable-style">
                            Edit pickup location
                          </div>
                          <img
                            className="icon-style"
                            src={EditIcon}
                            alt="edit icon"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: -55,
                          }}
                        >
                          <div
                            style={{
                              color: "#30A46C",
                              fontSize: 10,
                              fontWeight: "normal",
                              marginLeft: 56,
                            }}
                          >
                            Delivery location
                          </div>

                          <input
                            className="form-field-style"
                            // {...register("address", { required: true })}
                            placeholder="Pick the delivery location"
                            type="text"
                            onClick={() => {
                              navigateToMap();
                              setVegam(false);
                              setVivegam(false);
                              setFareDetails({});
                              setTwoWheeler(false);
                              setThreeWheeler(false);
                              setPickup(false);
                            }}
                            value={selectedAddress.address}
                            onChange={() =>
                              console.log(selectedAddress, "----seleadd")
                            }
                          />

                          {isAddress && (
                            <p className="error-font-style">
                              address is required.
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 13,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: -4,
                          }}
                        >
                          <input
                            className="day-field-align-same"
                            {...register("sameDay", { required: true })}
                            type="radio"
                            checked={sameDay}
                            onChange={(e) => handleCheckChange(e, "sameDay")}
                          />
                          <lable className="day-field-label-same">
                            Same day
                          </lable>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: -4,
                          }}
                        >
                          <input
                            className="day-field-align-next"
                            // {...register("nextDay", { required: true })}
                            type="radio"
                            checked={nextDay}
                            onChange={(e) => handleCheckChange(e, "nextDay")}
                          />
                          <lable
                            className="day-field-label-next"
                            // style={{ width: 60, marginLeft: -78, marginTop: 12 }}
                          >
                            Next day
                          </lable>
                        </div>
                        {nextDay === false && sameDay === false && (
                          <p className="error-font-style">Please choose day.</p>
                        )}{" "}
                      </div> */}
                      <div
                        className="subtitle-style"
                        style={{ marginBottom: -9, marginTop: 15 }}
                      >
                        Delivery Speed
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 13,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: -4,
                          }}
                        >
                          <input
                            className="day-field-align-same"
                            // {...register("vegam", { required: true })}
                            type="radio"
                            checked={vegam}
                            onChange={(e) => {
                              handleCheckChange(e, "vegam");
                              setFareDetails({});
                              setTwoWheeler(false);
                              setThreeWheeler(false);
                              setPickup(false);
                            }}
                          />
                          <lable className="day-field-label-same">Vegam</lable>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: -4,
                          }}
                        >
                          <input
                            className="day-field-align-next"
                            // {...register("nextDay", { required: true })}
                            type="radio"
                            checked={vivegam}
                            onChange={(e) => {
                              handleCheckChange(e, "vivegam");
                              setFareDetails({});
                              setTwoWheeler(false);
                              setThreeWheeler(false);
                              setPickup(false);
                            }}
                          />
                          <lable
                            className="day-field-label-next"
                            // style={{ width: 60, marginLeft: -78, marginTop: 12 }}
                          >
                            Vivegam
                          </lable>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 9,
                            color: "#30a46c",
                            padding: 5,
                            marginLeft: 50,
                          }}
                        >
                          (immediate)
                        </div>
                        <div
                          style={{
                            fontSize: 9,
                            color: "#30a46c",
                            padding: 5,
                            marginLeft: 30,
                          }}
                        >
                          (Within 6 hours)
                        </div>
                      </div>
                      {!isBookingTypeSelected && (
                        <p
                          className="error-font-style"
                          style={{ marginTop: 6, marginBottom: -10 }}
                        >
                          Please choose booking type.
                        </p>
                      )}{" "}
                      <div className="web-line-style"></div>
                      <div
                        className="web-view-style-three"
                        style={{ marginLeft: 1 }}
                      >
                        <div className="web-view-style-challan">
                          <label className="subtitle-style">
                            Upload Order Challan
                          </label>
                          <input
                            style={{
                              color: "#404040",
                              fontSize: 12,
                              marginLeft: 70,
                              marginTop: 13,
                            }}
                            type="file"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleFileChange}
                          />
                          {isFileUploaded && (
                            <div className="challan-error-style">
                              <p className="error-font-style">
                                {" "}
                                Please upload challan.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="web-line-style"></div>
                      <div className="web-view-style-two">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 10,
                          }}
                        >
                          <label className="subtitle-style">Bag Id</label>
                          <input
                            className="form-field-style"
                            style={{ width: 100, marginRight: 70 }}
                            {...register("bagid", { required: true })}
                            placeholder="Enter Bag Id"
                            type="number"
                            maxLength={10}
                            onChange={(e) => {
                              setBookingDetails({
                                ...bookingDetails,
                                bagId: e.target.value,
                              });
                            }}
                            value={bookingDetails.bagId}
                          />
                          {/* {isValidBagId && (
                            <div className="bagid-error-style">
                              <p className="error-font-style">
                                {" "}
                                Please enter bag id.
                              </p>
                            </div>
                          )} */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 8,
                          }}
                        >
                          <label
                            className="subtitle-style"
                            style={{ marginBottom: 14 }}
                          >
                            My goods will fit in
                          </label>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: -2,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 1,
                                marginLeft: 20,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: -11,
                                }}
                              >
                                <input
                                  className="form-field-style-vehicle-type-2-whl"
                                  {...register("twowheeler", {
                                    required: true,
                                  })}
                                  type="radio"
                                  checked={twoWheeler}
                                  onChange={(e) =>
                                    handleCheckChange(e, "twoWheeler")
                                  }
                                />
                                <img
                                  className="two-wheeler-img-style"
                                  src={TwoWheelerIcon}
                                  alt="zeuez logo"
                                />
                              </div>
                              <lable className="subtitle-style">
                                <div className="web-view-style-2-wheeler">
                                  2 Wheeler
                                </div>
                              </lable>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 1,
                                marginLeft: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: -10,
                                }}
                              >
                                <input
                                  className="form-field-style-vehicle-type-3-whl"
                                  {...register("threeWheeler", {
                                    required: true,
                                  })}
                                  type="radio"
                                  checked={threeWheeler}
                                  onChange={(e) =>
                                    handleCheckChange(e, "threeWheeler")
                                  }
                                />
                                <img
                                  className="three-wheeler-img-style"
                                  src={ThreeWheelerIcon}
                                  alt="zeuez logo"
                                />
                              </div>
                            </div>
                            <lable className="subtitle-style">
                              <div className="web-view-style-3-wheeler">
                                3 Wheeler
                              </div>
                            </lable>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop: 1,
                                marginLeft: 5,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: -10,
                                }}
                              >
                                <input
                                  className="form-field-style-vehicle-type-4-whl"
                                  {...register("pickup", { required: true })}
                                  type="radio"
                                  checked={pickup}
                                  onChange={(e) =>
                                    handleCheckChange(e, "pickup")
                                  }
                                />
                                <img
                                  className="four-wheeler-img-style"
                                  src={FourWheelerIcon}
                                  alt="zeuez logo"
                                />
                              </div>
                              <lable className="subtitle-style">
                                <div className="web-view-style-pickup">
                                  Pick up
                                </div>
                              </lable>
                            </div>
                            {/* {(errors.nextDay === false ||
                              errors.sameDay === false) && (
                              <p className="error-font-style">
                                Please choose type.
                              </p>
                            )}{" "} */}
                          </div>
                          {isVehicleChoosed && (
                            <div
                              className="payment-error-style"
                              style={{ marginTop: 14, marginBottom: -17 }}
                            >
                              <p className="error-font-style">
                                Please choose vehicle type
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="payment-type-alignment">
                        <div className="subtitle-style">
                          <div className="payment-label-style">I will pay</div>
                        </div>
                        <select
                          onChange={handleChange}
                          value={payType}
                          className="form-field-style"
                        >
                          <option value="">Choose payment method</option>
                          <option value="COD">Cash on Delivery</option>
                          <option value="Online payment">Pay Online</option>
                          <option value="PS">Pay at Shop</option>
                        </select>
                      </div>
                      {isPayTypeChoosed && (
                        <div className="payment-error-style">
                          <p className="error-font-style">
                            Please choose payment type
                          </p>
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: -10,
                        }}
                      >
                        <input
                          className="form-field-style-map"
                          style={{ marginLeft: 15, width: 17, height: 17 }}
                          onClick={() => {
                            setIsAgreedTermsandCond(true);
                          }}
                          type="checkbox"
                        />
                        <label
                          className="subtitle-style-map"
                          onClick={() => {
                            setShowTermsandConditionsPopup(true);
                          }}
                        >
                          I've read and agree to the{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "#0000FF",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            terms and conditions
                          </span>
                        </label>
                      </div>
                      <div style={{ marginTop: 710, position: "absolute" }}>
                        <div
                          disabled={
                            isEnableButton ||
                            !isAgreedTermsandCond ||
                            isClickedSubmit
                          }
                          className={
                            isEnableButton || !isAgreedTermsandCond
                              ? "button-style-disable"
                              : "button-style-main"
                          }
                          style={{ paddingTop: 10, paddingBottom: -8 }}
                          // type="submit"
                          name="Book"
                          onClick={() => {
                            setIsClickedSubmit(true);
                            showBookedPopup();
                          }}
                        >
                          Submit
                        </div>
                        {/* <input
                disabled={isEnableButton || !isAgreedTermsandCond}
                className={
                  isEnableButton || !isAgreedTermsandCond
                    ? "button-style-disable"
                    : "button-style"
                }
                // type="submit"
                name="Book"
                onClick={showBookedPopup}
              /> */}
                      </div>
                    </form>
                  </div>
                  <form
                    ref={ccForm}
                    id="nonseamless"
                    method="post"
                    name="redirect"
                    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                  >
                    <input
                      type="hidden"
                      id="encRequest"
                      name="encRequest"
                      value={encRequest}
                    />
                    <input
                      type="hidden"
                      name="access_code"
                      id="access_code"
                      value={accessCode}
                    />
                  </form>
                </div>
              </div>
              <div className="App-inner-container">
                <div className="shop-details">
                  <p className="shop-detail-style" style={{ padding: 10 }}>
                    SHOP NAME:{" "}
                    <span className="shop-sub-detail-style">
                      {shopDetails.shop_name}
                    </span>
                  </p>
                  <p className="shop-detail-style" style={{ marginTop: -15 }}>
                    SHOP TYPE:{" "}
                    <span className="shop-sub-detail-style">
                      {shopDetails.shop_type}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}

          {showRatePopup && selectedAddress.address ? (
            <>
              <m.div
                className="rate-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>

              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
                style={{
                  backgroundColor: "#ffffff",
                  marginTop: 30,
                  marginLeft: 60,
                }}
              >
                {isValidForFareCal ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="pop-up-title-style"
                        style={{ marginBottom: 12, marginTop: 39 }}
                      >
                        Your Price !
                      </div>
                      {threeWheeler ? (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Three Wheeler
                        </div>
                      ) : twoWheeler ? (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Two Wheeler
                        </div>
                      ) : (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Four Wheeler
                        </div>
                      )}

                      {fareDetails.total_fare ? (
                        <div
                          className="estimate-rate-style"
                          style={{ marginTop: 103 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: -12,
                            }}
                          >
                            <div>
                              <img
                                style={{
                                  width: 18,
                                  height: 18,
                                  marginTop: 8,
                                  marginBottom: -20,
                                  padding: 8,
                                }}
                                src={IndianCurrencyIcon}
                                alt="zeuez logo"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 22,

                                marginLeft: -8,
                              }}
                            >
                              {fareDetails.total_fare}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginBottom: -80,
                            marginLeft: 100,
                            marginTop: 20,
                          }}
                        >
                          <ReactLoading
                            type="balls"
                            color="#30A46C"
                            height={"45%"}
                            width={"45%"}
                          />
                          <div
                            className="pop-up-content-style"
                            style={{ marginTop: -25, marginLeft: -90 }}
                          >
                            Please wait...
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 130,
                        marginLeft: 17,
                      }}
                    >
                      <div
                        className="button-style"
                        style={{
                          width: 120,
                          height: 25,
                          paddingTop: 8,
                          marginRight: 7,
                        }}
                        onClick={() => {
                          setConfirmedFare(fareDetails.total_fare);
                          console.log(confirmedFare, "--confirmfare");
                          setShowRatePopup(false);
                          setIsEnableButton(false);
                        }}
                      >
                        Confirm
                      </div>{" "}
                      <div
                        className="button-style"
                        style={{
                          width: 120,
                          height: 25,
                          paddingTop: 8,
                          backgroundColor: "#c40000",
                          boxShadow: "3px 3px #ff7676",
                        }}
                        onClick={() => {
                          setShowRatePopup(false);
                          setIsEnableButton(true);
                        }}
                      >
                        Decline
                      </div>
                    </div>
                  </div>
                ) : showUnavailableMessage ? (
                  <>
                    <div
                      style={{
                        marginLeft: 225,
                        marginTop: 20,
                        fontSize: 20,
                        width: 30,
                        height: 30,
                        borderRadius: 30,
                        backgroundColor: "#30A46C",
                        paddingTop: 3,
                      }}
                      onClick={() => setShowRatePopup(false)}
                    >
                      X
                    </div>
                    <img
                      style={{
                        width: 150,
                        height: 155,
                        marginTop: -22,
                        padding: 8,
                      }}
                      src={Comingsoon}
                      alt="zeuez logo"
                    />
                    <div
                      className="pop-up-content-style"
                      style={{
                        fontSize: 15,
                        padding: 12,
                        marginTop: -10,
                      }}
                    >
                      Thanks for choosing us. We are growing and will soon serve
                      the location you selected!
                    </div>
                  </>
                ) : null}
              </m.div>
            </>
          ) : (
            <div>
              <ToastContainer
                toastStyle={{
                  backgroundColor: "#FF4C4C",
                  color: "#ffffff",
                  fontSize: 13,
                }}
              />
            </div>
          )}
          {/* {isAddressNotSelected ? (
        <Popup trigger={<button> Trigger</button>} position="right center">
          <div>Popup content here !!</div>
        </Popup>
      ) : null} */}
          {showSuccessPopup ? (
            <>
              <m.div
                className="rate-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
              >
                <div>
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      marginTop: 20,
                      marginBottom: -20,
                      backgroundColor: "#30A46C",
                      borderRadius: 28,
                      padding: 8,
                    }}
                    src={TickIcon}
                    alt="zeuez logo"
                  />
                </div>
                <div
                  className="pop-up-title-style"
                  style={{ marginBottom: 20 }}
                >
                  Great!
                </div>
                <div
                  className="pop-up-content-style"
                  style={{ marginBottom: 4 }}
                >
                  Your booking id{" "}
                  <span className="text-highlighter">
                    #{addedBookingResult.id}
                  </span>{" "}
                  has been confirmed!
                </div>
                <div className="pop-up-content-style" style={{ marginTop: 10 }}>
                  You can track your shipment here.
                </div>
                <a
                  href={storeBookingResponse.booking_url}
                  className="pop-up-content-style"
                  style={{
                    textDecoration: "underline",
                    color: "#0000FF",
                    cursor: "pointer",
                  }}
                >
                  {storeBookingResponse.booking_url}
                </a>
                <div
                  className="pop-up-content-style text-highlighter"
                  style={{ marginTop: 25 }}
                >
                  Thank you for choosing minL:)
                </div>
                <div
                  className="button-style-popup"
                  style={{ marginTop: 25 }}
                  onClick={returnHome}
                >
                  OK
                </div>{" "}
              </m.div>
            </>
          ) : null}
          {showFailedPopup ? (
            <>
              <m.div
                className="rate-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
                style={{ height: 250 }}
              >
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      marginTop: 20,
                      marginBottom: -20,
                      backgroundColor: "#c40000",
                      borderRadius: 28,
                      padding: 8,
                      paddingTop: 9,
                      color: "#ffffff",
                      marginLeft: 110,
                      fontSize: 30,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </div>
                </div>
                <div className="pop-up-content-style" style={{ marginTop: 40 }}>
                  Booking failed, Please try again!
                </div>
                <div
                  className="button-style-popup"
                  style={{ marginTop: 30 }}
                  onClick={returnHome}
                >
                  OK
                </div>{" "}
              </m.div>
            </>
          ) : null}
          {showAddressListPage ? (
            <m.div
              className="addressList-outer-container"
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
            >
              <div className="web-view-address-list">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 55,
                  }}
                >
                  <img
                    style={{
                      width: 17,
                      height: 17,
                      marginLeft: 30,
                      marginRight: -40,
                      marginTop: 26,
                      marginBottom: -20,
                      position: "relative",
                    }}
                    src={SearchIcon}
                    alt="zeuez logo"
                  />
                  <input
                    className="form-field-style-search-addressList"
                    placeholder="Search address from list"
                    type="text"
                    onChange={(e) => setQuery(e.target.value)}
                    // onClick={navigate}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={navigateMap}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      marginLeft: 30,
                      marginTop: 20,
                    }}
                  >
                    <img
                      style={{
                        width: 20,
                        height: 20,
                        marginTop: 5,
                        marginBottom: -20,
                      }}
                      src={LocationIcon}
                      alt="zeuez logo"
                    />
                    <div className="subtitle-style-addressList">
                      Choose address from map
                    </div>
                  </div>

                  {isActive ? (
                    <div className="addresslist-line-style-active"></div>
                  ) : (
                    <div className="addresslist-line-style-inactive"></div>
                  )}
                </div>
                <div className="subtitle-style-addressList-2">
                  YOUR ADDRESSES
                </div>
                <div className="form-container-addresslist">
                  {search(addressList).map((data) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                      key={data.id}
                      onClick={() => {
                        selectedFromList(data);
                      }}
                    >
                      <img
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: 40,
                          marginBottom: -60,
                          marginLeft: 10,
                        }}
                        src={RecentSearchIcon}
                        alt="zeuez logo"
                      />

                      <div className="address-main-style">
                        {data.address.slice(0, data.address.indexOf(","))}
                      </div>
                      <div
                        className="address-sub-style"
                        style={{ marginTop: 10, paddingLeft: 15 }}
                      >
                        {data.address}
                      </div>
                      <div className="addresslist-line-style"></div>
                    </div>
                  ))}
                </div>
              </div>
            </m.div>
          ) : null}
          {showMap ? (
            <m.div
              className="map-outer-container"
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              <div className="map-container">
                <GoogleMap
                  center={coords}
                  zoom={15}
                  mapContainerStyle={{ width: "120%", height: "100%" }}
                  onLoad={(map) => setMap(map)}
                >
                  <MarkerF
                    position={coords}
                    draggable={true}
                    onLoad={(map) => onMapLoad(map)}
                    onDragEnd={(e) => {
                      setCoords({
                        ...coords,
                        lat: e.latLng?.lat(),
                        lng: e.latLng?.lng(),
                      });
                      findAddress(e.latLng?.lat(), e.latLng?.lng());
                    }}
                  ></MarkerF>
                </GoogleMap>
              </div>

              <form
                onSubmit={handleSubmit((data) =>
                  console.log(data, "-----data")
                )}
                className="form-display-map"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Autocomplete
                    onLoad={updateOnload}
                    onPlaceChanged={updatePlace}
                    options={{
                      bounds: cityBounds,
                      componentRestrictions: { country: "IN" },
                    }}
                  >
                    <input
                      className="form-field-style-search"
                      // {...register("address", { required: true })}
                      placeholder="Search address or landmark"
                      type="text"
                      onChange={(e) => {
                        setSelectedAddress({
                          ...selectedAddress,
                          address: e.target.value,
                        });
                      }}
                      value={selectedAddress.address}
                      autoFocus={true}
                    />
                  </Autocomplete>
                  <div
                    style={{
                      width: 50,
                      height: 20,
                      color: "#ffffff",
                      backgroundColor: "#30a46c",
                      marginTop: 90,
                      marginLeft: 15,
                      padding: 5,
                      borderRadius: 5,
                    }}
                    onClick={(e) =>
                      setSelectedAddress({
                        ...selectedAddress,
                        address: "",
                      })
                    }
                  >
                    Clear
                  </div>
                </div>
              </form>

              {selectedAddress.address ? (
                <div className="form-container-map">
                  <form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <input
                    className="form-field-style-map"
                    style={{ color: "#bbb" }}
                    // {...register("address")}
                    placeholder="ADDRESS"
                    type="text"
                    value={selectedAddress.address}
                    readOnly
                    // onChange={(e) => {
                    //   setSelectedAddress({
                    //     ...selectedAddress,
                    //     address: e.target.value,
                    //   });
                    // }}
                  /> */}
                    <div
                      style={{
                        width: "85%",
                        height: 800,
                        backgroundColor: "#def5ea",
                        elevation: 1,
                        borderRadius: 8,
                        padding: 8,
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                        marginLeft: 20,
                        marginTop: 10,
                        border:"2px solid"
                      }}
                    >
                      <div>
                        <img
                          className="icon-style"
                          style={{ width: 40, height: 40 }}
                          src={OfficeIcon}
                          alt="edit icon"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: "600",
                          color: "#000000",
                          width: "90%",
                        }}
                      >
                        {selectedAddress.address}
                      </div>
                    </div>
                    <input
                      className="form-field-style-map"
                      {...register("landmark")}
                      placeholder="LANDMARK"
                      type="text"
                      onChange={(e) => {
                        setSelectedAddress({
                          ...selectedAddress,
                          landmark: e.target.value,
                        });
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      className="web-view-style-map-check"
                    >
                      <input
                        className="form-field-style-map"
                        style={{ width: 17, height: 17 }}
                        {...register("saveAddress")}
                        onClick={() => {
                          setSaveAddress(!saveAddress);
                        }}
                        type="checkbox"
                      />
                      <label className="subtitle-style-map">
                        Add to saved addresses
                      </label>
                    </div>

                    <div style={{ marginTop: 20, marginLeft: 25 }}>
                      <div
                        className="button-style-popup"
                        // type="submit"

                        onClick={navigateToHome}
                      >
                        Add
                      </div>
                    </div>
                  </form>
                </div>
              ) : null}
            </m.div>
          ) : null}
          {showTermsandConditionsPopup ? (
            <>
              <m.div
                className="rate-outer-container-termandcon"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>

              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container-termandcon"
              >
                <div
                  onClick={() => setShowTermsandConditionsPopup(false)}
                  className="close-button"
                >
                  X
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  About Us
                </div>
                <div className="subtitle" style={{ marginTop: 20 }}>
                  What we do
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  We are a motivated team of young dynamic individuals with a
                  vision to be a reliable end-to-end city logistics partner for
                  MSMEs in Chennai and beyond, deliver goods on-demand and allow
                  MSMEs to focus on their core business. For the past few years
                  we have been conducting surveys and studies to understand the
                  characteristics of the freight industry, analysing issues and
                  challenges faced by both shippers and truckers from a variety
                  of perspectives.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  GoodMove app is an initiative to consolidate freight in-order
                  to decrease the number of less than truckload trips and reduce
                  road congestion and emission of GHGs.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  For more details, please visit our website
                  <a href="https://goodmove.in/" style={{ marginLeft: 10 }}>
                    www.goodmove.in
                  </a>
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Terms and Conditions
                </div>
                <div className="subtitle" style={{ marginTop: 20 }}>
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile app/
                  platform. The following Terms &amp; Conditions shall apply to
                  customers utilising the Services offered by the GoodMove
                </div>
                <div className="subtitle"> Mobile app to Transport:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  The customer shall pay the fare (as agreed), toll (where
                  applicable) and any fee or levy presently payable or
                  hereinafter imposed by the law or required to be paid for
                  availing of the transport Services. The customer agrees and
                  accepts that the use of our Services is at the sole risk of
                  the Customer, and further acknowledges that the GoodMove
                  disclaims all representations and warranties of any kind,
                  whether express or implied.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  The customer shall ensure that he/she will not indulge in any
                  of the following activities while availing the service :
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Asking the driver to break any Traffic/RTO/City Police and/or
                  government rules for any purpose. The driver has the right to
                  refuse such a request by the customer. The driver also has the
                  right to refuse such a pick-up.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Pressurizing the driver to overload the truck with the
                  consignment than the allowed limit. The customer shall
                  indemnify company from and against and in respect of any or
                  all liabilities, losses, charges and expenses (including legal
                  fees and costs on a full indemnity basis) claims, demands,
                  actions and proceedings which GoodMove may incur or sustain
                  directly or indirectly from or by any reason of or in relation
                  to the use or proposed use of the services by the customer and
                  shall pay such sums on demand on the GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to disclose to all companies within
                  its group, or any government body as so required by the law or
                  by directive or request from any government body, the
                  particulars of the customer in the possession of company in
                  any way as company, in its absolute discretion, deems fit or
                  if it considers it in its interests to do so. GoodMove shall
                  be entitled at any time without giving any reason to terminate
                  the booking to transport the consignment done by the customer.
                  User(s) shall indemnify GoodMove with respect to any expenses
                  incurred with respect to such booking.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  In the case of lost items inside the consolidation centre or
                  during the journey, GoodMove will try to locate the items on a
                  best-effort basis but is not responsible for the same in case
                  of loss or damage to the same. GoodMove aggregates its
                  vehicles for the purpose of providing services. In the event
                  of loss of any item, user(s) shall not have any right to
                  withhold the payment to be made towards the third party or
                  agent operating this mobile app. Any complaint in respect of
                  the services or the use of the freight vehicle, the customer
                  has to inform GoodMove of the same in writing within 24 hours
                  of using the freight vehicle or the services of GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall not be liable for any conduct of the drivers of
                  freight vehicles. However, GoodMove encourages you to notify
                  it of any complaints that you may have against the driver that
                  you may have hired using our Services.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to add to, vary or amend any or all
                  these terms and conditions at any time and the customer shall
                  be bound by such addition, variation or amendment once such
                  addition, variation or amendment are incorporated into these
                  terms and conditions.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  All the calls made to the customer care are recorded for
                  quality and training purposes. In the event you place a query
                  on our app including query with respect to our services,
                  applicable fees or terms of service, you hereby expressly
                  agree to consent to receive our responses, whether by way of
                  telephonic calls or electronic mail, to such query and all
                  related information with respect to our services. For removal
                  of doubts, related information shall include without
                  limitation any marketing and/or commercial information, you
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  “National Do Not Call Register” or the “Private Do Not Call
                  Register” in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Toll Charges :</div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  In case of a toll on your trip, return toll fare will be
                  charged.
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile
                  app/platform. All the calls made to the customer care are
                  recorded for quality and training purposes. In the event you
                  place a query on our app including query with respect to our
                  Services, applicable fees or terms of Service, You hereby
                  expressly agree to consent to receive our responses, whether
                  by way of telephonic calls or electronic mail, to such query
                  and all related information with respect to our Services. For
                  removal of doubts, related information shall include without
                  limitation any marketing and/or commercial information. You
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  'National Do Not Call Register' or the 'Private Do Not Call
                  Register' in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Usage of Location data:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  GoodMove will collect the precise location of the user's
                  device when the app is running in the foreground or
                  background, if the app is allowed to access location services
                  through permissions in the mobile OS. We use this data to
                  enhance your use of our apps, including to improve pick-up/
                  drop off locations, enable safety features, and prevent and
                  detect fraud.
                </div>
                <div className="subtitle">
                  We may collect the following information:
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Name of the person, company, job title. Contact information
                  including email address. Demographic information such as
                  postcode, address, mobile number, login name, preferences and
                  interests. Use Our Services, we may collect and store
                  information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your phone number, address,
                  email, billing information, invoices, etc. Other information
                  relevant to customer surveys and/or offers, app install,
                  uninstall and other installed apps information.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  {" "}
                  We require this information to understand your needs and
                  provide you with a better service
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  We may use the information to improve our products and
                  services. We may periodically send promotional emails, SMSs
                  and make voice calls about new products, special offers or
                  other information which we think you may find interesting
                  using the email address and mobile number which you have
                  provided. From time to time, we may also use your information
                  to contact you for market research purposes. We may contact
                  you by email, SMS, voice, fax or mail. We may use the
                  information to customize the website according to your
                  interests. You may specifically opt-out of the above in
                  accordance with the Telecom Commercial Communications Customer
                  Preference Regulations, 2018 or otherwise specified. In case
                  you wish to opt out of the same, please contact us at
                  admin@gogoodmove.com
                </div>{" "}
              </m.div>
            </>
          ) : null}
          {showLoader ? (
            <>
              <m.div
                className="rate-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>

              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="loader-style"
              >
                <div
                  style={{
                    marginBottom: -80,
                    marginLeft: 34,
                    marginTop: 3,
                  }}
                >
                  <ReactLoading
                    type="balls"
                    color="#30A46C"
                    height={"65%"}
                    width={"65%"}
                  />
                  <div
                    className="pop-up-content-style"
                    style={{ marginTop: -25, marginLeft: -30 }}
                  >
                    Please wait...
                  </div>
                </div>
              </m.div>
            </>
          ) : null}
        </m.div>
      )}
    </>
  );
}

export default Booking;
