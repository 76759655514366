import { useEffect, useState } from "react";
import { motion as m } from "framer-motion";
import CalenderIcon from "./images/Calendar50x50.png";
import TruckIcon from "./images/truck-50x50.png";
import RupeeIcon from "./images/Rupee-50x50.png";
import WeightIcon from "./images/weight.png";
import DropIcon from "./images/truckIcon.png";
import PickupIcon from "./images/delivered.jpg";
import DotIcon from "./images/dotted_line.png";
import { getBookingHistory } from "./api/shop";
import { useParams, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import moment from "moment";

export default function OrderHistory() {
  const { mobileNumber } = useParams();
  const [openDetailsPage, setOpenDetailsPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [selectbookingData, setSelectbookingData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = bookingList.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = bookingList.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getCustomerBookingHistory = async () => {
      setShowLoader(true);
      const storedMobileNumber = localStorage.getItem("mobile");
      console.log(mobileNumber, storedMobileNumber, "val2")
      if (mobileNumber === storedMobileNumber) {
        let reqObj={
          customer_mobile : mobileNumber
        }
        console.log("enter")
        const result = await getBookingHistory(reqObj);
        if (result.success) {
          setShowLoader(false);
          setBookingList(result.data.bookings);
        }
      } else {
        console.log("false")
        localStorage.removeItem("mobile");
        navigate("/otpverification");
      }
    }
    getCustomerBookingHistory();

  }, [mobileNumber, navigate]);

  return (
    <>
      <div className="order-history-container" style={{ marginTop: 100 }}>
        <div style={{ fontSize: 25, fontWeight: "bold", fontFamily: "monospace", color: "green", marginTop: 15 }}>BOOKING HISTORY</div>
        {displayedData.map((data) => (
          <div
            className="list-container"
            onClick={() => {
              setOpenDetailsPage(true);
              setSelectbookingData(data);
            }}
          >
            <div className="booking-id-style">Booking id : {data.id}</div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -350,
                marginTop: 48,
              }}
            >
              <img
                src={CalenderIcon}
                alt="zeuez logo"
                className="iconStyle"
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                }}
              />
              <div className="time-text-style">
                {moment(data.created_at).format("YYYY-MM-DD")}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -350,
                marginTop: 12,
              }}
            >
              <img
                className="iconStyle"
                style={{
                  position: "absolute",
                  width: 22,
                  height: 22,
                }}
                src={TruckIcon}
                alt="zeuez logo"
              />
              <div className="trip-status">{data.booking_status}</div>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: 10,
                marginTop: 4,
              }}
            >
              <img
                style={{
                  position: "absolute",
                  width: 21,
                  height: 21,
                  marginTop: -20,
                }}
                src={RupeeIcon}
                alt="zeuez logo"
              />
              <div className="payment-status">{data.payment_status}</div>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: 40,
                marginTop: -55,
              }}
            >
              <img
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  marginLeft: -12,
                }}
                src={WeightIcon}
                alt="zeuez logo"
              />
              <div className="weight-style">
                {data?.commodity_weight + "  kgs"}
              </div>
            </div>
          </div>
        ))}
        {showLoader ? null : (<div style={{ marginTop: '10px', padding: '20px' }}>
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            &lt;
          </button>
          <span style={{ margin: '0 10px', color: 'black' }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            &gt;
          </button>
        </div>)}
        {showLoader ? (
          <>
            {/* <m.div
              className="rate-outer-container"
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 0.25, ease: "easeOut" }}
              exit={{ opacity: 0 }}
              style={{ marginTop: -470, marginLeft: -20 }}
            ></m.div> */}

            <m.div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 0.25, ease: "easeOut" }}
              exit={{ opacity: 0 }}
              className="history-inner-container"
              style={{
                backgroundColor: "#ffffff",
                marginTop: 100,
                marginLeft: 100,
                width: 200,
                height: 100,
                borderRadius: 50,
                position: "relative",
              }}
            >
              <div
                style={{
                  marginLeft: 65,
                }}
              >
                <ReactLoading
                  type="balls"
                  color="#30A46C"
                  height={"50%"}
                  width={"50%"}
                />
                <div
                  className="pop-up-content-style"
                  style={{ marginTop: -15, marginLeft: -60 }}
                >
                  Please wait...
                </div>
              </div>
            </m.div>
          </>
        ) : null}
      </div>
      {openDetailsPage ? (
        <>
          <m.div
            className="history-outer-container"
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            style={{ position: "absolute", top: 0 }}
          ></m.div>
          <m.div
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="history-inner-container"
          >
            <div
              className="close-button"
              onClick={() => setOpenDetailsPage(false)}
            >
              X
            </div>
            <div className="inner-booking-id">
              Booking id : {selectbookingData.id}
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -320,
                marginTop: 78,
              }}
            >
              <img
                className="inner-truck-icon"
                src={TruckIcon}
                alt="zeuez logo"
              />
              <div className="inner-booking-status">
                {selectbookingData.booking_status}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -322,
                marginTop: 40,
              }}
            >
              <img
                className="inner-money-icon"
                src={RupeeIcon}
                alt="zeuez logo"
              />
              <div className="inner-payment-status">
                {selectbookingData.payment_status}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: 230,
                marginTop: -40,
                position: "absolute",
              }}
            >
              <img
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: -12,
                  position: "absolute",
                }}
                src={WeightIcon}
                alt="zeuez logo"
              />
              <div className="inner-weight">
                {selectbookingData?.commodity_weight + "  kgs"}
              </div>
            </div>
            <div className="trip-details">TRIP DETAILS</div>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -316,
                marginTop: 110,
              }}
            >
              <img className="pickup-icon" src={PickupIcon} alt="zeuez logo" />
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <div className="pickup-loc">
                  {selectbookingData.pickup_location}
                  <div style={{ fontWeight: "bold", marginTop: 10 }}>
                    {" "}
                    {moment(selectbookingData.pickup_time).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img className="dot-icon" src={DotIcon} alt="zeuez logo" />
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                marginLeft: -316,
                marginTop: 210,
              }}
            >
              <img className="drop-icon" src={DropIcon} alt="zeuez logo" />
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <div className="drop-loc">
                  {selectbookingData.dropoff_location}
                  <div style={{ fontWeight: "bold", marginTop: 10 }}>
                    {" "}
                    {moment(selectbookingData.dropoff_time).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </m.div>{" "}
        </>
      ) : null}{" "}
    </>
  );
}
