// Home.js
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="container">
      <h1 className="fade-in welcome-message" style={{color:"#30A46C"}}>Welcome to minL</h1>
      <div className="center-container">
        <h4 style={{ color: 'black' }}>Please choose your city</h4>
        <div className="options">
          <button className="option" onClick={() => { window.location.href = '/chennai'; }}>Chennai</button>
          <button className="option" onClick={() => { window.location.href = '/delhi'; }}>Delhi</button>
        </div>
      </div>
    </div>
  );
}


export default Home;
