// const baseUrl = "http://52.66.195.223:3000/api";
// const baseUrl = "https://test-api.goodmove.in/api";
const baseUrl = "https://api.goodmove.in/api";
// const baseUrl = "http://3.108.44.83:3000/api";
// const baseUrl = "https://api.goodmove.in/api";

export async function getShopDetails(shop_id) {
  const res = await fetch(baseUrl + "/shop/getShopDetails", {
    method: "POST",
    body: JSON.stringify({ shop_id }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getFareBytype(farePayload) {
  const res = await fetch(baseUrl + "/location/getHomeDeliveryFare", {
    method: "POST",
    body: JSON.stringify(farePayload),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getFareByAddresses(farePayload) {
  const res = await fetch(
    baseUrl + "/location/getHomeDeliveryFareByAddresses",
    {
      method: "POST",
      body: JSON.stringify(farePayload),
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return res.json();
}

export async function getDelhiHomeDeliveryFareByAddresses(farePayload) {
  const res = await fetch(
    baseUrl + "/location/getDelhiHomeDeliveryFareByAddresses",
    {
      method: "POST",
      body: JSON.stringify(farePayload),
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return res.json();
}

export async function addBooking(bookingPayload) {
  const res = await fetch(baseUrl + "/booking/addShopBooking", {
    method: "POST",
    body: JSON.stringify(bookingPayload),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function deleteBooking(bookingPayload) {
  const res = await fetch(baseUrl + "/booking/deleteBooking/" +bookingPayload.booking_id, {
    method: "POST",
    body: JSON.stringify(bookingPayload),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}



export async function getAddressListPerUser(customer_mobile) {
  console.log(customer_mobile, "----customer_mobilebbb");
  const res = await fetch(baseUrl + "/users/getCustomerAddress", {
    method: "POST",
    body: JSON.stringify({ customer_mobile }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function uploadInvoice(fileData) {
  console.log({ file: fileData }, "----fileData");
  const res = await fetch(baseUrl + "/booking/uploadreceipt", {
    method: "POST",
    body: JSON.stringify({ file: fileData }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getCCSessionData(fileData) {
  console.log({ file: fileData }, "----fileData");
  const res = await fetch(baseUrl + "/booking/getCCavenueSession", {
    method: "POST",
    body: JSON.stringify({ file: fileData }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getBookingsBySearch(searchQuery) {
  const res = await fetch(baseUrl + "/booking/getBookings", {
    method: "POST",
    body: JSON.stringify(searchQuery),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getMinlBookingPayDetails(searchQuery) {
  const res = await fetch(baseUrl + "/booking/getMinlBookingPayDetails", {
    method: "POST",
    body: JSON.stringify(searchQuery),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}



export async function checkSourceLocation(bookingPayload) {
  const res = await fetch(baseUrl + "/location/checkSourceLocation", {
    method: "POST",
    body: JSON.stringify(bookingPayload),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getBookingDistance(bookingPayload) {
  const res = await fetch(baseUrl + "/location/getBookingDistance", {
    method: "POST",
    body: JSON.stringify(bookingPayload),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}
export async function getBookingHistory(data) {
  const res = await fetch(baseUrl + "/booking/getMinlBookingsByCustomer", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function getCustomerLog(data) {
  const res = await fetch(baseUrl + "/booking/customerBookingLog", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function sendOtp(customer_mobile) {
  const res = await fetch(baseUrl + "/users/sendOtp", {
    method: "POST",
    body: JSON.stringify({ mobile: customer_mobile }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function verifyOtp(customer_mobile, otp) {
  const res = await fetch(baseUrl + "/users/verifyOtp", {
    method: "POST",
    body: JSON.stringify({ mobile: customer_mobile, otp }),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}

export async function verifyNumber(mobile) {
  const res = await fetch(baseUrl + "/location/getDelhiHomeDeliveryFreeFare", {
    method: "POST",
    body: JSON.stringify({ customer_mobile: mobile}),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.json();
}