import { useNavigate, useParams } from "react-router-dom";
import TickIcon from "./images/tickicon.png";
import IndianCurrencyIcon from "./images/indianCurrencyBlack.jpg";
import { useEffect, useState } from "react";
import { getBookingsBySearch } from "./api/shop";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const { booking_id } = useParams();
  const [addedBookDetails, setAddedBookDetails] = useState([]);
  const [shopDetails, setShopDetails] = useState({});

  useEffect(() => {
    // const addedBookingDetails = localStorage.getItem("added booking");
    // const bookingDetails = JSON.parse(addedBookingDetails);

    // console.log(bookingDetails, "------bookingDetails");
    localStorage.removeItem('goToPayment')
    localStorage.removeItem('added_booking')
    const fetchBookingDetails = async () => {
      const addedBookingDetails = await getBookingsBySearch({ id: booking_id });

      const shopDetails = localStorage.getItem("shopDetails");
      console.log(addedBookingDetails, "------bookingDetails");
      setShopDetails(JSON.parse(shopDetails));

      setAddedBookDetails(addedBookingDetails);
    };

    fetchBookingDetails();
  }, []);
  console.log(addedBookDetails, "----addedBookDetails");
  return (
    <div className="pricing-policy-container" style={{ height: 390 }}>
      <div>
        <img
          style={{
            width: 40,
            height: 40,
            marginTop: 15,
            marginBottom: -20,
            backgroundColor: "#30A46C",
            borderRadius: 28,
            padding: 8,
          }}
          src={TickIcon}
          alt="zeuez logo"
        />
      </div>
      <div className="title-style" style={{ marginTop: 40 }}>
        Payment Successful!
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        Your payment for Booking id{" "}
        <span className="text-highlighter" style={{ fontSize: 10 }}>
          #{booking_id}
        </span>{" "}
        has been successful.
      </div>

      {addedBookDetails.length !== 0 && (
        <>
          <div className="web-view-pay-success">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 5,
              }}
            >
              <div
                className="text-highlighter"
                style={{
                  fontSize: 12,
                  marginRight: 60,
                  marginLeft: 60,
                  marginTop: 5,
                }}
              >
                Amount paid :
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -12,
                }}
              >
                <div>
                  <img
                    style={{
                      width: 12,
                      height: 10,
                      marginTop: -5,
                      marginBottom: 1,
                      padding: 1,
                    }}
                    src={IndianCurrencyIcon}
                    alt="zeuez logo"
                  />
                </div>
                <div
                  className="text-highlighter"
                  style={{ fontSize: 12, marginTop: -1 }}
                >
                  {addedBookDetails.data.bookings[0].fare}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="text-highlighter"
                style={{ fontSize: 12, marginRight: 60, marginLeft: 60 }}
              >
                Transaction ID :
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -12,
                }}
              >
                <div
                  className="text-highlighter"
                  style={{ fontSize: 12, marginTop: -4, marginLeft: -5 }}
                >
                  123456
                </div>
              </div>
            </div>
          </div>
          <div className="pop-up-content-style" style={{ marginTop: 14 }}>
            To track your shipment please click here
          </div>
          <a
            href={addedBookDetails.data.bookings[0].booking_url}
            className="pop-up-content-style"
            style={{
              textDecoration: "underline",
              color: "#0000FF",
              cursor: "pointer",
            }}
          >
            {addedBookDetails.data.bookings[0].booking_url}
          </a>{" "}
        </>
      )}

      <div
        className="button-style-ok"
        onClick={() => navigate(`/shop/${shopDetails.id}`)}
      >
        OK
      </div>
    </div>
  );
}
