export default function DelhiRefundCancel() {
    return (
      <div
        className="privacy-policy-container"
        style={{ height: 250}}
      >
        <div className="title-style" style={{ marginTop: 40 }}>
          Refund and Cancellation Policy
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          All cancellations made 5 minutes after confirming a booking will incur a
          cancellation fee of INR. 50/- (Indian Rupees Fifty only). Cancelling
          four bookings in a day after driver allocation will temporarily suspend
          your account for 24 hours. Figures are subject to change. Any refund if
          so will be reflected in the customer's account within 7 working days.
        </div>
      </div>
    );
  }
  // import React, { useState } from 'react';
  // import Modal from 'react-modal';
  
  // export default function RefundAndCancellationPolicy() {
  //   const [modalIsOpen, setModalIsOpen] = useState(false);
  
  //   const openModal = () => {
  //     setModalIsOpen(true);
  //   };
  
  //   const closeModal = () => {
  //     setModalIsOpen(false);
  //   };
  
  //   return (
  //     <div>
  //       <button onClick={openModal}>Open Refund and Cancellation Policy</button>
  //       <Modal
  //         isOpen={modalIsOpen}
  //         onRequestClose={closeModal}
  //         style={{
  //           overlay: {
  //             backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //           },
  //           content: {
  //             width: '80%',
  //             height: '80%',
  //             margin: 'auto',
  //             borderRadius: '10px',
  //             overflow: 'auto',
  //           },
  //         }}
  //       >
  //         <div
  //           className="privacy-policy-container"
  //           style={{ height: '100%', overflow: 'auto' }}
  //         >
  //           <div className="title-style" style={{ marginTop: 40 }}>
  //             Refund and Cancellation Policy
  //           </div>
  //           <div className="subtitle-style" style={{ margin: 30 }}>
  //             All cancellations made 5 minutes after confirming a booking will incur a
  //             cancellation fee of INR. 50/- (Indian Rupees Fifty only). Cancelling
  //             four bookings in a day after driver allocation will temporarily suspend
  //             your account for 24 hours. Figures are subject to change. Any refund if
  //             so will be reflected in the customer's account within 7 working days.
  //           </div>
  //           <button onClick={closeModal}>Close</button>
  //         </div>
  //       </Modal>
  //     </div>
  //   );
  // }
  