export default function DelhiContactUs() {
    return (
      <div
        className="privacy-policy-container"
        style={{ height: 250 }}
      >
        <div className="title-style" style={{ marginTop: 40 }}>
          Contact Us
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          01FA, First Floor, IITM Research Park, Kanagam Road, Taramani, Adyar,
          Chennai-600113
        </div>
      </div>
    );
  }
  