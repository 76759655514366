import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, useParams } from "react-router-dom";
import ShopBooking from "./shopbooking";
import Booking from "./booking";
import PaymentPage from "./paymentPage";
import Navbar from "./nav";
import AboutUs from "./aboutUs";
import ContactUs from "./contactUs";
import PrivacyPolicy from "./privacyPolicy";
import RefundandCancellationPolicy from "./refundCancellation";
import TermandConditions from "./termsandconditions";
import PricingPolicy from "./pricingPolicy";
import PaymentSuccess from "./paymentSuccess";
import PaymentFailed from "./paymentFailed";
import OrderHistory from "./orderHistory";
import OtpVerification from "./otpVerification";
import OtpVerifyDelhi from "./otpVerificationDelhi";
import OrderHistoryDelhi from "./orderHistoryDelhi";
import DelhiAboutUs from "./delhiAboutUs";
import DelhiTermandConditions from "./delhiTerms";
import DelhiPrivacyPolicy from './delhiPrivacyPolicy';
import DelhiRefundCancel from "./delhiRefundCancel";
import DelhiPricingPolicy from './delhiPricingPolicy';
import DelhiContactUs from './delhiContactUs';
import Home from "./Home";

function App() {
  let Component;
  // switch (window.location.pathname) {
  //   case "/aboutus":
  //     Component = <AboutUs />;
  //     break;
  //   case "/termsandconditions":
  //     Component = <TermandConditions />;
  //     break;
  //   case "/privacypolicy":
  //     Component = <PrivacyPolicy />;
  //     break;
  //   case "/refundandcancellation":
  //     Component = <RefundandCancellationPolicy />;
  //     break;
  //   case "/contact":
  //     Component = <ContactUs />;
  //     break;
  //   case "/pricingPolicy":
  //     Component = <PricingPolicy />;
  //     break;
  //   case "/orderHistory/:mobileNumber":
  //     Component = <OrderHistory />;
  //     break;
  //   case "/otpVerification":
  //     Component = <OtpVerification />;
  //     break;
  //   default:
  //     Component = "";
  // }
  return (
    <div className="App App-header">
      <Router>
        <Routes>
          <Route path="/shop/:shop_id" element={<ShopBooking />} />
          <Route path="/:city" element={<Booking />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />

          <Route
            path="/paymentsuccess/:booking_id"
            element={<PaymentSuccess />}
          />
          <Route
            path="/paymentfailed/:booking_id"
            element={<PaymentFailed />}
          />
          <Route
            path="/orderHistory/:mobileNumber"
            element={<OrderHistory />}
          />
          <Route 
            path="/orderHistory/delhi/:mobileNumber"
            element={<OrderHistoryDelhi/>}
          />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/aboutus/delhi" element={<DelhiAboutUs />} />
          <Route path="/termsandconditions" element={<TermandConditions />} />
          <Route path="/termsandconditions/delhi" element={<DelhiTermandConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/privacypolicy/delhi" element={<DelhiPrivacyPolicy />} />
          <Route path="/refundandcancellation" element={<RefundandCancellationPolicy />} />
          <Route path="/refundandcancellation/delhi" element={<DelhiRefundCancel />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contact/delhi" element={<DelhiContactUs />} />
          <Route path="/pricingPolicy" element={<PricingPolicy />} />
          <Route path="/pricingPolicy/delhi" element={<DelhiPricingPolicy />} />
          <Route path="/otpVerification" element={<OtpVerification />} />
          <Route path="/otpVerification/delhi" element={<OtpVerifyDelhi />} />
        </Routes>
      
      
      <Navbar />
      {Component}
      </Router>
    </div>
  );
}

export default App;
