import { useForm } from "react-hook-form";
import { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion as m } from "framer-motion";
import {
  addBooking,
  getFareByAddresses,
  getDelhiHomeDeliveryFareByAddresses,
  getShopDetails,
  sendOtp,
  verifyOtp,
  // getCCSessionData,
  getBookingDistance,
  getCustomerLog,
  verifyNumber,
  // checkSourceLocation,
} from "./api/shop";
import TwoWheelerIcon from "./images/2wheelerfinal.png";
import ThreeWheelerIcon from "./images/3wheelerFinal.png";
import FourWheelerIcon from "./images/4wheelerfinal.png";
import TickIcon from "./images/tickicon.png";
import OfficeIcon from "./images/office-icon.jpg";
import Comingsoon from "./images/coming_soon.png";
import IndianCurrencyIcon from "./images/indian_rupee.png";
import LocationIcon from "./images/location-icon-zeufz.png";
import RecentSearchIcon from "./images/recent.png";
import SearchIcon from "./images/icons8-search-52.png";
import { getAddressListPerUser } from "./api/shop";
import ReactLoading from "react-loading";
import { BsInfoCircleFill } from "react-icons/bs";
// eslint-disable-next-line
import Geocode from "react-geocode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  // Autocomplete,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import moment from "moment";
import Modal from 'react-modal';
// import { add } from "date-fns";
import axios, { all } from "axios";
Geocode.setApiKey("AIzaSyBeoSc41NabP50zIM7bi2gMYTrolDSRmBA");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
const GOOGLE_API_KEY = "AIzaSyBeoSc41NabP50zIM7bi2gMYTrolDSRmBA";
// const baseUrl = "https://test-api.goodmove.in/api";
const baseUrl = "https://api.goodmove.in/api";
// const baseUrl = "http://52.66.195.223:3000/api";
const libraries = ['places'];


function ShopBooking(params) {
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const urlParts = currentUrl?.split('/');
  const bookingId = urlParts[urlParts.length - 1];
  console.log(bookingId, "id val")
  const { shop_id } = useParams();
  const allowedPincodes = bookingId === "delhi"
    ? ["110003", "110005", "110006"]
    : ["600017", "600018", "600040", "600004", "600107"];

  const defaultCoords = bookingId === "delhi"
    ? { lat: 28.6004, lng: 77.2270 }
    : { lat: 13.0418, lng: 80.2341 };
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  } = useForm();
  const [mobileCheck, setMobileCheck] = useState(false);
  const [payType, setPaytype] = useState("");
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickupCheck, setPickupCheck] = useState(false);
  const [dropoffCheck, setDropoffCheck] = useState(false);
  const [twoWheeler, setTwoWheeler] = useState(false);
  const [threeWheeler, setThreeWheeler] = useState(false);
  const [pickup, setPickup] = useState(false);
  const [vegam, setVegam] = useState(false);
  const [vivegam, setVivegam] = useState(false);
  const [isBookingTypeSelected, setIsBookingTypeSelected] = useState(false);
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);
  const [shopDetails, setShopDetails] = useState({});
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  // const [showFailedPopup, setShowFailedPopup] = useState(false);
  const [coords, setCoords] = useState(defaultCoords);
  const [isValidForFareCal, setIsValidForFareCal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  // eslint-disable-next-line
  const [map, setMap] = useState(/** @type goodle.maps.map */ null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAddress, setIsAddress] = useState(false);
  const [isAddressDrop, setIsAddressDrop] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    customer_mobile: "",
    dropFromTime: "",
    dropToTime: "",
    file: "",
    vehicle_type: "",
    fare: "",
    bagId: 0,
    save_address: false,
  });
  // const [isSourceVerified, setIsSourceVerified] = useState(false);
  const [isDestinationVerified, setIsDestinationVerified] = useState(false);
  const [checked, setChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [fareDetails, setFareDetails] = useState({});
  const [confirmedFare, setConfirmedFare] = useState(0);
  const [showUnavailableMessage, setShowUnavailableMessage] = useState(false);
  const [selectedPickupAddress, setSelectedPickupAddress] = useState({
    address: "",
    address_type: "Pickup",
    city: "",
    country: "",
    // customer_id: selectedCustomerId,
    latitude: 0,
    longitude: 0,
    // mobile: selectedCustomerPhone.slice(-10),
    pincode: "",
    receiver_contact: "",
    receiver_name: "",
    state: "",
    landmark: "",
  });
  const [selectedAddress, setSelectedAddress] = useState({
    address: "",
    address_type: "dropoff",
    city: "",
    country: "",
    // customer_id: selectedCustomerId,
    latitude: 0,
    longitude: 0,
    // mobile: selectedCustomerPhone.slice(-10),
    pincode: "",
    receiver_contact: "",
    receiver_name: "",
    state: "",
    landmark: "",
  });
  const [addedBookingResult, setAddedBookingResult] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [showAddressListPage, setShowAddressListPage] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [query, setQuery] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isPayTypeChoosed, setIsPayTypeChoosed] = useState(false);
  // eslint-disable-next-line
  const [isEnableButton, setIsEnableButton] = useState(true);
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [storeBookingResponse, setStoreBookingResponse] = useState({});
  const [showAppLoader, setShowAppLoader] = useState(false);
  const [addressType, setAddressType] = useState(null);
  const [isVehicleChoosed, setIsVehicleChoosed] = useState(false);
  const [valConfirm, setValConfirm] = useState(false);
  const [dragCount, setDragCount] = useState(0);
  const maxDragCount = 3;
  const [buttonDisable, setButtonDisable] = useState(false)
  const [otpInput1, setOtpInput1] = useState("");
  const [otpInput2, setOtpInput2] = useState("");
  const [otpInput3, setOtpInput3] = useState("");
  const [otpInput4, setOtpInput4] = useState("");
  const [checkOtp, setCheckOtp] = useState(false);
  const [disCheck, setDisCheck] = useState(false);
  const [isInfoModalOpen, setInfoIsModalOpen] = useState(false);
  const [wInfoModalOpen, setwInfoIsModalOpen] = useState(false);
  const [goToPayment, setGoToPayment] = useState(false);
  const [rideCheck, setRideCheck] = useState(false);
  const ccForm = useRef();
  const notify = () => toast("Please choose delivery location!");
  const errorMessage = () => toast("Please check OTP")
  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();

  const mapOpen = () => {
    setIsActive(true)
    setShowMap(true);
  }
  const handleChangeterms = () => {
    setChecked(!checked);
    setTermsError(false);
  };
  // const google = window.google;
  const search = (options) => {
    return options.filter((item) =>
      item.address.toString().toLowerCase().includes(query)
    );
  };

  const handleBeforeUnload = (event) => {
    // Check if the event is due to the back button being clicked
    if (event.clientX < 0 && event.clientY < 0) {
      // Show the confirmation popup
      event.returnValue = "Are you sure you want to leave?";
    }
  };
  useEffect(() => {
    if (selectedAddress.address?.length > 15) {
      setDropoffCheck(true);
      setPickupCheck(false);
      console.log("first", selectedAddress);
      validatePin()

    }
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedPickupAddress.address?.length > 15) {
      setPickupCheck(true);
      setDropoffCheck(false);
      console.log("first jknksdjbfaisias", selectedPickupAddress);
      validatePin()
    }
  }, [selectedPickupAddress])

  const init = async () => {
    let goToPayment = await localStorage.getItem('goToPayment');
    let booking = await localStorage.getItem('added_booking');
    // console.log(goToPayment, "goToPayment")
    // console.log(booking, "booking")
    if (goToPayment) {
      let bookingData = JSON.parse(booking)
      navigate("/paymentfailed/" + bookingData.bookingDetails.id)
    }
  }
  useEffect(() => {
    console.log(storeBookingResponse)
    console.log(goToPayment)

    init()
    console.log("______________________________")
    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (threeWheeler || pickup) {
      if (selectedAddress?.latitude !== "" && selectedPickupAddress?.latitude !== "") {
        distanceCheck();
        console.log("main pass check");
      }
      console.log("pass check");
    } else {
      console.log("fail");
    }
  }, [threeWheeler, pickup]);

  useEffect(() => {
    const fetchShopDetails = async () => {
      const result = await getShopDetails(shop_id);

      // console.log(result, "----result");
      setShopDetails(result.data);
      if (shopDetails.id) {
        setShowAppLoader(false);
      }
      setShowAppLoader(true);

      localStorage.setItem("shopDetails", JSON.stringify(result.data));
    };
    fetchShopDetails();
    // eslint-disable-next-line
  }, []);

  const navigateToMap = async (aType) => {
    setAddressType(aType);
    // console.log(bookingDetails.customer_mobile, aType, "-----customer_mobile");
    let addressList;
    if (bookingDetails.customer_mobile) {
      addressList = await getAddressListPerUser(bookingDetails.customer_mobile);
      // console.log(addressList, "-----addressList");

      setAddressList(addressList.data);
      if (addressList.data?.length === 0) {
        // console.log("---com insidex");
        setShowMap(true);
      } else {
        setShowAddressListPage(true);
      }
    } else {
      setShowMap(false);
    }
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });
  if (!isLoaded) {
    return <div style={{ fontSize: 12, color: "#000000" }}>map is loading</div>;
  }

  const termsModOpen = () => {
    setTermsModal(true);
  }

  const termsModClose = () => {
    setTermsModal(false);
  }

  const openMapModal = () => {
    setShowModal(true);
  };

  const closeMapModal = () => {
    setShowModal(false);
    setQuery("");
  };

  const declineCustomerLog = async () => {
    let fromTime;
    let toFime;
    if (vegam) {
      console.log("coming inside 4-------");
      fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
      toFime = moment(fromTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
    } else if (vivegam) {
      console.log("coming inside 5-------");
      fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
      toFime = moment(fromTime).add(6, "hour").format("YYYY-MM-DD HH:mm:ss");
    }
    const bookingPayload = {
      customer_id: 0,
      pickup_location:
        selectedPickupAddress.address + " " + selectedPickupAddress.landmark,
      pickup_time: "",
      pickuptime_flexiblity_status: "Fixed",
      pickup_flexible_time: "",
      dropoff_location:
        selectedAddress.address + " " + selectedAddress.landmark,
      dropoff_time: fromTime,
      dropofftime_flexiblity_status: "Fixed",
      dropoff_flexible_time: toFime,
      commodity_type: 1,
      commodity_weight: 0,
      weight_unit: 12,
      commodity_whole_volume: 1,
      volume_unit: 35,
      commodity_length: "",
      length_unit: 37,
      commodity_breadth: "",
      breadth_unit: 37,
      commodity_height: "",
      height_unit: 37,
      packaging_type: 1,
      receiver_name: "Customer",
      receiver_contact: bookingDetails.customer_mobile,
      instruction: "Test",
      pickup_lat: selectedPickupAddress.latitude,
      pickup_long: selectedPickupAddress.longitude,
      pickup_city: selectedPickupAddress.city,
      pickup_state: selectedPickupAddress.state,
      pickup_country: selectedPickupAddress.country,
      pickup_pincode: selectedPickupAddress.pincode,
      dropoff_city: selectedAddress.city,
      dropoff_state: selectedAddress.state,
      dropoff_country: selectedAddress.country,
      dropoff_pincode: selectedAddress.pincode,
      dropoff_lat: selectedAddress.latitude,
      dropoff_long: selectedAddress.longitude,
      assign_tour_status: 0,
      other_commodity_name: "",
      is_having_loading_charges: false,
      customer_mobile: bookingDetails.customer_mobile,
      vehicle_type: twoWheeler
        ? "2wheeler"
        : threeWheeler
          ? "3wheeler"
          : "4wheeler",
      payment_type: payType,
      fare: fareDetails?.total_fare,
      shop_id: shop_id ? shop_id : 0,
      save_address: saveAddress,
      consignment_image: file?.data,
      delivery_on: "sameday",
      bag_id: parseInt(bookingDetails.bagId),
      is_vivegam: vivegam,
      booking_type: bookingId === "delhi" ? "Home Delivery Delhi" : "Home Delivery"
    };
    console.log(bookingPayload, "payload")
    let reqObj = {
      customerMobile: bookingDetails.customer_mobile,
      logType: "decline the booking after rate popup",
      bookingData: { ...bookingPayload }
    }
    console.log(reqObj, "req")

    let response = await getCustomerLog(reqObj).then(responseJson => {
      console.log(responseJson, "res val")
    })
  }

  const fareCustomerLog = async (val) => {
    console.log(val, "val")
    const farePayload = {
      pickup_address: {
        lat: selectedPickupAddress.latitude,
        long: selectedPickupAddress.longitude,
      },
      drop_address: {
        lat: selectedAddress.latitude,
        long: selectedAddress.longitude,
      },
      vehicle_type: val,
      customer_mobile: bookingDetails.customer_mobile,
      is_vivegam: vegam ? false : true,
    };

    let farereqobj = {
      customerMobile: bookingDetails.customer_mobile,
      logType: "getting fare value",
      bookingData: { ...farePayload }
    }
    console.log(farereqobj, "fare obj")
    let response = await getCustomerLog(farereqobj).then(responseJson => {
      console.log(responseJson, "res val fare")
    })
  }

  const distanceCheck = async () => {
    console.log("enter distance check");
    const reqObj = {
      source_address: {
        lat: selectedPickupAddress?.latitude,
        long: selectedPickupAddress?.longitude,
      },
      destination_address: {
        lat: selectedAddress?.latitude,
        long: selectedAddress?.longitude,
      },
      city: "delhi",
      vehicle_type: threeWheeler ? "3wheeler" : pickup ? "4wheeler" : ""
    };
    console.log(reqObj, "distance object");
    try {
      const distanceCheckResult = await getBookingDistance(reqObj);
      console.log(distanceCheckResult.success, "distance check");
      if (distanceCheckResult.success) {
        console.log("allows it");
        setDisCheck(true);
      } else {
        console.log("don't allow");
        alert(distanceCheckResult.message);
        setSelectedAddress({
          address: "",
          address_type: "dropoff",
          city: "",
          countrgy: "",
          // customer_id: selectedCustomerId,
          latitude: 0,
          longitude: 0,
          // mobile: selectedCustomerPhone.slice(-10),
          pincode: "",
          receiver_contact: "",
          receiver_name: "",
          state: "",
          landmark: "",
        });
        setSelectedPickupAddress({
          address: "",
          address_type: "Pickup",
          city: "",
          country: "",
          // customer_id: selectedCustomerId,
          latitude: 0,
          longitude: 0,
          // mobile: selectedCustomerPhone.slice(-10),
          pincode: "",
          receiver_contact: "",
          receiver_name: "",
          state: "",
          landmark: "",
        });
        setThreeWheeler(false)
        setPickup(false)
        setVegam(false);
        setVivegam(false);
        console.log(selectedAddress, selectedPickupAddress, "address values")
      }
    } catch (error) {
      console.error("Error checking distance:", error);
    }

  }

  let findAddress = function (lat, lng) {
    console.log("first last");
    return Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country, pincode;
        for (let i = 0; i < response.results[0].address_components?.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types?.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                pincode = response.results[0].address_components[i].long_name;
                break;
              default:
                pincode = "";
            }
          }
        }
        console.log(city, state, country, pincode, "values");

        console.log("2");
        if (addressType === "pickup") {
          console.log("3");
          console.log("setting up pickup address data....");
          setSelectedPickupAddress({
            ...selectedPickupAddress,
            address,
            city,
            state,
            country,
            pincode,
            latitude: lat,
            longitude: lng,
          });
        } else {
          console.log("4");
          setSelectedAddress({
            ...selectedAddress,
            address,
            city,
            state,
            country,
            pincode,
            latitude: lat,
            longitude: lng,
          });

        }
        console.log(selectedPickupAddress, "fulll address")
      },
      (error) => {
        console.log("7");
        console.error(error);
      }
    );
  };



  // if(allowedPincodes.includes(selectedPickupAddress?.pincode) || allowedPincodes.includes(selectedAddress?.pincode)){
  //   // checkDistance();
  //   console.log("over all true");
  //   return;
  // }else if(selectedPickupAddress?.pincode !== "" && allowedPincodes.includes(selectedPickupAddress?.pincode)){
  //   console.log("fail always 1")
  //   alert("Apologies! Currently we do not serve this location.");
  //   // setSelectedPickupAddress({});
  //   return;
  // } else if(selectedAddress?.pincode !== "" && allowedPincodes.includes(selectedAddress?.pincode)){
  //   console.log("fail always 22222")
  //   alert("Apologies! Currently we do not serve this location.");
  //   // setSelectedAddress({});
  //   return;
  // }


  // if(selectedPickupAddress?.pincode !== "" && allowedPincodes.includes(selectedPickupAddress?.pincode)){
  //   if(!allowedPincodes.includes(selectedAddress?.pincode)){
  //     console.log("pass check")
  //   }else{
  //     console.log("alert help")
  //   }
  // } else if(selectedAddress?.pincode !== "" && allowedPincodes.includes(selectedAddress?.pincode)){
  //   if(!allowedPincodes.includes(selectedPickupAddress?.pincode)){
  //     console.log("pass checkkk 2222")
  //   }else{
  //     console.log("aleert  22222")
  //   }
  // }
  const validatePin = () => {
    console.log(allowedPincodes, "pins")
    console.log(selectedAddress?.pincode, "drop pin");
    console.log(selectedPickupAddress?.pincode, "pick pin")
    let alertShown = false; // Flag to track if alert has been shown
    console.log(alertShown, "alert val")
    console.log("checking pins")

    if (selectedAddress?.pincode != "" && selectedPickupAddress?.pincode != "") {
      // checkDistance();
      if (!allowedPincodes.includes(selectedPickupAddress?.pincode) && !allowedPincodes.includes(selectedAddress?.pincode)) {
        setSelectedAddress({
          address: "",
          address_type: "dropoff",
          city: "",
          countrgy: "",
          // customer_id: selectedCustomerId,
          latitude: 0,
          longitude: 0,
          // mobile: selectedCustomerPhone.slice(-10),
          pincode: "",
          receiver_contact: "",
          receiver_name: "",
          state: "",
          landmark: "",
        });
        alert("Apologies! Currently we do not serve this location.");
      }
      /*if (allowedPincodes.includes(selectedPickupAddress?.pincode)) {
        console.log("enter one");
        checkDistance()
        if (allowedPincodes.includes(selectedAddress?.pincode)) {
          console.log("clear no issue");
          checkDistance()
        } else if (!allowedPincodes.includes(selectedAddress?.pincode)) {
          console.log("no issue for this also");
        } else {
          // setSelectedAddress({});
          console.log("hit something");
        }
      } else if (allowedPincodes.includes(selectedAddress?.pincode)) {
        console.log("enter two");
        checkDistance()
        if (allowedPincodes.includes(selectedPickupAddress?.pincode)) {
          console.log("okay no issue 2222");
          checkDistance()
        } else if (!allowedPincodes.includes(selectedPickupAddress?.pincode)) {
          console.log("ookay no issue 28734653847568");
        } else {
          // setSelectedPickupAddress({});
          console.log("hits h23232423");
        }
      } else {
        console.log("alert here");
        if (!alertShown) { // Check if alert has already been shown
          alertShown = true; // Set flag to true after showing alert
          alert("Apologies! Currently we do not serve this location.");
        }
        return;
      }*/
    } else {
      console.log("in main else")
      console.log(selectedAddress)
      console.log(selectedPickupAddress)
      // setSelectedAddress({
      //   address: "",
      //   address_type: "dropoff",
      //   city: "",
      //   countrgy: "",
      //   // customer_id: selectedCustomerId,
      //   latitude: 0,
      //   longitude: 0,
      //   // mobile: selectedCustomerPhone.slice(-10),
      //   pincode: "",
      //   receiver_contact: "",
      //   receiver_name: "",
      //   state: "",
      //   landmark: "",
      // });
    }
  }





  const onMapLoad = (map) => {
    // console.log(map, "map")
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        // console.log(pos, "-----pos");
        setCoords({ ...coords, lat: pos.lat, lng: pos.lng });
      }
    );
  };

  const updatePlace = async () => {
    const addressObj = autocomplete.getPlaces()[0];
    let city = "", state = "", country = "", pincode = "";

    addressObj.address_components.forEach((comps) => {
      switch (comps.types[0]) {
        case "locality":
          city = comps.long_name;
          break;
        case "administrative_area_level_1":
          state = comps.long_name;
          break;
        case "country":
          country = comps.long_name;
          break;
        case "postal_code":
          pincode = comps.long_name;
          break;
        default:
          break;
      }
    });

    const address = addressObj.formatted_address ? (addressObj.name ? addressObj.name + ", " : "") + addressObj.formatted_address : "";

    if (addressType === "pickup") {
      setSelectedPickupAddress({
        ...selectedPickupAddress,
        address,
        city,
        state,
        country,
        pincode,
        latitude: addressObj.geometry.location.lat(),
        longitude: addressObj.geometry.location.lng(),
      });

    } else {
      setSelectedAddress({
        ...selectedAddress,
        address,
        city,
        state,
        country,
        pincode,
        latitude: addressObj.geometry.location.lat(),
        longitude: addressObj.geometry.location.lng(),
      });
      console.log("from choose ......")
    }

    setCoords({
      ...coords,
      lat: addressObj.geometry.location.lat(),
      lng: addressObj.geometry.location.lng(),
    });
  };





  const selectedFromList = async (data) => {
    if (addressType === "pickup") {

      if (!isDestinationVerified) {
        setSelectedPickupAddress({
          ...selectedPickupAddress,
          address: data.address,
          address_type: data.address_type,
          city: data.city,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          pincode: data.pincode,
          state: data.state,
          receiver_contact: data.receiver_contact,
          receiver_name: data.receiver_name,
        });
      } else {
        if (data.pincode && allowedPincodes.indexOf(data.pincode) >= 0) {
          setSelectedPickupAddress({
            ...selectedPickupAddress,
            address: data.address,
            address_type: data.address_type,
            city: data.city,
            country: data.country,
            latitude: data.latitude,
            longitude: data.longitude,
            pincode: data.pincode,
            state: data.state,
            receiver_contact: data.receiver_contact,
            receiver_name: data.receiver_name,
          });
          // setIsSourceVerified(true);
        } else {
          // setIsSourceVerified(false);
          alert("Apologies! Currently we do not serve this location.");
        }
        closeMapModal()
      }
    } else {
      // let reqObj = {
      //   source_address: {
      //     lat: selectedPickupAddress.latitude,
      //     long: selectedPickupAddress.longitude,
      //   },
      //   destination_address: {
      //     lat: data.latitude,
      //     long: data.longitude,
      //   },
      // };

      if (allowedPincodes.indexOf(selectedPickupAddress?.pincode) >= 0) {
        // console.log("------pincode1", data.pincode);
        setSelectedAddress({
          ...selectedAddress,
          address: data.address,
          address_type: data.address_type,
          city: data.city,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
          pincode: data.pincode,
          state: data.state,
          receiver_contact: data.receiver_contact,
          receiver_name: data.receiver_name,
        });
      } else {
        // console.log("------pincode2", data.pincode);
        if (data.pincode && allowedPincodes.indexOf(data.pincode) >= 0) {
          setSelectedAddress({
            ...selectedAddress,
            address: data.address,
            address_type: data.address_type,
            city: data.city,
            country: data.country,
            latitude: data.latitude,
            longitude: data.longitude,
            pincode: data.pincode,
            state: data.state,
            receiver_contact: data.receiver_contact,
            receiver_name: data.receiver_name,
          });
          setIsDestinationVerified(true);
        } else {
          setIsDestinationVerified(false);
          alert("Apologies! Currently we do not serve this location.");
        }
      }
    }
    setShowAddressListPage(false);
    closeMapModal()
  };
  const updateOnload = (plc) => {
    console.log(plc);
    setAutocomplete(plc);
  };
  // var cityBounds = new google.maps.LatLngBounds(
  //   new google.maps.LatLng(12.288681, 79.997589),
  //   new google.maps.LatLng(9.939093, 78.121719)
  // );

  const returnHome = () => {
    setShowSuccessPopup(false);
    window.location.reload();
  };

  const validate = () => {
    let valid = true;
    if (bookingDetails.customer_mobile !== "") {
      setMobileCheck(false);
      console.log("1")
    } else {
      setMobileCheck(true);
      valid = false;
      console.log("2")

    }
    if (selectedPickupAddress.address === "") {
      setIsAddress(true);
      valid = false;
      console.log("3")

    } else {
      setIsAddress(false);
      console.log("4")

    }
    if (selectedAddress.address === "") {
      setIsAddressDrop(true);
      valid = false;
      console.log("5")

    } else {
      setIsAddressDrop(false);
      console.log("6")

    }
    if (!checked) {
      setTermsError(true);
      valid = false;
      console.log("7")

    } else {
      setTermsError(false);
      console.log("8")

    }
    // if (file === null) {
    //   setIsFileUploaded(true);
    //   valid = false;
    //   console.log("9")
    // } else {
    //   setIsFileUploaded(false);
    //   console.log("10")

    // }
    if (payType === "") {
      setIsPayTypeChoosed(true);
      valid = false;
      console.log("11")


    } else {
      setIsPayTypeChoosed(false);
      console.log("12")

    }
    if (vegam) {
      setIsBookingTypeSelected(false);
      console.log("13");

    }
    else if (vivegam) {
      setIsBookingTypeSelected(false);
      console.log("14");
    } else {
      setIsBookingTypeSelected(true);
      console.log("15");
      valid = false;
    }
    if (!pickup && !threeWheeler && !twoWheeler) {
      setIsVehicleChoosed(true);
      valid = false;
      console.log("16")
    } else if (pickup || !threeWheeler || !twoWheeler) {
      setIsVehicleChoosed(false);
      console.log("17")
    } else if (!pickup || threeWheeler || !twoWheeler) {
      setIsVehicleChoosed(false);
      console.log("18")
    } else if (pickup || threeWheeler || twoWheeler) {
      setIsVehicleChoosed(false);
      console.log("19")
    }

    return valid;
  }

  const showBooked = async () => {
    const valid = validate();
    console.log(valid, valConfirm, "vall")

    if (valid && valConfirm) {
      console.log("yes pass");

      let fromTime;
      let toFime;
      if (vegam) {
        console.log("coming inside 4-------");
        fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
        toFime = moment(fromTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
      } else if (vivegam) {
        console.log("coming inside 5-------");
        fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
        toFime = moment(fromTime).add(6, "hour").format("YYYY-MM-DD HH:mm:ss");
      }

      const bookingPayload = {
        customer_id: 0,
        pickup_location:
          selectedPickupAddress.address + " " + selectedPickupAddress.landmark,
        pickup_time: "",
        pickuptime_flexiblity_status: "Fixed",
        pickup_flexible_time: "",
        dropoff_location:
          selectedAddress.address + " " + selectedAddress.landmark,
        dropoff_time: fromTime,
        dropofftime_flexiblity_status: "Fixed",
        dropoff_flexible_time: toFime,
        commodity_type: 1,
        commodity_weight: 0,
        weight_unit: 12,
        commodity_whole_volume: 1,
        volume_unit: 35,
        commodity_length: "",
        length_unit: 37,
        commodity_breadth: "",
        breadth_unit: 37,
        commodity_height: "",
        height_unit: 37,
        packaging_type: 1,
        receiver_name: "Customer",
        receiver_contact: bookingDetails.customer_mobile,
        instruction: "Test",
        pickup_lat: selectedPickupAddress.latitude,
        pickup_long: selectedPickupAddress.longitude,
        pickup_city: selectedPickupAddress.city,
        pickup_state: selectedPickupAddress.state,
        pickup_country: selectedPickupAddress.country,
        pickup_pincode: selectedPickupAddress.pincode,
        dropoff_city: selectedAddress.city,
        dropoff_state: selectedAddress.state,
        dropoff_country: selectedAddress.country,
        dropoff_pincode: selectedAddress.pincode,
        dropoff_lat: selectedAddress.latitude,
        dropoff_long: selectedAddress.longitude,
        assign_tour_status: 0,
        other_commodity_name: "",
        is_having_loading_charges: false,
        customer_mobile: bookingDetails.customer_mobile,
        vehicle_type: twoWheeler
          ? "2wheeler"
          : threeWheeler
            ? "3wheeler"
            : "4wheeler",
        payment_type: payType,
        fare: fareDetails?.total_fare,
        shop_id: shop_id ? shop_id : 0,
        save_address: saveAddress,
        consignment_image: file?.data,
        delivery_on: "sameday",
        bag_id: parseInt(bookingDetails.bagId),
        is_vivegam: vivegam,
        booking_type: bookingId === "delhi" ? "Home Delivery Delhi" : "Home Delivery"
      };

      if (
        (payType === "COD" || payType === "PS") &&
        payType !== "" &&
        fromTime < toFime
      ) {
        console.log("coming inside 7-------");
        // if (isEnableButton) {
        //   setFareDetails({});
        //   setTwoWheeler(false);
        //   setThreeWheeler(false);
        //   setPickup(false);
        //   alert("Please accept the rate before you confirm the booking!");
        //   console.log("coming inside 8-------");
        //   return;
        // }
        setShowLoader(true);
        const result = await addBooking(bookingPayload);
        // console.log(result, "----result for booking");
        if (result.success === true) {
          console.log("coming inside 9-------");

          setShowLoader(false);
          setStoreBookingResponse(result.data.bookingDetails);

          setShowSuccessPopup(true);
          setAddedBookingResult(result.data.bookingDetails);
          localStorage.setItem("added_booking", JSON.stringify(result.data));
        } else {
          console.log("coming inside 10-------");

          setShowLoader(false);
          setShowSuccessPopup(true);
        }
      } else if (
        payType === "Online payment" &&
        fromTime < toFime
      ) {
        console.log("coming inside 11-------");

        // if (isEnableButton) {
        //   setFareDetails({});
        //   setTwoWheeler(false);
        //   setThreeWheeler(false);
        //   setPickup(false);
        //   console.log("coming inside 12-------");

        //   alert("Please accept the rate before you confirm the booking!");
        //   return;
        // }
        setShowLoader(true);
        const result = await addBooking(bookingPayload);

        setStoreBookingResponse(result.data.bookingDetails);
        setGoToPayment(true)
        if (result.data.bookingDetails.total_fare > 0) {
          localStorage.setItem("goToPayment", "true");
          localStorage.setItem("added_booking", JSON.stringify(result.data));
          // console.log("booking result");
          // console.log(result);
          setEncRequest(result.data.encoded_value);
          setAccessCode(result.data.accessCode);
          setTimeout(() => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            ccForm.current.submit();
            setShowLoader(false);
          }, 2 * 1000);
        } else {
          setShowSuccessPopup(true);
          setAddedBookingResult(result.data.bookingDetails);
          localStorage.setItem("added_booking", JSON.stringify(result.data));
        }

      }

    }
    else if (valid && !valConfirm) {
      console.log("enter enable button")
      setFareDetails({});
      setTwoWheeler(false);
      setThreeWheeler(false);
      setPickup(false);
      alert("Please accept the rate before you confirm the booking!");
      return;
    }
  }


  const navigateToHome = () => {
    setShowMap(false);
    closeMapModal();
    setShowAddressListPage(false);
  };

  const handleFileChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileName", e.target.files[0].name);
    // console.log(formData, "-----formData");
    try {
      const res = await axios.post(
        baseUrl + "/booking/uploadreceipt",
        formData
      );
      console.log(res);
      setFile(res.data);
    } catch (ex) {
      console.log(ex);
    }
    // console.log(e.target.files[0].name, "----e.target.files");
  };

  const handleChange = (event) => {
    setPaytype(event.target.value);
    // console.log(payType, "---payType");
  };

  const onDragEnd = (e) => {
    let val = dragCount + 1;

    console.log(val, "count");

    if (dragCount < maxDragCount) {
      console.log("drag");
      const newCoords = {
        lat: e.latLng?.lat(),
        lng: e.latLng?.lng(),
      };

      setCoords(newCoords);
      setDragCount(val);
      console.log("calling on drag end")
      findAddress(newCoords.lat, newCoords.lng);

      // setCoords({
      //   ...coords,
      //   lat: e.latLng?.lat(),
      //   lng: e.latLng?.lng(),
      // });
      // findAddress(e.latLng?.lat(), e.latLng?.lng());
    } else {
      console.log("not drag")
    }
  };

  const handleMobileChange = async (e) => {
    const enteredMobile = e.target.value;

    // Check if the entered value has 10 digits
    if (enteredMobile?.length === 10) {
      // Open the modal
      console.log(enteredMobile, "mobile")
      setIsModalOpen(true);
      let reqData = {
        customerMobile: enteredMobile,
        logType: "mobile entered",
        bookingData: {}
      }
      console.log(reqData, "req")
      let response = await getCustomerLog(reqData).then(responseJson => {
        console.log(responseJson)
      })
    }

    setBookingDetails({
      ...bookingDetails,
      customer_mobile: enteredMobile,
      fare: '',
      bagId: 0,
    });
  };

  const modalClose = () => {
    setIsModalOpen(false);
  }

  const sendOTP = async () => {
    setButtonDisable(true);
    console.log("mobile", bookingDetails.customer_mobile)
    const result = await sendOtp(bookingDetails.customer_mobile);
    console.log(result, "----result");
  };

  const verifyUser = async (e) => {
    console.log(e, "num")
    console.log(bookingDetails.customer_mobile, "val number")
    const otp = otpInput1 + "" + otpInput2 + "" + otpInput3 + "" + otpInput4;
    console.log(otp, "-----otp");
    const result = await verifyOtp(bookingDetails.customer_mobile, otp);
    if (result.type === "success") {
      console.log("OTP checked")
      setIsModalOpen(false);
      setCheckOtp(false);
      if (bookingId === "delhi") {
        (async () => {
          try {
            const checkNum = await verifyNumber(bookingDetails.customer_mobile);
            console.log(checkNum, "check");
            if (checkNum.data.isFreeRide) {
              console.log("true ride");
              setRideCheck(true);
            } else {
              console.log("no true ride");
            }
          } catch (error) {
            console.error("Error:", error);
          }
        })();
      }


    } else {
      errorMessage();
      console.log("wrong OTP");
      setIsModalOpen(true);
      setCheckOtp(true);
    }
    console.log(result, "----result otp");
  };


  const handleCheckChange = async (e, type) => {
    if (!selectedAddress.address || !selectedPickupAddress.address) {
      notify();
      console.log("coming inside address------");
      // setIsAddressNotSelected(true);
      setTwoWheeler(false);
      setThreeWheeler(false);
      setPickup(false);
      // setIsEnableButton(false);

    }
    if (type === "vivegam") {
      // setIsBookingTypeSelected(true);
      setVegam(false);
      setVivegam(true);
    } else if (type === "vegam") {
      // setIsBookingTypeSelected(true);
      setVegam(true);
      setVivegam(false);
    } else if (type === "twoWheeler") {
      setFareDetails({});
      setTwoWheeler(true);
      if (vegam || vivegam) {
        setShowRatePopup(true);
      } else if (vegam) {
        setShowRatePopup(true);

      } else if (vivegam) {
        setShowRatePopup(true);

      } else {
        alert("Please choose the delivery speed")

      }
      setThreeWheeler(false);
      setPickup(false);
      const vehicleType = "2wheeler";
      const farePayload = {
        pickup_address: {
          lat: selectedPickupAddress.latitude,
          long: selectedPickupAddress.longitude,
        },
        drop_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        vehicle_type: vehicleType,
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      // console.log(farePayload, "-----farePayload");
      if (bookingId === "delhi") {
        const result = await getDelhiHomeDeliveryFareByAddresses(farePayload);
        console.log(result, "result val 12")

        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);
        } else {
          setShowUnavailableMessage(true);
          setIsValidForFareCal(false);
        }
      } else {
        const result = await getFareByAddresses(farePayload);
        console.log(result, "result val 2323")
        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);

        } else {
          setShowUnavailableMessage(true);
          setIsValidForFareCal(false);
        }
      }

      // console.log(result, "----result");
      // setFareDetails(result.data);

    } else if (type === "threeWheeler") {
      setFareDetails({});
      setTwoWheeler(false);
      setThreeWheeler(true);
      setPickup(false);
      if (vegam || vivegam) {
        setShowRatePopup(true);
      } else if (vegam) {
        setShowRatePopup(true);
      } else if (vivegam) {
        setShowRatePopup(true);
      } else {
        alert("Please choose the delivery speed");
      }
      const vehicleType = "3wheeler";
      const farePayload = {
        pickup_address: {
          lat: selectedPickupAddress.latitude,
          long: selectedPickupAddress.longitude,
        },
        drop_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        vehicle_type: vehicleType,
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      // console.log(farePayload, "-----farePayload");
      if (bookingId === "delhi") {
        const result = await getDelhiHomeDeliveryFareByAddresses(farePayload);
        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);

        } else {
          setShowUnavailableMessage(true);
          setIsValidForFareCal(false);
        }
      } else {
        const result = await getFareByAddresses(farePayload);
        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);

        } else {
          setShowUnavailableMessage(true);
          setIsValidForFareCal(false);
        }
      }
      // console.log(result, "----result");
      // setFareDetails(result.data);

    } else if (type === "pickup") {
      setFareDetails({});
      setTwoWheeler(false);
      setThreeWheeler(false);
      setPickup(true);
      if (vegam || vivegam) {
        setShowRatePopup(true);
      } else if (vegam) {
        setShowRatePopup(true);
      } else if (vivegam) {
        setShowRatePopup(true);
      } else {
        alert("Please choose the delivery speed")
      }
      const vehicleType = "4wheeler";
      const farePayload = {
        pickup_address: {
          lat: selectedPickupAddress.latitude,
          long: selectedPickupAddress.longitude,
        },
        drop_address: {
          lat: selectedAddress.latitude,
          long: selectedAddress.longitude,
        },
        vehicle_type: vehicleType,
        customer_mobile: bookingDetails.customer_mobile,
        is_vivegam: vegam ? false : true,
      };
      // console.log(farePayload, "-----farePayload");
      setShowUnavailableMessage(false);

      if (bookingId === "delhi") {
        const result = await getDelhiHomeDeliveryFareByAddresses(farePayload);
        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);

        } else {
          alert("something went wrong, please try again");

          // setIsValidForFareCal(false);
        }
      } else {
        const result = await getFareByAddresses(farePayload);
        if (result.success === true) {
          setIsValidForFareCal(true);
          setFareDetails(result.data);
          fareCustomerLog(vehicleType);

        } else {
          alert("something went wrong, please try again");

          // setIsValidForFareCal(false);
        }
      }

      // if (result.success === true) {
      //   setIsValidForFareCal(true);
      //   setFareDetails(result.data);
      // } else {
      //   alert("something went wrong, please try again");

      //   // setIsValidForFareCal(false);
      // }
    }
  };

  // console.log(selectedAddress.address_type, "---samenext");
  // const getCCSession = async () => {
  //   let sessionData = await getCCSessionData();
  //   console.log("sessionData");
  //   console.log(sessionData);
  //   if (sessionData.success) {
  //     setEncRequest(sessionData.data.encoded_value);
  //     setAccessCode(sessionData.data.accessCode);
  //     setTimeout(() => {
  //       ccForm.current.submit();
  //     }, 10 * 1000);
  //   }
  // };
  // console.log(isEnableButton,
  //   !isAgreedTermsandCond, "valll")
  // console.log(showSuccessPopup, isVehicleChoosed, bookingDetails, "mob")
  // console.log(isBookingTypeSelected, isAgreedTermsandCond, mobileCheck, isAddress, isAddressDrop, isPayTypeChoosed, isVehicleChoosed, isFileUploaded,termsError, "daadad")
  return (
    <>
      {!shopDetails.id && showAppLoader ? (
        <div
          className="pricing-policy-container"
          style={{ height: 100, marginLeft: 50 }}
        >
          <div className="title-style" style={{ marginTop: 25, color: "red" }}>
            Shop not found
          </div>
          <div className="subtitle-style" style={{ marginTop: 10 }}>
            Please use valid shop url!
          </div>
        </div>
      ) : (
        <div >
          {/* <div className="App-inner-container-for-book-page"></div> */}
          <div className="App-semi-container-for-book-page">
            <div className="form-container-for-book-page">
              <div className="my-order-button">
                <a
                  href={bookingId === "delhi" ? "/otpVerification/delhi" : "/otpVerification"}
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  My Orders
                </a>
              </div>
              <div className="title-style" style={{ marginTop: 30 }}>
                Book Here
              </div>
              <div className="form-contn-for-book-page">
                <div
                  // onSubmit={handleSubmit((data) => console.log(data, "-----dataaaa"))}
                  className="form-display"
                >
                  <div className="web-view-style-one">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginLeft: -55,
                      }}
                    >
                      <input
                        className="form-field-style-input"
                        {...register("mobile", { required: true })}
                        placeholder="Enter your mobile number"
                        type="number"
                        maxLength={10}
                        onChange={handleMobileChange}
                      />

                      {/* // if (!/^[6-9]\d{9}$/.test(enteredMobile)) { 
                      //   setMobileCheck(true);
                      // }

                      // setVegam(false);
                      // setVivegam(false);
                      // setFareDetails({});
                      // setTwoWheeler(false);
                      // setThreeWheeler(false);
                      // setPickup(false);
                      // console.log(
                      //   "badid===========",
                      //   // customer_mobile,
                      //   bookingDetails.bagId,
                      //   vegam,
                      //   vivegam,
                      //   file
                      // );
*/}

                      {/* {console.log(bookingDetails.customer_mobile.length, setBookingDetails, "num")} */}
                      {mobileCheck && (
                        <p className="error-font-style-mobile">
                          Mobile number is required
                        </p>)

                      }{!mobileCheck && bookingDetails.customer_mobile?.replace(/^[6-9]\d{9}$/, "") &&
                        (
                          <p className="error-font-style-mobile">
                            Please enter valid mobile number
                          </p>
                        )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginLeft: -55,
                      }}
                    >
                      <input
                        className="form-field-style-input"
                        // {...register("pickupaddress", { required: true })}
                        placeholder="Choose the pickup location"
                        type="text"
                        onClick={() => {
                          openMapModal();
                          navigateToMap("pickup");
                          setVegam(false);
                          setVivegam(false);
                          setFareDetails({});
                          setTwoWheeler(false);
                          setThreeWheeler(false);
                          setPickup(false);
                          setShowMap(false);
                        }}
                        value={selectedPickupAddress.address?.replace(/^[^,]+,/, '').trim()}
                        onChange={() =>
                          console.log(selectedAddress, "----seleadd")
                        }
                        // disabled={bookingDetails.customer_mobile.length !== 10 && bookingDetails.customer_mobile.replace(/^[6-9]\d{9}$/, "")}
                        disabled={checkOtp || !/^[6-9]\d{9}$/.test(bookingDetails.customer_mobile)}

                      />

                      {isAddress && (
                        <p className="error-font-style-location">
                          Pickup address is required
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // marginLeft: -55,
                      }}
                    >
                      <input
                        className="form-field-style-input"
                        // {...register("dropaddress", { required: true })}
                        placeholder="Choose the delivery location"
                        type="text"
                        onClick={() => {
                          openMapModal();
                          navigateToMap("dropoff");
                          setVegam(false);
                          setVivegam(false);
                          setFareDetails({});
                          setTwoWheeler(false);
                          setThreeWheeler(false);
                          setPickup(false);
                          setShowMap(false)
                        }}
                        value={selectedAddress.address?.replace(/^[^,]+,/, '').trim()}
                        onChange={() =>
                          console.log(selectedAddress, "seleadd")
                        }
                        disabled={checkOtp || !/^[6-9]\d{9}$/.test(bookingDetails.customer_mobile)}

                      />

                      {isAddressDrop && (
                        <p className="error-font-style-location">
                          Dropoff address is required
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    className="subtitle-style-heading"
                    style={{ marginBottom: -9, marginTop: 20 }}
                  >
                    Delivery speed
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 13,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: -4,
                      }}
                    >
                      <input
                        className={`day-field-align-same ${bookingId === "delhi" ? "delhi" : ""}`}
                        // {...register("vegam", { required: true })}
                        type="radio"
                        checked={vegam}
                        onChange={(e) => {
                          handleCheckChange(e, "vegam");
                          setFareDetails({});
                          setTwoWheeler(false);
                          setThreeWheeler(false);
                          setPickup(false);
                        }}
                      />
                      <label className={`day-field-label-same ${bookingId === "delhi" ? "delhi" : ""}`}>{bookingId === "delhi" ? "Tez" : "Vegam"}</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: -4,
                      }}
                    >
                      <input
                        className="day-field-align-next"
                        // {...register("nextDay", { required: true })}
                        type="radio"
                        checked={vivegam}
                        onChange={(e) => {
                          handleCheckChange(e, "vivegam");
                          setFareDetails({});
                          setTwoWheeler(false);
                          setThreeWheeler(false);
                          setPickup(false);
                        }}
                      />
                      <label
                        className="day-field-label-next"
                      >
                        {bookingId === "delhi" ? "Bachat" : "Vivegam"}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // gap: "20px"
                    }}
                  >
                    <div
                      className="delivery-immediate"
                    >
                      (immediate)
                    </div>
                    <div
                      className="delivery-6-hours"
                    >
                      (within 6 hours)
                    </div>
                  </div>
                  <div className="web-line-style">
                    {isBookingTypeSelected && (
                      <p
                        className="error-font-style"
                        style={{ marginTop: 6, marginBottom: -10, marginLeft: 0 }}
                      >
                        Please choose booking type
                      </p>
                    )}{" "}
                  </div>
                  {/* <div
                    className="web-view-style-three"
                    style={{ marginLeft: 1 }}
                  >
                    <div className="web-view-style-challan">
                      <label className="subtitle-style">
                        Upload Order Challan
                      </label>
                      <input
                        style={{
                          color: "#404040",
                          fontSize: 12,
                          marginLeft: 70,
                          marginTop: 10,
                        }}
                        type="file"
                        accept=".jpeg, .png, .jpg"
                        onChange={handleFileChange}
                      />
                      {isFileUploaded && (
                        <div className="challan-error-style">
                          {" "}
                          Please upload challan
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className="web-view-style-two">
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                      }}
                    >
                      <label className="subtitle-style-bag">Bag Id</label>
                      <input
                        className="form-field-style"
                        style={{ width: 100, marginRight: 70 }}
                        {...register("bagid", { required: true })}
                        placeholder="Enter Bag Id"
                        type="number"
                        maxLength={10}
                        onChange={(e) => {
                          setBookingDetails({
                            ...bookingDetails,
                            bagId: e.target.value,
                          });
                        }}
                      />
                      {/* {isValidBagId && (
                        <div className="bagid-error-style">
                          <p className="error-font-style-bagId">
                            {" "}
                            Please enter bag id
                          </p>
                        </div>
                      )} 
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 20,
                      }}
                    >
                      <label
                        className="subtitle-style-heading"
                        style={{ marginBottom: 14 }}
                      >
                        My goods will fit in
                      </label>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          // marginLeft: -12,
                        }}
                      >
                        {bookingId === 'delhi' ? "" :
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: 1,
                              marginLeft: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: -11,
                              }}
                            >
                              <input
                                className="form-field-style-vehicle-type-2-whl"
                                // style={{ marginLeft: -55 }}
                                {...register("twowheeler", { required: true })}
                                type="radio"
                                checked={twoWheeler}
                                onChange={(e) =>
                                  handleCheckChange(e, "twoWheeler")
                                }
                              />
                              <img
                                className="two-wheeler-img-style"
                                src={TwoWheelerIcon}
                                alt="zeuez logo"
                              />
                            </div>
                            <label
                              className="subtitle-style"
                              style={{ color: "#3e4d62" }}
                            >
                              <div className="web-view-style-2-wheeler">
                                2 Wheeler
                                {/* <BsInfoCircleFill /> */}

                              </div>

                            </label>
                          </div>
                        }

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 1,
                            marginLeft: 7,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: -10,
                            }}
                          >
                            <input
                              className="form-field-style-vehicle-type-3-whl"
                              {...register("nextDay", { required: true })}
                              type="radio"
                              checked={threeWheeler}
                              onChange={(e) =>
                                handleCheckChange(e, "threeWheeler")
                              }
                            />
                            <img
                              className="three-wheeler-img-style"
                              src={ThreeWheelerIcon}
                              alt="zeuez logo"
                            />
                          </div>
                        </div>
                        <label
                          className="subtitle-style"
                          style={{ color: "#3e4d62" }}
                        >
                          <div className="web-view-style-3-wheeler">
                            3 Wheeler
                            <BsInfoCircleFill onClick={() => setwInfoIsModalOpen(true)} style={{ cursor: 'pointer' }} />
                          </div>
                        </label>
                        {wInfoModalOpen && (
                          <>
                            <div className='modal-backdrop' onClick={() => setwInfoIsModalOpen(false)}></div>
                            <div className='modal'>
                              <p>
                                <strong>

                                  Box size
                                </strong>
                                - 135 cu.ft
                                <br />
                                <br />
                                <strong>

                                  Weight capacity
                                </strong>
                                - 500 kg
                              </p>

                              <button onClick={() => setwInfoIsModalOpen(false)}>Close</button>
                            </div>
                          </>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 1,
                            // marginLeft: 5,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: -10,
                            }}
                          >
                            <input
                              className="form-field-style-vehicle-type-4-whl"
                              {...register("nextDay", { required: true })}
                              type="radio"
                              checked={pickup}
                              onChange={(e) => handleCheckChange(e, "pickup")}
                            />
                            <img
                              className="four-wheeler-img-style"
                              src={FourWheelerIcon}
                              alt="zeuez logo"
                            />
                          </div>
                          <label
                            className="subtitle-style"
                            style={{ color: "#3e4d62" }}
                          >
                            <div className="web-view-style-pickup">
                              {bookingId === "delhi" ? "Ace EV" : "Pick up"}
                              {/* Pick up */}
                              <BsInfoCircleFill onClick={() => setInfoIsModalOpen(true)} style={{ cursor: 'pointer' }} />
                            </div>
                          </label>
                          {isInfoModalOpen && (
                            <>
                              <div className='modal-backdrop' onClick={() => setInfoIsModalOpen(false)}></div>
                              <div className='modal'>
                                <p>
                                  <strong>

                                    Box size
                                  </strong>
                                  - 210 cu.ft
                                  <br />
                                  <br />
                                  <strong>

                                    Weight capacity
                                  </strong>
                                  - 750 kg
                                </p>

                                <button onClick={() => setInfoIsModalOpen(false)}>Close</button>
                              </div>
                            </>
                          )}
                        </div>
                        {/* {(errors.nextDay === false ||
                          errors.sameDay === false) && (
                          <p className="error-font-style">
                            Please choose type.
                          </p>
                        )}{" "} */}
                      </div>
                      {isVehicleChoosed && (
                        <div
                          className="vehicle-error-style"
                          style={{ marginTop: 5 }}
                        >
                          Please choose vehicle type
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="payment-type-alignment">
                    <div className="subtitle-style-heading">
                      <div className="payment-label-style">I will pay</div>
                    </div>
                    <select
                      onChange={handleChange}
                      value={payType}
                      className="form-field-style"
                    >
                      <option value="">Choose payment method</option>
                      <option value="COD">Cash on Delivery</option>
                      <option value="Online payment">Pay Online</option>
                      {bookingId === "delhi" ? "" : <option value="PS">Pay at Shop</option>}
                    </select>
                  </div>
                  {isPayTypeChoosed && (
                    <div className="payment-error-style">
                      Please choose payment type
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: -10,
                      padding: 15,
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleChangeterms()}
                        className="form-field-style-map"
                        style={{ marginLeft: 15, width: 17, height: 16 }}
                      // onClick={() => {
                      //   setIsAgreedTermsandCond(true);
                      //   setTermsError(false);
                      // }}
                      />
                      <label
                        className="subtitle-style-map"
                        onClick={() => {
                          termsModOpen()
                        }}
                      >
                        I've read and agree to the{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "#0000FF",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          Terms & Conditions
                        </span>
                      </label>
                    </div>
                    <div>
                      {termsError && (
                        <div className="terms-error-style">
                          Please accept the terms and conditions
                        </div>
                      )}
                    </div>

                  </div>
                  <div style={{ position: "relative" }}>
                    <button
                      className="button-style-main"
                      //  disabled={!isEnableButton}
                      name="Book"
                      onClick={() => showBooked()}
                    >
                      Submit
                    </button>
                    {/* <div
                      disabled={
                        isEnableButton ||
                        !isAgreedTermsandCond
                      }
                      className="button-style-main"
                      name="Book"
                      onClick={() => {
                        showBookedPopup();
                      }}
                    >
                      Submit
                    </div> */}
                    {/* <input
                      disabled={isEnableButton || !isAgreedTermsandCond}
                        className={
                  isEnableButton || !isAgreedTermsandCond
                    ? "button-style-disable"
                    : "button-style"
                }
                // type="submit"
                name="Book"
                onClick={showBookedPopup}
                    /> */}
                  </div>
                  {bookingId === "delhi"  && <div className="enquiry-text">
                    **<span className="redText">For booking enquiries, contact +919717722929</span>**
                  </div>}
                </div>
              </div>
              <form
                ref={ccForm}
                id="nonseamless"
                method="post"
                name="redirect"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  type="hidden"
                  id="encRequest"
                  name="encRequest"
                  value={encRequest}
                />
                <input
                  type="hidden"
                  name="access_code"
                  id="access_code"
                  value={accessCode}
                />
              </form>
            </div>
          </div>

          {disCheck && showRatePopup && selectedAddress.address ? (
            <>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
                style={{
                  // position: "relative"
                  // position:"absolute"
                  // backgroundColor: "#ffffff",
                  // marginTop: 130,
                  // marginLeft: 60,
                }}
              >
                {isValidForFareCal ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        className="pop-up-title-style"
                        style={{ marginBottom: 12, marginTop: 39 }}
                      >
                        Your Price !
                      </div>
                      {threeWheeler ? (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Three Wheeler
                        </div>
                      ) : twoWheeler ? (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Two Wheeler
                        </div>
                      ) : (
                        <div
                          className="pop-up-content-style"
                          style={{ marginTop: -2 }}
                        >
                          For Four Wheeler
                        </div>
                      )}
                      <div className="pop-up-content-style" style={{ marginBottom: 20 }}>
                        Toll charges will be added as extra<span style={{ color: "red", fontSize: 15 }}>*</span>
                      </div>
                      {bookingId === "delhi" ? (fareDetails.total_fare == 0 || fareDetails.total_fare !== 0) ? (
                        <div
                          className="estimate-rate-style"
                          style={{ marginTop: 127 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: -12,
                            }}
                          >
                            <div>
                              <img
                                style={{
                                  width: 18,
                                  height: 18,
                                  marginTop: 8,
                                  marginBottom: -20,
                                  padding: 8,
                                }}
                                src={IndianCurrencyIcon}
                                alt="zeuez logo"
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 22,

                                marginLeft: -8,
                              }}
                            >
                              {fareDetails?.total_fare}{" "}<span style={{ color: "white", fontSize: 15 }}>*</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            marginBottom: -80,
                            marginLeft: 100,
                            marginTop: 20,
                          }}
                        >
                          <ReactLoading
                            type="balls"
                            color="#30A46C"
                            height={"45%"}
                            width={"45%"}
                          />
                          <div
                            className="pop-up-content-style"
                            style={{ marginTop: -25, marginLeft: -90 }}
                          >
                            Please wait...
                          </div>
                        </div>
                      ) :
                        fareDetails.total_fare ? (
                          <div
                            className="estimate-rate-style"
                            style={{ marginTop: 127 }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: -12,
                              }}
                            >
                              <div>
                                <img
                                  style={{
                                    width: 18,
                                    height: 18,
                                    marginTop: 8,
                                    marginBottom: -20,
                                    padding: 8,
                                  }}
                                  src={IndianCurrencyIcon}
                                  alt="zeuez logo"
                                />
                              </div>
                              <div
                                style={{
                                  marginTop: 22,

                                  marginLeft: -8,
                                }}
                              >
                                {fareDetails?.total_fare}{" "}<span style={{ color: "white", fontSize: 15 }}>*</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginBottom: -80,
                              marginLeft: 100,
                              marginTop: 20,
                            }}
                          >
                            <ReactLoading
                              type="balls"
                              color="#30A46C"
                              height={"45%"}
                              width={"45%"}
                            />
                            <div
                              className="pop-up-content-style"
                              style={{ marginTop: -25, marginLeft: -90 }}
                            >
                              Please wait...
                            </div>
                          </div>
                        )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 100,
                        marginLeft: 17,
                      }}
                    >
                      <div
                        className="button-style"
                        style={{
                          width: 120,
                          height: 25,
                          paddingTop: 8,
                          marginRight: 7,
                        }}
                        onClick={() => {
                          setConfirmedFare(fareDetails.total_fare);
                          console.log(confirmedFare, "--confirmfare");
                          setShowRatePopup(false);
                          setIsEnableButton(false);
                          setValConfirm(true);
                        }}
                      >
                        Confirm
                      </div>{" "}
                      <div
                        className="button-style"
                        style={{
                          width: 120,
                          height: 25,
                          paddingTop: 8,
                          backgroundColor: "#c40000",
                          boxShadow: "3px 3px #ff7676",
                        }}
                        onClick={() => {
                          setShowRatePopup(false);
                          setIsEnableButton(true);
                          setValConfirm(false);
                          declineCustomerLog();
                        }}
                      >
                        Decline
                      </div>
                    </div>
                  </div>
                ) : showUnavailableMessage ? (
                  <>
                    <div
                      style={{
                        marginLeft: 225,
                        marginTop: 20,
                        fontSize: 20,
                        width: 30,
                        height: 30,
                        borderRadius: 30,
                        backgroundColor: "#30A46C",
                        paddingTop: 3,
                      }}
                      onClick={() => setShowRatePopup(false)}
                    >
                      X
                    </div>
                    <img
                      style={{
                        width: 150,
                        height: 160,
                        marginTop: -22,
                        padding: 8,
                      }}
                      src={Comingsoon}
                      alt="zeuez logo"
                    />
                    <div
                      className="pop-up-content-style"
                      style={{
                        fontSize: 15,
                        padding: 12,
                        marginTop: -10,
                      }}
                    >
                      Thanks for choosing us. We are growing and will soon serve
                      the location you selected!
                    </div>
                  </>
                ) : null}
              </m.div>
            </>
          ) : (
            <div>
              <ToastContainer
                toastStyle={{
                  backgroundColor: "#FF4C4C",
                  color: "#ffffff",
                  fontSize: 13,
                }}
              />
            </div>
          )}
          {/* {isAddressNotSelected ? (
        <Popup trigger={<button> Trigger</button>} position="right center">
          <div>Popup content here !!</div>
        </Popup>
      ) : null} */}
          {showSuccessPopup ? (
            <>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
              >
                <div>
                  <img
                    style={{
                      width: 40,
                      height: 40,
                      marginTop: 20,
                      marginBottom: -20,
                      backgroundColor: "#30A46C",
                      borderRadius: 28,
                      padding: 8,
                    }}
                    src={TickIcon}
                    alt="zeuez logo"
                  />
                </div>
                <div
                  className="pop-up-title-style"
                  style={{ marginBottom: 20 }}
                >
                  Great!
                </div>
                <div
                  className="pop-up-content-style"
                  style={{ marginBottom: 4 }}
                >
                  Your booking id{" "}
                  <span className="text-highlighter">
                    #{addedBookingResult.id}
                  </span>{" "}
                  has been confirmed!
                </div>
                <div className="pop-up-content-style" style={{ marginTop: 10 }}>
                  You can track your shipment here.
                </div>
                <a
                  href={storeBookingResponse.booking_url}
                  className="pop-up-content-style"
                  style={{
                    textDecoration: "underline",
                    color: "#0000FF",
                    cursor: "pointer",
                  }}
                >
                  {storeBookingResponse.booking_url}
                </a>
                <div
                  className="pop-up-content-style text-highlighter"
                  style={{ marginTop: 25 }}
                >
                  Thank you for choosing minL:)
                </div>
                <div
                  className="button-style-popup"
                  style={{ marginTop: 25 }}
                  onClick={returnHome}
                >
                  OK
                </div>{" "}
              </m.div>
            </>
          ) : null}

          <Modal
            isOpen={showModal}
            onRequestClose={closeMapModal}
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                width: '80%',
                height: '80%',
                margin: 'auto',
                borderRadius: '10px',
                overflow: 'hidden',
              },
            }}
          >
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '20px',
                // border:"2px solid",
                width: "50px",
                height: "20px",
                cursor: 'pointer',
                border: 'none',
                background: 'transparent',
                color: 'black',
              }}
              onClick={closeMapModal}
            >
              X
            </button>
            {showAddressListPage ? (
              <m.div
                className="addressList-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.4, ease: "easeOut" }}
              >
                <div className="web-view-address-list">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginTop: 55,
                    }}
                  >
                    <img
                      style={{
                        width: 17,
                        height: 17,
                        marginLeft: 30,
                        marginRight: -40,
                        marginTop: 26,
                        marginBottom: -20,
                        position: "relative",
                      }}
                      src={SearchIcon}
                      alt="zeuez logo"
                    />
                    <input
                      className="form-field-style-search-addressList"
                      placeholder="Search address from list"
                      type="text"
                      onChange={(e) => setQuery(e.target.value)}
                    // onClick={navigate}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={mapOpen}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        marginLeft: 30,
                        marginTop: 20,
                      }}
                    >
                      <img
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: 5,
                          marginBottom: -20,
                        }}
                        src={LocationIcon}
                        alt="zeuez logo"
                      />
                      <div className="subtitle-style-addressList">
                        Choose address from map
                      </div>
                    </div>

                    {isActive ? (
                      <div className="addresslist-line-style-active"></div>
                    ) : (
                      <div className="addresslist-line-style-inactive"></div>
                    )}
                  </div>
                  <div className="subtitle-style-addressList-2">
                    YOUR ADDRESSES
                  </div>
                  <div className="form-container-addresslist">
                    {search(addressList).map((data) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                        key={data.id}
                        onClick={() => {
                          selectedFromList(data);
                        }}
                      >
                        <img
                          style={{
                            width: 20,
                            height: 20,
                            marginTop: 40,
                            marginBottom: -60,
                            marginLeft: 10,
                          }}
                          src={RecentSearchIcon}
                          alt="zeuez logo"
                        />

                        <div className="address-main-style">
                          {data.address.slice(0, data.address.indexOf(","))}
                        </div>
                        <div
                          className="address-sub-style"
                          style={{ marginTop: 10, paddingLeft: 15 }}
                        >
                          {data.address}
                        </div>
                        <div className="addresslist-line-style"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </m.div>
            ) : null}
            {showMap ? (
              <m.div
                className="map-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.4, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                style={{ marginTop: "5%" }}
              >

                <div className="map-container">

                  <GoogleMap
                    center={coords}
                    zoom={15}
                    mapContainerStyle={{ width: "120%", height: "100%" }}
                    onLoad={(map) => setMap(map)}
                  >
                    <MarkerF
                      position={coords}
                      draggable={true}
                      onLoad={(map) => onMapLoad(map)}
                      onDragEnd={(e) => onDragEnd(e)}
                    ></MarkerF>
                  </GoogleMap>
                </div>

                <form
                  onSubmit={handleSubmit((data) =>
                    console.log(data, "-----data")
                  )}
                  className="form-display-map"
                >
                  <div style={{ display: "flex", flexDirection: "row", marginLeft: "300px" }}>
                    <StandaloneSearchBox
                      onPlacesChanged={updatePlace}
                      onLoad={updateOnload}
                    >
                      <input
                        className="form-field-style-search"
                        {...register("address", { required: true })}
                        placeholder="Search address or landmark"
                        type="text"
                        onChange={(e) => {
                          // console.log(addressType, selectedPickupAddress.address.length > 15, "val1");
                          // console.log(selectedPickupAddress.address.length > 15, "val1");
                          if (addressType === "pickup") {
                            setSelectedPickupAddress({
                              ...selectedPickupAddress,
                              address: e.target.value,
                            });
                          } else {
                            setSelectedAddress({
                              ...selectedAddress,
                              address: e.target.value,
                            });
                          }
                        }}
                        value={
                          addressType === "pickup"
                            ? selectedPickupAddress.address
                            : selectedAddress.address
                        }
                        autoFocus={true}
                      />
                    </StandaloneSearchBox>
                    <div
                      style={{
                        width: 43,
                        height: 20,
                        color: "#ffffff",
                        backgroundColor: "#30a46c",
                        marginTop: 90,
                        marginLeft: 19,
                        padding: 5,
                        borderRadius: 5,
                        paddingLeft: 10,
                        fontSize: 16,
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        addressType === "pickup"
                          ? setSelectedPickupAddress({
                            ...selectedPickupAddress,
                            address: "",
                          })
                          : setSelectedAddress({
                            ...selectedAddress,
                            address: "",
                          })
                        setPickupCheck(false);
                        setDropoffCheck(false);
                      }
                      }
                    >
                      Clear
                    </div>
                  </div>
                </form>
                {!dropoffCheck !== !pickupCheck ? (
                  <div className="form-container-map">
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: "85%",
                          height: 80,
                          backgroundColor: "#def5ea",
                          elevation: 1,
                          borderRadius: 8,
                          padding: 8,
                          alignItems: "center",
                          flexDirection: "row",
                          display: "flex",
                          marginLeft: 20,
                          marginTop: 10,
                          gap: 40,
                        }}
                      >
                        <div>
                          <img
                            className="icon-style"
                            style={{ width: 40, height: 40 }}
                            src={OfficeIcon}
                            alt="edit icon"
                          />
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: "600",
                            color: "#000000",
                            width: "90%",
                          }}
                        >
                          {addressType === "pickup"
                            ? selectedPickupAddress.address
                            : selectedAddress.address}
                        </div>
                      </div>
                      <input
                        className="form-field-style-map"
                        // {...register("landmark")}
                        placeholder="LANDMARK"
                        type="text"
                        onChange={(e) => {
                          addressType === "pickup"
                            ? setSelectedPickupAddress({
                              ...selectedPickupAddress,
                              landmark: e.target.value,
                            })
                            : setSelectedAddress({
                              ...selectedAddress,
                              landmark: e.target.value,
                            });
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignContent: "center",
                          marginTop: "10px",
                          // border:'2px solid'
                        }}
                      // className="web-view-style-map-check"
                      >
                        <input
                          className="form-field-style-map"
                          style={{ width: 17, height: 17 }}
                          {...register("saveAddress")}
                          onClick={() => {
                            setSaveAddress(!saveAddress);
                          }}
                          type="checkbox"
                        />
                        <label className="subtitle-style-map">
                          Add to saved addresses
                        </label>
                      </div>

                      <div style={{ marginTop: 20, marginLeft: 25 }}>
                        <button
                          style={{
                            boxShadow: "3px 3px #a1e3c3",
                            backgroundImage: "linear-gradient(to bottom right, #46c98a, #30a46c)",
                            fontSize: "14px",
                            color: "white",
                            fontWeight: "600",
                            width: "120px",
                            height: "30px",
                            borderRadius: "14px",
                            marginLeft: "25px",
                            fontFamily: "Tahoma, Geneva, Verdana, sans-serif"
                          }}
                          // disabled={""}
                          onClick={() => {
                            setPickupCheck(false);
                            navigateToHome();
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                ) : null}

              </m.div>
            )
              // ) : showMap ? (
              //   <m.div
              //     className="map-outer-container"
              //     initial={{ y: "100%" }}
              //     animate={{ y: "0%" }}
              //     transition={{ duration: 0.4, ease: "easeOut" }}
              //     exit={{ opacity: 0 }}
              //     style={{ marginTop: "5%" }}
              //   >

              //     <div className="map-container">

              //       <GoogleMap
              //         center={coords}
              //         zoom={15}
              //         mapContainerStyle={{ width: "120%", height: "100%" }}
              //         onLoad={(map) => setMap(map)}
              //       >
              //         <MarkerF
              //           position={coords}
              //           draggable={true}
              //           onLoad={(map) => onMapLoad(map)}
              //           onDragEnd={(e) => {
              //             setCoords({
              //               ...coords,
              //               lat: e.latLng?.lat(),
              //               lng: e.latLng?.lng(),
              //             });
              //             findAddress(e.latLng?.lat(), e.latLng?.lng());
              //           }}
              //         ></MarkerF>
              //       </GoogleMap>
              //     </div>

              //     <form
              //       onSubmit={handleSubmit((data) =>
              //         console.log(data, "-----data")
              //       )}
              //       className="form-display-map"
              //     >
              //       <div style={{ display: "flex", flexDirection: "row", marginLeft: "300px" }}>
              //         <StandaloneSearchBox
              //           onPlacesChanged={updatePlace}
              //           onLoad={updateOnload}
              //         >
              //           <input
              //             className="form-field-style-search"
              //             // {...register("address", { required: true })}
              //             placeholder="Search address or landmark"
              //             type="text"
              //             onChange={(e) => {
              //               // console.log(addressType, selectedPickupAddress.address.length > 15, "val1");
              //               // console.log(selectedPickupAddress.address.length > 15, "val1");
              //               if (addressType === "pickup") {
              //                 setSelectedPickupAddress({
              //                   ...selectedPickupAddress,
              //                   address: e.target.value,
              //                 });
              //               } else {
              //                 setSelectedAddress({
              //                   ...selectedAddress,
              //                   address: e.target.value,
              //                 });
              //               }
              //             }}
              //             value={
              //               addressType === "pickup"
              //                 ? selectedPickupAddress.address
              //                 : selectedAddress.address
              //             }
              //             autoFocus={true}
              //           />
              //         </StandaloneSearchBox>
              //         <div
              //           style={{
              //             width: 50,
              //             height: 20,
              //             color: "#ffffff",
              //             backgroundColor: "#30a46c",
              //             marginTop: 90,
              //             marginLeft: 19,
              //             padding: 5,
              //             borderRadius: 5,
              //             paddingLeft: 10,
              //             fontSize: 16,
              //             cursor: "pointer"
              //           }}
              //           onClick={(e) =>
              //             addressType === "pickup"
              //               ? setSelectedPickupAddress({
              //                 ...selectedPickupAddress,
              //                 address: "",
              //               })
              //               : setSelectedAddress({
              //                 ...selectedAddress,
              //                 address: "",
              //               })
              //           }
              //         >
              //           Clear
              //         </div>
              //       </div>
              //     </form>


              //     {selectedAddress.address.length > 10 || selectedPickupAddress.address.length > 10 ? (
              //       <div className="form-container-map">
              //         <div
              //           style={{
              //             display: "flex",
              //             flexDirection: "column",
              //           }}
              //         >
              //           {/* <input
              //         className="form-field-style-map"
              //         style={{ color: "#bbb" }}
              //         // {...register("address")}
              //         placeholder="ADDRESS"
              //         type="text"
              //         value={selectedAddress.address}
              //         readOnly
              //         // onChange={(e) => {
              //         //   setSelectedAddress({
              //         //     ...selectedAddress,
              //         //     address: e.target.value,
              //         //   });
              //         // }}
              //       /> */}
              //           <div
              //             style={{
              //               width: "85%",
              //               height: 80,
              //               backgroundColor: "#def5ea",
              //               elevation: 1,
              //               borderRadius: 8,
              //               padding: 8,
              //               alignItems: "center",
              //               flexDirection: "row",
              //               display: "flex",
              //               marginLeft: 20,
              //               marginTop: 10,
              //               gap: 40,
              //             }}
              //           >
              //             <div>
              //               <img
              //                 className="icon-style"
              //                 style={{ width: 40, height: 40 }}
              //                 src={OfficeIcon}
              //                 alt="edit icon"
              //               />
              //             </div>
              //             <div
              //               style={{
              //                 fontSize: 12,
              //                 fontWeight: "600",
              //                 color: "#000000",
              //                 width: "90%",
              //               }}
              //             >
              //               {addressType === "pickup"
              //                 ? selectedPickupAddress.address
              //                 : selectedAddress.address}
              //             </div>
              //           </div>
              //           <input
              //             className="form-field-style-map"
              //             // {...register("landmark")}
              //             placeholder="LANDMARK"
              //             type="text"
              //             onChange={(e) => {
              //               addressType === "pickup"
              //                 ? setSelectedPickupAddress({
              //                   ...selectedPickupAddress,
              //                   landmark: e.target.value,
              //                 })
              //                 : setSelectedAddress({
              //                   ...selectedAddress,
              //                   landmark: e.target.value,
              //                 });
              //             }}
              //             disabled
              //           />
              //           <div
              //             style={{
              //               display: "flex",
              //               flexDirection: "row",
              //               justifyContent: "flex-start",
              //               alignContent: "center",
              //               marginTop: "10px",
              //               // border:'2px solid'
              //             }}
              //           // className="web-view-style-map-check"
              //           >
              //             <input
              //               className="form-field-style-map"
              //               style={{ width: 17, height: 17 }}
              //               {...register("saveAddress")}
              //               onClick={() => {
              //                 setSaveAddress(!saveAddress);
              //               }}
              //               type="checkbox"
              //             />
              //             <label className="subtitle-style-map">
              //               Add to saved addresses
              //             </label>
              //           </div>

              //           <div style={{ marginTop: 20, marginLeft: 25 }}>
              //             <button
              //               style={{
              //                 boxShadow: "3px 3px #a1e3c3",
              //                 backgroundImage: "linear-gradient(to bottom right, #46c98a, #30a46c)",
              //                 fontSize: "14px",
              //                 color: "white",
              //                 fontWeight: "600",
              //                 width: "120px",
              //                 height: "30px",
              //                 borderRadius: "14px",
              //                 marginLeft: "25px",
              //                 fontFamily: "Tahoma, Geneva, Verdana, sans-serif"
              //               }}
              //               // disabled={""}
              //               onClick={() => {
              //                 navigateToHome();
              //               }}
              //             >
              //               Add
              //             </button>


              //           </div>
              //         </div>
              //       </div>
              //     ) : null}
              //   </m.div>
              // ) 
              : null}
          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={termsModal}
            onRequestClose={termsModClose}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                width: '80%',
                height: '80%',
                margin: 'auto',
                borderRadius: '10px',
                overflow: 'hidden',
              },
            }}
          >
            <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '20px',
                // border:"2px solid",
                width: "50px",
                height: "20px",
                cursor: 'pointer',
                border: 'none',
                background: 'transparent',
                color: 'black',
              }}
              onClick={termsModClose}
            >
              X
            </button>

            <>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container-termandcon"
              >
                {/* <div
                  onClick={() => setShowTermsandConditionsPopup(false)}
                  className="close-button"
                >
                  X
                </div> */}

                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Terms and Conditions
                </div>
                <div className="subtitle" style={{ marginTop: 20 }}>
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile app/
                  platform. The following Terms &amp; Conditions shall apply to
                  customers utilising the Services offered by the GoodMove
                </div>
                <div className="subtitle"> Mobile app to Transport:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  The customer shall pay the fare (as agreed), toll (where
                  applicable) and any fee or levy presently payable or
                  hereinafter imposed by the law or required to be paid for
                  availing of the transport Services. The customer agrees and
                  accepts that the use of our Services is at the sole risk of
                  the Customer, and further acknowledges that the GoodMove
                  disclaims all representations and warranties of any kind,
                  whether express or implied.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  The customer shall ensure that he/she will not indulge in any
                  of the following activities while availing the service :
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Asking the driver to break any Traffic/RTO/City Police and/or
                  government rules for any purpose. The driver has the right to
                  refuse such a request by the customer. The driver also has the
                  right to refuse such a pick-up.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Pressurizing the driver to overload the truck with the
                  consignment than the allowed limit. The customer shall
                  indemnify company from and against and in respect of any or
                  all liabilities, losses, charges and expenses (including legal
                  fees and costs on a full indemnity basis) claims, demands,
                  actions and proceedings which GoodMove may incur or sustain
                  directly or indirectly from or by any reason of or in relation
                  to the use or proposed use of the services by the customer and
                  shall pay such sums on demand on the GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to disclose to all companies within
                  its group, or any government body as so required by the law or
                  by directive or request from any government body, the
                  particulars of the customer in the possession of company in
                  any way as company, in its absolute discretion, deems fit or
                  if it considers it in its interests to do so. GoodMove shall
                  be entitled at any time without giving any reason to terminate
                  the booking to transport the consignment done by the customer.
                  User(s) shall indemnify GoodMove with respect to any expenses
                  incurred with respect to such booking.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  In the case of lost items inside the consolidation centre or
                  during the journey, GoodMove will try to locate the items on a
                  best-effort basis but is not responsible for the same in case
                  of loss or damage to the same. GoodMove aggregates its
                  vehicles for the purpose of providing services. In the event
                  of loss of any item, user(s) shall not have any right to
                  withhold the payment to be made towards the third party or
                  agent operating this mobile app. Any complaint in respect of
                  the services or the use of the freight vehicle, the customer
                  has to inform GoodMove of the same in writing within 24 hours
                  of using the freight vehicle or the services of GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall not be liable for any conduct of the drivers of
                  freight vehicles. However, GoodMove encourages you to notify
                  it of any complaints that you may have against the driver that
                  you may have hired using our Services.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to add to, vary or amend any or all
                  these terms and conditions at any time and the customer shall
                  be bound by such addition, variation or amendment once such
                  addition, variation or amendment are incorporated into these
                  terms and conditions.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  All the calls made to the customer care are recorded for
                  quality and training purposes. In the event you place a query
                  on our app including query with respect to our services,
                  applicable fees or terms of service, you hereby expressly
                  agree to consent to receive our responses, whether by way of
                  telephonic calls or electronic mail, to such query and all
                  related information with respect to our services. For removal
                  of doubts, related information shall include without
                  limitation any marketing and/or commercial information, you
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  “National Do Not Call Register” or the “Private Do Not Call
                  Register” in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Toll Charges :</div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  In case of a toll on your trip, return toll fare will be
                  charged.
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile
                  app/platform. All the calls made to the customer care are
                  recorded for quality and training purposes. In the event you
                  place a query on our app including query with respect to our
                  Services, applicable fees or terms of Service, You hereby
                  expressly agree to consent to receive our responses, whether
                  by way of telephonic calls or electronic mail, to such query
                  and all related information with respect to our Services. For
                  removal of doubts, related information shall include without
                  limitation any marketing and/or commercial information. You
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  'National Do Not Call Register' or the 'Private Do Not Call
                  Register' in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Usage of Location data:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  GoodMove will collect the precise location of the user's
                  device when the app is running in the foreground or
                  background, if the app is allowed to access location services
                  through permissions in the mobile OS. We use this data to
                  enhance your use of our apps, including to improve pick-up/
                  drop off locations, enable safety features, and prevent and
                  detect fraud.
                </div>
                <div className="subtitle">
                  We may collect the following information:
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Name of the person, company, job title. Contact information
                  including email address. Demographic information such as
                  postcode, address, mobile number, login name, preferences and
                  interests. Use Our Services, we may collect and store
                  information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your phone number, address,
                  email, billing information, invoices, etc. Other information
                  relevant to customer surveys and/or offers, app install,
                  uninstall and other installed apps information.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  {" "}
                  We require this information to understand your needs and
                  provide you with a better service
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  We may use the information to improve our products and
                  services. We may periodically send promotional emails, SMSs
                  and make voice calls about new products, special offers or
                  other information which we think you may find interesting
                  using the email address and mobile number which you have
                  provided. From time to time, we may also use your information
                  to contact you for market research purposes. We may contact
                  you by email, SMS, voice, fax or mail. We may use the
                  information to customize the website according to your
                  interests. You may specifically opt-out of the above in
                  accordance with the Telecom Commercial Communications Customer
                  Preference Regulations, 2018 or otherwise specified. In case
                  you wish to opt out of the same, please contact us at
                  admin@gogoodmove.com
                </div>{" "}
              </m.div>
            </>

          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={isModalOpen}
            // onRequestClose={modalClose}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                width: '60%',
                height: '50%',
                margin: 'auto',
                borderRadius: '10px',
                overflow: 'hidden',
              },
            }}
          >
            {!isModalOpen && (<button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '20px',
                // border:"2px solid",
                width: "50px",
                height: "20px",
                cursor: 'pointer',
                border: 'none',
                background: 'transparent',
                color: 'black',
              }}
              onClick={modalClose}
            >
              X
            </button>)}
            <>

              <div style={{ display: "flex", flexDirection: "column" }}> OTP verification

                {!buttonDisable && <button
                  className={
                    "button-style-main"
                  }
                  style={{
                    width: 120,
                    height: 40,
                    paddingTop: 2,
                    marginTop: 20,
                  }}
                  disabled={buttonDisable}
                  onClick={() => {
                    sendOTP();
                  }}
                >
                  Send OTP
                </button>}
                <div
                  className="description-style"
                  style={{ fontSize: 14, marginTop: 30, marginBottom: 10 }}
                >
                  Enter the 4-Digit code sent to your mobile number ******{bookingDetails.customer_mobile.slice(-4)}
                </div>
                <div style={{ flexDirection: "row" }}>
                  <form>
                    <input
                      className="form-field-style"
                      style={{
                        width: 25,
                        height: 35,
                        marginTop: 10,
                        marginLeft: 0,
                      }}
                      type="text"
                      pattern="[0-9]"
                      ref={firstInput}
                      maxLength={1}
                      onChange={(e) => {
                        setOtpInput1(e.target.value);
                        e && secondInput.current.focus();
                      }}
                    />
                    {/* { mobile&& (
            <p className="error-font-style">mobile number is required.</p>
          )} */}
                    <input
                      className="form-field-style"
                      style={{
                        width: 25,
                        height: 35,
                        marginTop: 10,
                        marginLeft: 0,
                      }}
                      type="text" pattern="[0-9]*"
                      ref={secondInput}
                      maxLength={1}
                      onChange={(e) => {
                        setOtpInput2(e.target.value);
                        e
                          ? thirdInput.current.focus()
                          : firstInput.current.focus();
                      }}
                    />
                    <input
                      className="form-field-style"
                      style={{
                        width: 25,
                        height: 35,
                        marginTop: 10,
                        marginLeft: 0,
                      }}
                      type="numeric"
                      ref={thirdInput}
                      maxLength={1}
                      onChange={(e) => {
                        setOtpInput3(e.target.value);
                        e
                          ? fourthInput.current.focus()
                          : secondInput.current.focus();
                      }}
                    />
                    <input
                      className="form-field-style"
                      style={{
                        width: 25,
                        height: 35,
                        marginTop: 10,
                        marginLeft: 0,
                      }}
                      type="numeric"
                      ref={fourthInput}
                      maxLength={1}
                      onChange={(e) => {
                        setOtpInput4(e.target.value);
                        !e && thirdInput.current.focus();
                      }}
                    />
                  </form>
                  <div
                    className="error-font-style-otp"
                  >
                    {/* {otpMessage} */}
                  </div>
                  <div
                    className={
                      otpInput1 === "" ||
                        otpInput2 === "" ||
                        otpInput3 === "" ||
                        otpInput4 === ""
                        ? "verify-button-disable"
                        : "verify-button"
                    }
                    style={{
                      width: 100,
                      height: 30,
                      paddingTop: 10,
                      marginLeft: 10,
                      marginTop: 20,
                      display: "flex",
                      justifyContent: 'center'
                    }}

                    onClick={() => {
                      // setShowOTPinput(true);
                      verifyUser(bookingDetails.customer_mobile);

                      // if (Isverified) navigate("/orderHistory");
                    }}
                  >
                    Verify OTP
                  </div>
                </div>
              </div>
            </>
          </Modal>
          <Modal
            ariaHideApp={false}
            isOpen={rideCheck}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                width: '60%',
                height: '50%',
                margin: 'auto',
                borderRadius: '10px',
                overflow: 'hidden',
              },
            }}
          >
            {/* Close button */}
            {rideCheck && (
              <button
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  fontSize: '20px',
                  width: "50px",
                  height: "20px",
                  cursor: 'pointer',
                  border: 'none',
                  background: 'transparent',
                  color: 'black',
                }}
                onClick={() => setRideCheck(false)}
              >
                X
              </button>
            )}
            <>
              {/* Offer section */}
              <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                <h2>Special Offer</h2>
                <p style={{ fontSize: 16, marginTop: 20 }}>Choose Bachat for Free Delivery!</p>
                <p style={{ fontSize: 16, marginTop: 20 }}>Inaugural offer till April 14th 2024 & limit to one per customer per day</p>
              </div>
            </>
          </Modal>

          {showLoader ? (
            <>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="loader-style"
              >
                <div
                  style={{
                    marginBottom: -80,
                    marginLeft: 34,
                    marginTop: 3,
                  }}
                >
                  <ReactLoading
                    type="balls"
                    color="#30A46C"
                    height={"65%"}
                    width={"65%"}
                  />
                  <div
                    className="pop-up-content-style"
                    style={{ marginTop: -25, marginLeft: -30 }}
                  >
                    Please wait...
                  </div>
                </div>
              </m.div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default ShopBooking;


// toFime = moment({
//   year: today.getFullYear(),
//   month: today.getMonth(),
//   date: today.getDate(),
//   hours: bookingDetails.dropToTime.slice(0, 2),
//   minute: bookingDetails.dropToTime.slice(3, 5),
//   second: 0,
// }).format("YYYY-MM-DD HH:mm:ss");
// const date1 = new Date(fromTime);
// const date2 = new Date(toFime);
// console.log(date1, date2, "-----date12");
// if (date1 > date2) {
//   console.log("coming inside 5-------");
//   console.log("time1 is later than time2");
//   setIsValidTime(false);
// } else if (date1 < date2) {
//   console.log("time1 is earlier than time2");
//   setIsValidTime(true);
// } else {
//   console.log("time1 and time2 are the same");
//   setIsValidTime(false);
// }

// else if (nextDay && vegam) {
//   // const tomorrowFns = add(new Date(), {
//   //   days: 1,
//   // });
//   // console.log("tomorrow", tomorrowFns);
//   // fromTime = moment({
//   //   year: tomorrowFns.getFullYear(),
//   //   month: tomorrowFns.getMonth(),
//   //   date: tomorrowFns.getDate(),
//   //   hours: +bookingDetails.dropFromTime.slice(0, 2),
//   //   minute: +bookingDetails.dropFromTime.slice(3, 5),
//   //   second: 0,
//   // }).format("YYYY-MM-DD hh:mm:ss");
//   fromTime = moment()
//     .add(1, "day") // Add 1 day to get to the next day
//     .format("YYYY-MM-DD HH:mm:ss");
//   console.log(fromTime, "----finalDate");
//   toFime = moment(fromTime)
//     .add(1, "hour") // Add 6 hours to the next day
//     .format("YYYY-MM-DD HH:mm:ss");
//   console.log(fromTime, "----finalDate");
//   // toFime = moment({
//   //   year: tomorrowFns.getFullYear(),
//   //   month: tomorrowFns.getMonth(),
//   //   date: tomorrowFns.getDate(),
//   //   hours: +bookingDetails.dropToTime.slice(0, 2),
//   //   minute: +bookingDetails.dropToTime.slice(3, 5),
//   //   second: 0,
//   // }).format("YYYY-MM-DD hh:mm:ss");
//   // const date1 = new Date(fromTime);
//   // const date2 = new Date(toFime);

//   // if (date1 > date2) {
//   //   console.log("time1 is later than time2");
//   //   setIsValidTime(false);
//   // } else if (date1 < date2) {
//   //   console.log("time1 is earlier than time2");
//   //   setIsValidTime(true);
//   // } else {
//   //   console.log("time1 and time2 are the same");
//   //   setIsValidTime(false);
//   // }
//   console.log(toFime, "----toFime");
// } else if (nextDay && vivegam) {
//   fromTime = moment()
//     .add(1, "day") // Add 1 day to get to the next day
//     .format("YYYY-MM-DD HH:mm:ss");
//   console.log(fromTime, "----finalDate");
//   toFime = moment(fromTime)
//     .add(6, "hour") // Add 6 hours to the next day
//     .format("YYYY-MM-DD HH:mm:ss");
//   console.log(fromTime, "----finalDate");
// }
// if (sameDay) {
//   console.log("coming inside 4-------");

//   const today = new Date();

//   fromTime = moment({
//     year: today.getFullYear(),
//     month: today.getMonth(),
//     date: today.getDate(),
//     hours: bookingDetails.dropFromTime.slice(0, 2),
//     minute: bookingDetails.dropFromTime.slice(3, 5),
//     second: 0,
//   }).format("YYYY-MM-DD HH:mm:ss");
//   console.log(fromTime, "----finalDate");
//   console.log(
//     bookingDetails.dropToTime.slice(0, 2),
//     bookingDetails.dropToTime.slice(3, 5),
//     "-----bookingDetails.dropToTime.slice(3, 5)"
//   );
//   toFime = moment({
//     year: today.getFullYear(),
//     month: today.getMonth(),
//     date: today.getDate(),
//     hours: bookingDetails.dropToTime.slice(0, 2),
//     minute: bookingDetails.dropToTime.slice(3, 5),
//     second: 0,
//   }).format("YYYY-MM-DD HH:mm:ss");

//   // const date1 = new Date(fromTime);
//   // const date2 = new Date(toFime);
//   // console.log(date1, date2, "-----date12");
//   // if (date1 > date2) {
//   //   console.log("coming inside 5-------");
//   //   console.log("time1 is later than time2");
//   //   setIsValidTime(false);
//   // } else if (date1 < date2) {
//   //   console.log("time1 is earlier than time2");
//   //   setIsValidTime(true);
//   // } else {
//   //   console.log("time1 and time2 are the same");
//   //   setIsValidTime(false);
//   // }
//   console.log(toFime, "----toFime");
// } else if (nextDay) {
//   const tomorrowFns = add(new Date(), {
//     days: 1,
//   });
//   console.log("tomorrow", tomorrowFns);
//   fromTime = moment({
//     year: tomorrowFns.getFullYear(),
//     month: tomorrowFns.getMonth(),
//     date: tomorrowFns.getDate(),
//     hours: +bookingDetails.dropFromTime.slice(0, 2),
//     minute: +bookingDetails.dropFromTime.slice(3, 5),
//     second: 0,
//   }).format("YYYY-MM-DD hh:mm:ss");
//   console.log(fromTime, "----finalDate");
//   toFime = moment({
//     year: tomorrowFns.getFullYear(),
//     month: tomorrowFns.getMonth(),
//     date: tomorrowFns.getDate(),
//     hours: +bookingDetails.dropToTime.slice(0, 2),
//     minute: +bookingDetails.dropToTime.slice(3, 5),
//     second: 0,
//   }).format("YYYY-MM-DD hh:mm:ss");
//   // const date1 = new Date(fromTime);
//   // const date2 = new Date(toFime);

//   // if (date1 > date2) {
//   //   console.log("time1 is later than time2");
//   //   setIsValidTime(false);
//   // } else if (date1 < date2) {
//   //   console.log("time1 is earlier than time2");
//   //   setIsValidTime(true);
//   // } else {
//   //   console.log("time1 and time2 are the same");
//   //   setIsValidTime(false);
//   // }
//   console.log(toFime, "----toFime");
// }
// eslint-disable-next-line
{/* <div className="form-container-map">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    className="form-field-style-map"
                    // {...register("address")}
                    placeholder="ADDRESS"
                    type="text"
                    value={
                      addressType == "pickup"
                        ? selectedPickupAddress.address
                        : selectedAddress.address
                    }
                    onChange={(e) => {
                      if (addressType == "pickup") {
                        setSelectedPickupAddress({
                          ...selectedPickupAddress,
                          address: e.target.value,
                        });
                      } else {
                        setSelectedAddress({
                          ...selectedAddress,
                          address: e.target.value,
                        });
                      }
                    }}
                  />
                  <input
                    className="form-field-style-map"
                    {...register("landmark")}
                    placeholder="LANDMARK"
                    type="text"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className="web-view-style-map-check"
                  >
                    <input
                      className="form-field-style-map"
                      style={{ width: 17, height: 17 }}
                      {...register("saveAddress")}
                      onClick={() => {
                        setSaveAddress(!saveAddress);
                      }}
                      type="checkbox"
                    />
                    <label className="subtitle-style-map">
                      Add to saved addresses
                    </label>
                  </div>

                  <div style={{ marginTop: 20, marginLeft: 25 }}>
                    <div
                      className="button-style-popup"
                      // type="submit"

                      onClick={navigateToHome}
                    >
                      Add
                    </div>
                  </div>
                </form>
              </div>  
              
              <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: -11,
                      }}
                    >
                      <input
                        className="day-field-align-same"
                        {...register("sameDay", { required: true })}
                        type="radio"
                        checked={sameDay}
                        onChange={(e) => handleCheckChange(e, "sameDay")}
                      />
                      <lable
                        className="day-field-label-same"
                        // style={{ width: 70, marginLeft: -75, marginTop: 12 }}
                      >
                        Same day
                      </lable>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: -10,
                      }}
                    >
                      <input
                        className="day-field-align-next"
                        // {...register("nextDay", { required: true })}
                        type="radio"
                        checked={nextDay}
                        onChange={(e) => handleCheckChange(e, "nextDay")}
                      />
                      <lable
                        className="day-field-label-next"
                        // style={{ width: 60, marginLeft: -78, marginTop: 12 }}
                      >
                        Next day
                      </lable>
                    </div>
                    {nextDay === false && sameDay === false && (
                      <p className="error-font-style">Please choose day.</p>
                    )}{" "}
                  </div> */}


// let checkDistance = await getBookingDistance(reqObj);
// if (checkDistance.success) {
//   setSelectedAddress({
//     ...selectedAddress,
//     address: data.address,
//     address_type: data.address_type,
//     city: data.city,
//     country: data.country,
//     latitude: data.latitude,
//     longitude: data.longitude,
//     pincode: data.pincode,
//     state: data.state,
//     receiver_contact: data.receiver_contact,
//     receiver_name: data.receiver_name,
//   });
// } else {
//   alert(checkDistance.message);
// }

// if (sourceCheck.success) {
//   setSelectedPickupAddress({
//     ...selectedPickupAddress,
//     address,
//     city,
//     state,
//     country,
//     pincode,
//     latitude: addressObj.geometry.location.lat(),
//     longitude: addressObj.geometry.location.lng(),
//   });
// } else {
//   alert(sourceCheck.message);
// }

// let sourceCheck = await checkSourceLocation({ pincode: data.pincode });
// console.log("sourceCheck");
// console.log(sourceCheck);
// if (sourceCheck.success) {
//   setSelectedPickupAddress({
//     ...selectedPickupAddress,
//     address: data.address,
//     address_type: data.address_type,
//     city: data.city,
//     country: data.country,
//     latitude: data.latitude,
//     longitude: data.longitude,
//     pincode: data.pincode,
//     state: data.state,
//     receiver_contact: data.receiver_contact,
//     receiver_name: data.receiver_name,
//   });
// } else {
//   alert(sourceCheck.message);
// }




// const showBookedPopup = async () => {

//   console.log("coming inside 1-------");
//   // console.log(file, payType,selectedAddress.address, "-----file----");
//   // console.log(selectedPickupAddress.address, "val");
//   if (bookingDetails.customer_mobile !== "") {
//     setMobileCheck(false);
//     setIsEnableButton(false);
//   } else {
//     setMobileCheck(true);
//   }

//   if (selectedPickupAddress.address === "") {
//     setIsAddress(true);
//   } else {
//     setIsAddress(false);
//     setIsEnableButton(false);

//   }
//   if (selectedAddress.address === "") {
//     setIsAddressDrop(true);
//     console.log("hit 2")
//   } else {
//     console.log('first 2')
//     setIsAddressDrop(false);
//     setIsEnableButton(false);

//   }

//   if (!checked) {
//     setTermsError(true);
//   } else {
//     setTermsError(false);
//   }

//   if (file === null && payType !== "" && selectedAddress.address !== "") {
//     setIsFileUploaded(true);
//     setIsPayTypeChoosed(false);
//     // setIsAddress(false);
//   } else if (
//     payType === "" &&
//     file !== null &&
//     selectedAddress.address !== ""
//   ) {
//     setIsPayTypeChoosed(true);
//     setIsFileUploaded(false);
//     // setIsAddress(false);
//   } else if (
//     selectedAddress.address === "" &&
//     file !== null &&
//     payType !== ""
//   ) {
//     // setIsAddress(true);
//     setIsAddressDrop(true);
//     setIsPayTypeChoosed(false);
//     setIsFileUploaded(false);
//   } else if (
//     selectedAddress.address === "" &&
//     file === null &&
//     payType === ""
//   ) {
//     setIsFileUploaded(true);
//     setIsPayTypeChoosed(true);
//     // setIsAddress(true);
//   } else if (
//     payType === "" &&
//     file === null &&
//     selectedAddress.address !== ""
//   ) {
//     setIsFileUploaded(true);
//     setIsPayTypeChoosed(true);
//     // setIsAddress(false);
//   } else if (
//     payType === "" &&
//     file !== null &&
//     selectedAddress.address === ""
//   ) {
//     setIsPayTypeChoosed(true);
//     // setIsAddress(true);
//     setIsFileUploaded(false);
//   } else if (
//     payType !== "" &&
//     file === null &&
//     selectedAddress.address === ""
//   ) {
//     // setIsAddress(true);
//     setIsFileUploaded(true);
//     setIsPayTypeChoosed(false);
//   } else if (
//     payType !== "" &&
//     file !== null &&
//     selectedAddress.address !== ""
//   ) {
//     setIsFileUploaded(false);
//     setIsPayTypeChoosed(false);
//     // setIsAddress(false);
//   }

//   if (!vegam && !vivegam) {
//     setIsBookingTypeSelected(false);
//   }

//   if (!pickup && !threeWheeler && !twoWheeler) {
//     setIsVehicleChoosed(true);
//   } else if (pickup || !threeWheeler || !twoWheeler) {
//     setIsVehicleChoosed(false);
//   } else if (!pickup || threeWheeler || !twoWheeler) {
//     setIsVehicleChoosed(false);
//   } else if (pickup || threeWheeler || twoWheeler) {
//     setIsVehicleChoosed(false);
//   }
//   // if (isEnableButton) {
//   //   setFareDetails({});
//   //   setTwoWheeler(false);
//   //   setThreeWheeler(false);
//   //   setPickup(false);
//   //   alert("Please accept the rate before you confirm the booking!");

//   //   return;
//   // }

//   if (!mobileCheck
//     && !isAddress
//     && !isAddressDrop
//     && isBookingTypeSelected
//     && !isPayTypeChoosed
//     && !isVehicleChoosed
//     && !isFileUploaded
//     // && isAgreedTermsandCond
//     && !termsError
//   ) {
//     console.log("enter 3rd")
//     if (bookingDetails.customer_mobile.replace(/[^\d]/g, "").length === 10) {
//       console.log("coming inside 3-------");

//       let fromTime;
//       let toFime;
//       if (vegam) {
//         console.log("coming inside 4-------");
//         fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
//         toFime = moment(fromTime).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
//       } else if (vivegam) {
//         console.log("coming inside 5-------");
//         fromTime = moment().format("YYYY-MM-DD HH:mm:ss");
//         toFime = moment(fromTime).add(6, "hour").format("YYYY-MM-DD HH:mm:ss");
//       }

//       console.log("coming inside 6-------");

//       const bookingPayload = {
//         customer_id: 0,
//         pickup_location:
//           selectedPickupAddress.address + " " + selectedPickupAddress.landmark,
//         pickup_time: "",
//         pickuptime_flexiblity_status: "Fixed",
//         pickup_flexible_time: "",
//         dropoff_location:
//           selectedAddress.address + " " + selectedAddress.landmark,
//         dropoff_time: fromTime,
//         dropofftime_flexiblity_status: "Fixed",
//         dropoff_flexible_time: toFime,
//         commodity_type: 1,
//         commodity_weight: 0,
//         weight_unit: 12,
//         commodity_whole_volume: 1,
//         volume_unit: 35,
//         commodity_length: "",
//         length_unit: 37,
//         commodity_breadth: "",
//         breadth_unit: 37,
//         commodity_height: "",
//         height_unit: 37,
//         packaging_type: 1,
//         receiver_name: "Customer",
//         receiver_contact: bookingDetails.customer_mobile,
//         instruction: "Test",
//         pickup_lat: selectedPickupAddress.latitude,
//         pickup_long: selectedPickupAddress.longitude,
//         pickup_city: selectedPickupAddress.city,
//         pickup_state: selectedPickupAddress.state,
//         pickup_country: selectedPickupAddress.country,
//         pickup_pincode: selectedPickupAddress.pincode,
//         dropoff_city: selectedAddress.city,
//         dropoff_state: selectedAddress.state,
//         dropoff_country: selectedAddress.country,
//         dropoff_pincode: selectedAddress.pincode,
//         dropoff_lat: selectedAddress.latitude,
//         dropoff_long: selectedAddress.longitude,
//         assign_tour_status: 0,
//         other_commodity_name: "",
//         is_having_loading_charges: false,
//         customer_mobile: bookingDetails.customer_mobile,
//         vehicle_type: twoWheeler
//           ? "2wheeler"
//           : threeWheeler
//             ? "3wheeler"
//             : "4wheeler",
//         payment_type: payType,
//         fare: fareDetails?.total_fare,
//         shop_id: shop_id ? shop_id : 0,
//         save_address: saveAddress,
//         consignment_image: file?.data,
//         delivery_on: "sameday",
//         bag_id: parseInt(bookingDetails.bagId),
//         is_vivegam: vivegam,
//       };

//       console.log(bookingPayload, setFareDetails(fareDetails?.total_fare), "----bookingpayloadddd");
//       if (
//         (payType === "COD" || payType === "PS") &&
//         payType !== "" &&
//         fromTime < toFime &&
//         isBookingTypeSelected
//         // &&!isVehicleChoosed
//       ) {
//         console.log("coming inside 7-------");
//         if (isEnableButton) {
//           setFareDetails({});
//           setTwoWheeler(false);
//           setThreeWheeler(false);
//           setPickup(false);
//           alert("Please accept the rate before you confirm the booking!");
//           console.log("coming inside 8-------");
//           return;
//         }
//         setShowLoader(true);
//         const result = await addBooking(bookingPayload);
//         // console.log(result, "----result for booking");
//         if (result.success === true) {
//           console.log("coming inside 9-------");

//           setShowLoader(false);
//           setStoreBookingResponse(result.data.bookingDetails);
//           setShowSuccessPopup(true);
//           setAddedBookingResult(result.data.bookingDetails);
//           localStorage.setItem("added booking", JSON.stringify(result.data));
//         } else {
//           console.log("coming inside 10-------");

//           setShowLoader(false);
//           setShowSuccessPopup(true);
//         }
//       } else if (
//         payType === "Online payment" &&
//         fromTime < toFime &&
//         isBookingTypeSelected
//         // && isVehicleChoosed
//       ) {
//         console.log("coming inside 11-------");

//         if (isEnableButton) {
//           setFareDetails({});
//           setTwoWheeler(false);
//           setThreeWheeler(false);
//           setPickup(false);
//           console.log("coming inside 12-------");

//           alert("Please accept the rate before you confirm the booking!");
//           return;
//         }
//         setShowLoader(true);
//         const result = await addBooking(bookingPayload);
//         setStoreBookingResponse(result.data.bookingDetails);
//         // console.log("booking result");
//         // console.log(result);
//         setEncRequest(result.data.encoded_value);
//         setAccessCode(result.data.accessCode);
//         setTimeout(() => {
//           window.removeEventListener("beforeunload", handleBeforeUnload);
//           ccForm.current.submit();
//           setShowLoader(false);
//         }, 2 * 1000);
//       }
//     }
//   }
//   else if (isEnableButton) {
//     console.log("enter enable button")
//     setFareDetails({});
//     setTwoWheeler(false);
//     setThreeWheeler(false);
//     setPickup(false);
//     alert("Please accept the rate before you confirm the booking!");
//     console.log("coming inside 13-------");

//     return;
//   }


// };
// eslint-disable-next-line
{/* {showFailedPopup ? (
            <>
              <m.div
                className="rate-outer-container"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>
              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container"
                style={{ height: 250 }}
              >
                <div style={{ marginTop: 40 }}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      marginTop: 20,
                      marginBottom: -20,
                      backgroundColor: "#c40000",
                      borderRadius: 28,
                      padding: 8,
                      paddingTop: 9,
                      color: "#ffffff",
                      marginLeft: 110,
                      fontSize: 30,
                      fontWeight: "bold",
                    }}
                  >
                    X
                  </div>
                </div>
                <div className="pop-up-content-style" style={{ marginTop: 40 }}>
                  Booking failed, Please try again!
                </div>
                <div
                  className="button-style-popup"
                  style={{ marginTop: 30 }}
                  onClick={returnHome}
                >
                  OK
                </div>{" "}
              </m.div>
            </>
          ) : null}     
          
          {showTermsandConditionsPopup ? (
            <>
              <m.div
                className="rate-outer-container-termandcon"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
              ></m.div>

              <m.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                exit={{ opacity: 0 }}
                className="rate-inner-container-termandcon"
              >
                <div
                  onClick={() => setShowTermsandConditionsPopup(false)}
                  className="close-button"
                >
                  X
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  About Us
                </div>
                <div className="subtitle" style={{ marginTop: 20 }}>
                  What we do
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  We are a motivated team of young dynamic individuals with a
                  vision to be a reliable end-to-end city logistics partner for
                  MSMEs in Chennai and beyond, deliver goods on-demand and allow
                  MSMEs to focus on their core business. For the past few years
                  we have been conducting surveys and studies to understand the
                  characteristics of the freight industry, analysing issues and
                  challenges faced by both shippers and truckers from a variety
                  of perspectives.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  GoodMove app is an initiative to consolidate freight in-order
                  to decrease the number of less than truckload trips and reduce
                  road congestion and emission of GHGs.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  For more details, please visit our website
                  <a href="https://goodmove.in/" style={{ marginLeft: 10 }}>
                    www.goodmove.in
                  </a>
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Terms and Conditions
                </div>
                <div className="subtitle" style={{ marginTop: 20 }}>
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile app/
                  platform. The following Terms &amp; Conditions shall apply to
                  customers utilising the Services offered by the GoodMove
                </div>
                <div className="subtitle"> Mobile app to Transport:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  The customer shall pay the fare (as agreed), toll (where
                  applicable) and any fee or levy presently payable or
                  hereinafter imposed by the law or required to be paid for
                  availing of the transport Services. The customer agrees and
                  accepts that the use of our Services is at the sole risk of
                  the Customer, and further acknowledges that the GoodMove
                  disclaims all representations and warranties of any kind,
                  whether express or implied.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  The customer shall ensure that he/she will not indulge in any
                  of the following activities while availing the service :
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Asking the driver to break any Traffic/RTO/City Police and/or
                  government rules for any purpose. The driver has the right to
                  refuse such a request by the customer. The driver also has the
                  right to refuse such a pick-up.
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Pressurizing the driver to overload the truck with the
                  consignment than the allowed limit. The customer shall
                  indemnify company from and against and in respect of any or
                  all liabilities, losses, charges and expenses (including legal
                  fees and costs on a full indemnity basis) claims, demands,
                  actions and proceedings which GoodMove may incur or sustain
                  directly or indirectly from or by any reason of or in relation
                  to the use or proposed use of the services by the customer and
                  shall pay such sums on demand on the GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to disclose to all companies within
                  its group, or any government body as so required by the law or
                  by directive or request from any government body, the
                  particulars of the customer in the possession of company in
                  any way as company, in its absolute discretion, deems fit or
                  if it considers it in its interests to do so. GoodMove shall
                  be entitled at any time without giving any reason to terminate
                  the booking to transport the consignment done by the customer.
                  User(s) shall indemnify GoodMove with respect to any expenses
                  incurred with respect to such booking.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  In the case of lost items inside the consolidation centre or
                  during the journey, GoodMove will try to locate the items on a
                  best-effort basis but is not responsible for the same in case
                  of loss or damage to the same. GoodMove aggregates its
                  vehicles for the purpose of providing services. In the event
                  of loss of any item, user(s) shall not have any right to
                  withhold the payment to be made towards the third party or
                  agent operating this mobile app. Any complaint in respect of
                  the services or the use of the freight vehicle, the customer
                  has to inform GoodMove of the same in writing within 24 hours
                  of using the freight vehicle or the services of GoodMove.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall not be liable for any conduct of the drivers of
                  freight vehicles. However, GoodMove encourages you to notify
                  it of any complaints that you may have against the driver that
                  you may have hired using our Services.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  GoodMove shall be entitled to add to, vary or amend any or all
                  these terms and conditions at any time and the customer shall
                  be bound by such addition, variation or amendment once such
                  addition, variation or amendment are incorporated into these
                  terms and conditions.
                </div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  All the calls made to the customer care are recorded for
                  quality and training purposes. In the event you place a query
                  on our app including query with respect to our services,
                  applicable fees or terms of service, you hereby expressly
                  agree to consent to receive our responses, whether by way of
                  telephonic calls or electronic mail, to such query and all
                  related information with respect to our services. For removal
                  of doubts, related information shall include without
                  limitation any marketing and/or commercial information, you
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  “National Do Not Call Register” or the “Private Do Not Call
                  Register” in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Toll Charges :</div>
                <div className="subtitle-style" style={{ margin: 30 }}>
                  {" "}
                  In case of a toll on your trip, return toll fare will be
                  charged.
                </div>
                <div
                  className="title-style"
                  style={{ marginTop: 20, fontSize: 13 }}
                >
                  Privacy Policy
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  This privacy policy sets out how GoodMove uses and protects
                  any information that you share when you use this mobile
                  app/platform. All the calls made to the customer care are
                  recorded for quality and training purposes. In the event you
                  place a query on our app including query with respect to our
                  Services, applicable fees or terms of Service, You hereby
                  expressly agree to consent to receive our responses, whether
                  by way of telephonic calls or electronic mail, to such query
                  and all related information with respect to our Services. For
                  removal of doubts, related information shall include without
                  limitation any marketing and/or commercial information. You
                  understand and agree that such information shall in no event
                  qualify as unsolicited commercial communication under the
                  Telecom Unsolicited Commercial Communications Regulations,
                  2007 and/or due to disclosure of such information, our
                  telephone numbers shall not qualify to be registered under the
                  'National Do Not Call Register' or the 'Private Do Not Call
                  Register' in accordance with the Telecom Unsolicited
                  Commercial Communications Regulations, 2007 or any other
                  applicable law.
                </div>
                <div className="subtitle">Usage of Location data:</div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  GoodMove will collect the precise location of the user's
                  device when the app is running in the foreground or
                  background, if the app is allowed to access location services
                  through permissions in the mobile OS. We use this data to
                  enhance your use of our apps, including to improve pick-up/
                  drop off locations, enable safety features, and prevent and
                  detect fraud.
                </div>
                <div className="subtitle">
                  We may collect the following information:
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  Name of the person, company, job title. Contact information
                  including email address. Demographic information such as
                  postcode, address, mobile number, login name, preferences and
                  interests. Use Our Services, we may collect and store
                  information about you to process your requests and
                  automatically complete forms for future transactions,
                  including (but not limited to) your phone number, address,
                  email, billing information, invoices, etc. Other information
                  relevant to customer surveys and/or offers, app install,
                  uninstall and other installed apps information.
                </div>
                <div className="subtitle" style={{ margin: 20 }}>
                  {" "}
                  We require this information to understand your needs and
                  provide you with a better service
                </div>
                <div className="subtitle-style" style={{ margin: 20 }}>
                  We may use the information to improve our products and
                  services. We may periodically send promotional emails, SMSs
                  and make voice calls about new products, special offers or
                  other information which we think you may find interesting
                  using the email address and mobile number which you have
                  provided. From time to time, we may also use your information
                  to contact you for market research purposes. We may contact
                  you by email, SMS, voice, fax or mail. We may use the
                  information to customize the website according to your
                  interests. You may specifically opt-out of the above in
                  accordance with the Telecom Commercial Communications Customer
                  Preference Regulations, 2018 or otherwise specified. In case
                  you wish to opt out of the same, please contact us at
                  admin@gogoodmove.com
                </div>{" "}
              </m.div>
            </>
          ) : null} */}