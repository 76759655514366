export default function PrivacyPolicy() {
  return (
    // <div style={{ }}>
    <div
      className="privacy-policy-container"
      style={{ overflow: "scroll" }}
    >
      <div className="title-style" style={{ marginTop: 40 }}>
        Privacy Policy
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        This privacy policy sets out how GoodMove uses and protects any
        information that you share when you use this mobile app/platform. All
        the calls made to the customer care are recorded for quality and
        training purposes. In the event you place a query on our app including
        query with respect to our Services, applicable fees or terms of Service,
        You hereby expressly agree to consent to receive our responses, whether
        by way of telephonic calls or electronic mail, to such query and all
        related information with respect to our Services. For removal of doubts,
        related information shall include without limitation any marketing
        and/or commercial information. You understand and agree that such
        information shall in no event qualify as unsolicited commercial
        communication under the Telecom Unsolicited Commercial Communications
        Regulations, 2007 and/or due to disclosure of such information, our
        telephone numbers shall not qualify to be registered under the 'National
        Do Not Call Register' or the 'Private Do Not Call Register' in
        accordance with the Telecom Unsolicited Commercial Communications
        Regulations, 2007 or any other applicable law.
      </div>
      <div className="subtitle">Usage of Location data:</div>
      <div className="subtitle-style" style={{ margin: 20 }}>
        GoodMove will collect the precise location of the user's device when the
        app is running in the foreground or background, if the app is allowed to
        access location services through permissions in the mobile OS. We use
        this data to enhance your use of our apps, including to improve pick-up/
        drop off locations, enable safety features, and prevent and detect
        fraud.
      </div>
      <div className="subtitle">We may collect the following information:</div>
      <div className="subtitle-style" style={{ margin: 20 }}>
        Name of the person, company, job title. Contact information including
        email address. Demographic information such as postcode, address, mobile
        number, login name, preferences and interests. Use Our Services, we may
        collect and store information about you to process your requests and
        automatically complete forms for future transactions, including (but not
        limited to) your phone number, address, email, billing information,
        invoices, etc. Other information relevant to customer surveys and/or
        offers, app install, uninstall and other installed apps information.
      </div>
      <div className="subtitle" style={{ margin: 20 }}>
        {" "}
        We require this information to understand your needs and provide you
        with a better service
      </div>
      <div className="subtitle-style" style={{ margin: 20 }}>
        We may use the information to improve our products and services. We may
        periodically send promotional emails, SMSs and make voice calls about
        new products, special offers or other information which we think you may
        find interesting using the email address and mobile number which you
        have provided. From time to time, we may also use your information to
        contact you for market research purposes. We may contact you by email,
        SMS, voice, fax or mail. We may use the information to customize the
        website according to your interests. You may specifically opt-out of the
        above in accordance with the Telecom Commercial Communications Customer
        Preference Regulations, 2018 or otherwise specified. In case you wish to
        opt out of the same, please contact us at admin@gogoodmove.com
      </div>{" "}
    </div>
  );
}
