import { useEffect, useState , useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMinlBookingPayDetails, deleteBooking } from "./api/shop";

export default function PaymentFailed() {
  const navigate = useNavigate();
  const { booking_id } = useParams();
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const ccForm = useRef();

  const handleBeforeUnload = (event) => {
    // Check if the event is due to the back button being clicked
    if (event.clientX < 0 && event.clientY < 0) {
      // Show the confirmation popup
      event.returnValue = "Are you sure you want to leave?";
    }
  };
  const init = async () => {
    const addedBookingDetails = await getMinlBookingPayDetails({ booking_id: booking_id });
      setEncRequest(addedBookingDetails.data.encoded_value);
      setAccessCode(addedBookingDetails.data.accessCode);
      
  }

  const submitCC = async () => {
    setTimeout(() => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      ccForm.current.submit();
    }, 2 * 1000);
  }

  const cancelBooking = async () => {
    let booking = localStorage.getItem('added_booking')
    localStorage.removeItem('goToPayment')
    localStorage.removeItem('added_booking')
    const addedBookingDetails = await deleteBooking({ booking_id: booking_id });
    let bookingData = JSON.parse(booking)
    if(bookingData?.bookingDetails?.booking_type == 'Home Delivery') {
      navigate('/booking')
    } else {
      navigate('/delhi')
    }
    
  }
  useEffect(() => {
    
    init();
  }, []);
  return (
    <div className="pricing-policy-container" style={{ height: 340 }}>
      <div>
        <div className="payment-failed-style">X</div>
      </div>
      <div className="title-style" style={{ marginTop: 40, color: "#c40000" }}>
        Payment Failed!
      </div>
      <div className="subtitle-style" style={{ margin: 30 }}>
        Your payment for Booking id{" "}
        <span className="text-highlighter" style={{ fontSize: 11 }}>
          #{booking_id}
        </span>{" "}
        has been declined!
      </div>

      <div className="subtitle-style">
        Would you like to proceed with the payment?
      </div>

      {/* <div
        className="payment-failed-button-align"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
        }}
      >
        <div
          className="button-style"
          style={{
            width: 120,
            height: 25,
            paddingTop: 8,
            marginRight: 7,
          }}
          onClick={() => navigate(`/shop/${shopDetails.id}`)}
        >
          Retry
        </div>{" "}
        <div
          className="button-style"
          style={{
            width: 120,
            height: 25,
            paddingTop: 8,
            backgroundColor: "#c40000",
            boxShadow: "3px 3px #ff7676",
          }}
          onClick={() => navigate(`/shop/${shopDetails.id}`)}
        >
          No
        </div>
      </div> */}
      <div
        className="payment-failed-button-align"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          justifyContent:"center",
          alignItems:'center'
        }}
      >
        <div
          className="button-style"
          style={{
            width: 120,
            height: 25,
            paddingTop: 8,
            marginRight: 7,
          }}
          onClick={() => submitCC()}
        >
          Try again
        </div>{" "}
        <div
          className="button-style"
          style={{
            width: 120,
            height: 25,
            paddingTop: 8,
            backgroundColor: "#c40000",
            boxShadow: "3px 3px #ff7676",
          }}
          onClick={() => cancelBooking()}
        >
          Cancel
        </div>
      </div>
      <form
                ref={ccForm}
                id="nonseamless"
                method="post"
                name="redirect"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  type="hidden"
                  id="encRequest"
                  name="encRequest"
                  value={encRequest}
                />
                <input
                  type="hidden"
                  name="access_code"
                  id="access_code"
                  value={accessCode}
                />
              </form>
    </div>
  );
}
