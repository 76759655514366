import { useEffect, useState } from "react";
import { motion as m } from "framer-motion";
import MenuIcon from "./images/menuNew.png";
import MinlLogo from "./images/minllogo4.png";
import "./App.css";
import { useNavigate } from 'react-router-dom';
import logo2 from './images/moevinglogo.png';

export default function Navbar() {

  const [showSideBar, setShowSideBar] = useState(false);
  const [showDriven,setShowDriven] = useState(false);
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const urlParts = currentUrl?.split('/');
  const bookingId = urlParts[urlParts.length - 1];
  console.log(bookingId, "id val")
  
  const handleClick =()=>{
    if(bookingId === "delhi"){
      navigate("/delhi")
    } else {

      navigate('/');
    }
  }
  

  useEffect(()=>{
    if(bookingId === "delhi"){
      setShowDriven(true);
    }
  },[showDriven])

  return (
    <>
      <div className="sideBar">
        <div 
        onClick={handleClick}
        >
          <img
            style={{
              width: 150,
              height: 45,
              marginTop: 40,
              marginBottom: -5,
              marginLeft: -115,
              position: "absolute",
              cursor:"pointer"
            }}
            src={MinlLogo}
            alt="zeuez logo"
            // onClick={handleClick}
          />
        </div>

        <nav className="nav">
          <ul className="side-title">
            <CustomLink href={`/aboutus/${bookingId}`} style={{ marginLeft: -150 }}>
              {" "}
              About Us
            </CustomLink>
            <CustomLink href={`/privacypolicy/${bookingId}`} style={{ marginLeft: -115 }}>
              {" "}
              Privacy Policy
            </CustomLink>
            <CustomLink href={`/pricingPolicy/${bookingId}`} style={{ marginLeft: -119 }}>
              {" "}
              Pricing Policy
            </CustomLink>
            <CustomLink href={`/termsandconditions/${bookingId}`} style={{ marginLeft: -60 }}>
              {" "}
              Terms and Conditions
            </CustomLink>
            <CustomLink
              href={`/refundandcancellation/${bookingId}`}
              style={{ marginLeft: -2 }}
            >
              {" "}
              Refund & Cancellation Policy
            </CustomLink>
            <CustomLink href={`/contact/${bookingId}`} style={{ marginLeft: -135 }}>
              {" "}
              Contact Us
            </CustomLink>
          </ul>
        </nav>
        {showDriven && <div style={{ color:'#000',marginTop:"40%"}}>
              Driven by
              <img style={{width:260,height:50}} src={logo2} alt=""/>
            </div>}
      </div>
      <div className="app-header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          onClick={handleClick}
        >
          <img
            src={MenuIcon}
            alt="menu icon"
            onClick={() => {
              setShowSideBar(true);
            }}
            style={{
              width: 60,
              height: 50,
              marginLeft: -120,
              marginTop: 9,
              cursor: "pointer",
            }}
          />

          <div>
            <img
              style={{
                width: 110,
                height: 32,
                marginTop: 10,
                marginBottom: -5,
                marginLeft: 60,
              }}
              src={MinlLogo}
              alt="zeuez logo"
            />
            <div
              className="description-style"
              style={{ marginTop: 3, marginLeft: 53 }}
            >
              App for Zero Emission Delivery
            </div>
          </div>
        </div>
      </div>

      {showSideBar ? (
        <m.div
          initial={{ x: "50%" }}
          animate={{ x: "30%" }}
          transition={{ duration: 0.25, ease: "easeOut" }}
          exit={{ opacity: 0 }}
          className="map-outer-container"
          style={{ position: "absolute" }}
        >
          <div className="sideBarStyle">
            <div
              onClick={() => setShowSideBar(false)}
              style={{
                marginTop: 30,
                marginLeft: 200,
                backgroundColor: "#a1e3c3",
                borderRadius: 50,
                width: 30,
                // height: 30,
                padding: 2,
              }}
            >
              X
            </div>
            <div 
        // onClick={handleClick}
        >
          <img
            style={{
              width: 150,
              height: 45,
              marginTop: 10,
              marginBottom: -5,
              marginLeft: -75,
              position: "absolute",
              // cursor:"pointer"
            }}
            src={MinlLogo}
            alt="zeuez logo"
            // onClick={handleClick}
          />
        </div>
            <nav className="navbar" style={{ marginTop: -20 }}>
              <ul className="side-title-style">
                <CustomLink href={`/aboutus/${bookingId}`} style={{ marginLeft: -100 }}>
                  {" "}
                  About Us
                </CustomLink>
                <CustomLink href={`/privacypolicy/${bookingId}`} style={{ marginLeft: -65 }}>
                  {" "}
                  Privacy Policy
                </CustomLink>
                <CustomLink href={`/pricingPolicy/${bookingId}`} style={{ marginLeft: -69 }}>
                  {" "}
                  Pricing Policy
                </CustomLink>
                <CustomLink
                  href={`/termsandconditions/${bookingId}`}
                  style={{ marginLeft: -20 }}
                >
                  {" "}
                  Terms and Conditions
                </CustomLink>
                <CustomLink
                  href="/refundandcancellation"
                  style={{ marginLeft: 15 }}
                >
                  {" "}
                  Refund & Cancellation Policy
                </CustomLink>
                <CustomLink href={`/contact/${bookingId}`} style={{ marginLeft: -80 }}>
                  {" "}
                  Contact Us
                </CustomLink>
              </ul>
            </nav>
            {showDriven &&<div style={{color:"#000", marginTop:"50%"}}>
              Driven by
              <img style={{width:230,height:50}} src={logo2} alt=""/>
            </div>}
          </div>
        </m.div>
      ) : null}
    </>
  );
}

function CustomLink({ href, children, ...props }) {
  let path = window.location.pathname;

  return (
    <li className={href === path ? "active" : null}>
      <a href={href} {...props}>
        {children}
      </a>
    </li>
  );
}
