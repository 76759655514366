export default function DelhiPricingPolicy() {
    return (
      <div className="pricing-policy-container" >
        <div className="title-style" style={{ marginTop: 40 }}>
          Pricing Policy
        </div>
        <div className="subtitle-style" style={{ margin: 30 }}>
          While booking a trip, the app shows an estimated upfront fare to your
          destination every time. The price is estimated based on a number of
          factors; weight of the consignment, distance, volume, etc.
        </div>
      </div>
    );
  }
  