import { useRef, useState } from "react";
import MinlLogo from "./images/minllogo4.png";
import { sendOtp, verifyOtp } from "./api/shop";
import ReactLoading from "react-loading";
import { motion as m } from "framer-motion";
import { useParams } from "react-router-dom";

export default function OtpVerifyDelhi() {
  // const navigate = useNavigate();

  const [mobileNumer, setMobileNumer] = useState("");
  const [otpInput1, setOtpInput1] = useState("");
  const [otpInput2, setOtpInput2] = useState("");
  const [otpInput3, setOtpInput3] = useState("");
  const [otpInput4, setOtpInput4] = useState("");
  const [Isverified, setIsverified] = useState(false);
  const [otpMessage, setOtpMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showOTPinput, setShowOTPinput] = useState(false);
  const [showGotohistory, setShowGotohistory] = useState(false);

  const firstInput = useRef();
  const secondInput = useRef();
  const thirdInput = useRef();
  const fourthInput = useRef();

  const sendOTP = async () => {
    localStorage.setItem("mobile",mobileNumer)
    const result = await sendOtp(mobileNumer);
    console.log(result, "----result");
  };

  const verifyUser = async () => {

    const currentUrl = window.location.href;
  const urlParts = currentUrl?.split('/');
  const bookingId = urlParts[urlParts.length - 1];
  console.log(bookingId, "id val222")
    // setShowLoader(true);
    const otp = otpInput1 + "" + otpInput2 + "" + otpInput3 + "" + otpInput4;
    console.log(otp, "-----otp");
    const result = await verifyOtp(mobileNumer, otp);
    if (result.type === "success") {
      setShowLoader(false);
      setIsverified(true);
      setShowOTPinput(false);
      setShowGotohistory(true);
      // navigate(`/orderHistory/${mobileNumer}`);
    } else {
      setShowLoader(false);
      setOtpMessage(result.message);
    }
    console.log(result, "----result otp");
  };

  return (
    <div className="otp-verification-container">
      <div>
        <img
          style={{
            width: 150,
            height: 45,
            marginTop: 40,
            marginBottom: -5,
            marginLeft: -76,
            position: "absolute",
          }}
          src={MinlLogo}
          alt="zeuez logo"
        />
      </div>
      {!showGotohistory ? (
        <div
          className="title-style"
          style={{ fontSize: 20, marginTop: 110, color: "#000000" }}
        >
          Verify
        </div>
      ) : null}

      {!showGotohistory ? (
        <div
          className="description-style"
          style={{ fontSize: 14, marginTop: 50, marginBottom: 10 }}
        >
          Enter your mobile number to send OTP.
        </div>
      ) : null}

      {!showGotohistory ? (
        <div>
          <form>
            <input
              className="form-field-style"
              style={{ width: 250, height: 30, marginTop: 10, marginLeft: 10,  }}
              placeholder="Mobile Number"
              type="number"
              maxLength={10}
              onChange={(e) => {
                setMobileNumer(e.target.value);
              }}
            />
            {/* { mobile&& (
            <p className="error-font-style">mobile number is required.</p>
          )} */}
          </form>

          <button
            className={
              
              "button-style-main"
            }
            style={{
              width: 120,
              height: 40,
              paddingTop: 2,
              marginTop: 20,
            }}
            onClick={() => {
              setShowOTPinput(true);
              sendOTP();
            }}
            disabled={mobileNumer.length !== 10 || !/^[6-9]\d{9}$/.test(mobileNumer)}
          >
            Send OTP
          </button>

          {showOTPinput ? (
            <div>
              <div
                className="description-style"
                style={{ fontSize: 14, marginTop: 30, marginBottom: 10 }}
              >
                Enter the 4-Digit code sent to your mobile number.
              </div>
              <div style={{ flexDirection: "row" }}>
                <form>
                  <input
                    className="form-field-style"
                    style={{
                      width: 50,
                      height: 35,
                      marginTop: 10,
                      marginLeft: 0,
                    }}
                    type="numeric"
                    ref={firstInput}
                    maxLength={1}
                    onChange={(e) => {
                      setOtpInput1(e.target.value);
                      e && secondInput.current.focus();
                    }}
                  />
                  {/* { mobile&& (
            <p className="error-font-style">mobile number is required.</p>
          )} */}
                  <input
                    className="form-field-style"
                    style={{
                      width: 50,
                      height: 35,
                      marginTop: 10,
                      marginLeft: 0,
                    }}
                    type="numeric"
                    ref={secondInput}
                    maxLength={1}
                    onChange={(e) => {
                      setOtpInput2(e.target.value);
                      e
                        ? thirdInput.current.focus()
                        : firstInput.current.focus();
                    }}
                  />
                  <input
                    className="form-field-style"
                    style={{
                      width: 50,
                      height: 35,
                      marginTop: 10,
                      marginLeft: 0,
                    }}
                    type="numeric"
                    ref={thirdInput}
                    maxLength={1}
                    onChange={(e) => {
                      setOtpInput3(e.target.value);
                      e
                        ? fourthInput.current.focus()
                        : secondInput.current.focus();
                    }}
                  />
                  <input
                    className="form-field-style"
                    style={{
                      width: 50,
                      height: 35,
                      marginTop: 10,
                      marginLeft: 0,
                    }}
                    type="numeric"
                    ref={fourthInput}
                    maxLength={1}
                    onChange={(e) => {
                      setOtpInput4(e.target.value);
                      !e && thirdInput.current.focus();
                    }}
                  />
                </form>
                <div
                  className="error-font-style-otp"
                >
                  {otpMessage}
                </div>
                <div
                  className={
                    otpInput1 === "" ||
                    otpInput2 === "" ||
                    otpInput3 === "" ||
                    otpInput4 === ""
                      ? "verify-button-disable"
                      : "verify-button"
                  }
                  // style={{
                  //   width: 120,
                  //   height: 30,
                  //   paddingTop: 10,
                  //   marginLeft: 140,
                  //   marginTop: 20,
                  // }}

                  onClick={() => {
                    setShowOTPinput(true);
                    verifyUser();
                    // if (Isverified) navigate("/orderHistory");
                  }}
                >
                  Verify OTP
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      {showGotohistory ? (
        <div
          className="verify-button-order"
          // style={{ width: 160, marginLeft: 90, marginTop: 140 }}
        >
          <a
            href={`/orderHistory/delhi/${mobileNumer}`}
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            GO TO MY ORDERS
          </a>
        </div>
      ) : null}
      {showLoader ? (
        <>
          <m.div
            className="rate-outer-container"
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            style={{ marginTop: -470, marginLeft: -20 }}
          ></m.div>

          <m.div
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="rate-inner-container"
            style={{
              backgroundColor: "#ffffff",
              marginTop: -130,
              marginLeft: 100,
              width: 200,
              height: 100,
              borderRadius: 50,
              position: "relative",
            }}
          >
            <div
              style={{
                marginLeft: 65,
              }}
            >
              <ReactLoading
                type="balls"
                color="#30A46C"
                height={"50%"}
                width={"50%"}
              />
              <div
                className="pop-up-content-style"
                style={{ marginTop: 15, marginLeft: 60 }}
              >
                Please wait...
              </div>
            </div>
          </m.div>
        </>
      ) : null}
    </div>
  );
}
