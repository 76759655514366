import { motion as m } from "framer-motion";

function PaymentPage() {
  return (
    <m.div
      className="addressList-outer-container"
      initial={{ y: "100%",x:"200%" }}
      animate={{ y: "0%" }}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <div style={{paddingLeft:"350px"}}> 
      Payment page

      </div>
    </m.div>
  );
}

export default PaymentPage;
